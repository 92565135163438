export default {
    arrayRemove(arr, value) {
        return arr.filter(
            function (ele) {
                return ele.dataField !== value;
            }
        );
    },
    /**
     *  поиск индекса в массиве
     * @param {array} arr -массив по которому идет поиск
     * @param {string,number} value - значение которое ищется
     * @param {string} typeField - поле по которому ищется
     * @return {number} возвращение индекса || -1 если не надйен
     */
    arraySearch(arr, value, typeField) {
        return arr.findIndex(x =>
            x[typeField] === value
        )
    },
    arraySoloSort(arr,typeVar, typeSort,typeField){
        switch (typeVar)
        {
            case 'string':
                switch (typeSort){
                    case 1:
                        arr.sort((a,b) => a[typeField].localeCompare(b[typeField]));
                        break;
                    case -1:
                        arr.sort((a,b) => b[typeField].localeCompare(a[typeField]));
                        break;
                    default:
                        arr.sort((a,b) => a[typeField].localeCompare(b[typeField]));
                }
                break;
            case 'number':
                switch (typeSort){
                    case 1:
                        arr.sort((a,b) => a[typeField]-b[typeField]);
                        break;
                    case -1:
                        arr.sort((a,b) => b[typeField]-a[typeField]);
                        break;
                    default:
                        arr.sort((a,b) => a[typeField]-b[typeField]);
                }
                break;
        }
    },
    compareString(a, b,typeField,typeSort){
        let result;
        switch (typeSort){
            case 1:
                result= a[typeField].localeCompare(b,[typeField]);
                break;
            case -1 :
                result= b[typeField].localeCompare(a,[typeField]);
                break;
            default:
                result= a[typeField].localeCompare(b,[typeField]);
        }
        return result;
    },
    compareInteger(a, b,typeField,typeSort){
        let result;
        switch (typeSort){
            case 1:
                result= a[typeField]-b[typeField];
                break;
            case -1 :
                result= b[typeField]-a[typeField];
                break;
            default:
                result=a[typeField]-b[typeField];
        }
        return result;
    },
    arrayMultiSort(arr,sortBy){
        const fieldSorter = (fields) => (a, b) => fields.map(o => {
            let dir = 1;
            let k=o.datafield;
            if (o.type_sort === -1) { dir = -1;}
            if(Object.prototype.toString.call(a[k]) === '[object Object]' && a !== null)
            {
                if(Object.prototype.hasOwnProperty.call(a[k], 'code'))
                {
                    return a[k]['code'] > b[k]['code'] ? dir : a[k]['code'] < b[k]['code'] ? -(dir) : 0;
                }
                else
                    return a[k]['name'] > b[k]['name'] ? dir : a[k]['name'] < b[k]['name'] ? -(dir) : 0;
            }
            else
            return a[k] > b[k] ? dir : a[k] < b[k] ? -(dir) : 0;
        }).reduce((p, n) => p ? p : n, 0);
        arr.sort(fieldSorter(sortBy));
        // arr.sort( function (a,b){
        //     let i=0;
        //     let result=0;
        //     while (i < sortBy.length)
        //     {
        //         result = sortBy[i].type_sort*(a[ sortBy[i].datafield ].toString() < b[ sortBy[i].datafield ].toString() ? -1 : (a[ sortBy[i].datafield ].toString() > b[ sortBy[i].datafield ].toString() ? 1 : 0));
        //         i++;
        //     }
        //     return result;
        // })
    },
    /**
    * проверка на нулл и вывод н/д
    * @param {number}  value - значение на форматирование
    * @param {string} typeVar - тип переменной float/integer
    * @param {number,null} roundValue - окргуление до какого то знака
    * @param {boolean} onlyValue - без стилей
    * @param {string} styles - стили к возвращаемому значению
    * @return {string, number} возрващает форматированное значнеие
    * */
    checkValueWithNullAndReturnND(value, typeVar,roundValue = null,onlyValue = true, styles = 'color: #C6C8CB;'){
        let result = value
        switch (typeVar){
            case 'integer':
                result  = value != null ? this.isInteger(+value) : 'н/д'
                break;
            case 'float':
                result = value != null ? (roundValue != null ? +value.toFixed(roundValue) : value ) : 'н/д'
                break;
        }

        return onlyValue ? result : result ==='н/д' ? `<span style="${styles}">${result}</span>` : result
    },
    /**
     *  форматирование из таблицы на вывод данных
     * @param {Object} header -Объект хедер как в таблицах обязательные поля dataField, typeVar, roundValue (если float)
     * @param {string,number,Object} value - значение которое преобразовывается
     * @param {Object} row - Объект со всеми ззначениями из строки
     * @return {number, string} возвращение форматированного значения
     */
    returnValueAfterTransform(header, value,row){
        let result = ''
        switch (header.typeVar){
            case 'selector':
                result =  this.nameDataObj(value, header.dataField,row);
                break;
            case 'customSelector':
                result = header.data.find(el => el.value === value) != null ? header.data.find(el => el.value === value).text : value
                break;
            case 'date':
                result = this.$scripts.Utils.empty(value) ? value : value.slice(0,10)
                break;
            case 'integer':
                result  = value != null ? this.isInteger(+value) : 'н/д'
                break;
            case 'string':
                result = value
                break;
            case 'float':
                result = value != null ? (header.roundValue != null ? value.toFixed(header.roundValue) : value ) : 'н/д'
                break;
            default:
                result = value
                break;
        }
        return result
    },
    isObject(obj,dataField) {
        if (Object.prototype.toString.call(obj) === '[object Object]' && obj !== null) {
            return this.nameDataObj(obj, dataField);
        } else
            return obj;
    },
    isInteger(num) {
        if ((num ^ 0) === num) {
            return num;
        } else {
            return num.toFixed(1);
        }
    },
    nameDataObj(obj, nameField,row) {
        let full_name = '';
        if (nameField==='sprUgsCollspan')
        {
            if (row.sprSpec != null && !this.$scripts.Utils.empty(row.sprSpec.specID)){
                full_name =row.sprSpec[`specCode`] + ' - ' + row.sprSpec[`specName`]
            }
            else if (row.sprUgs != null)
            {
                full_name =row.sprUgs[`ugsCode`] + ' - ' + row.sprUgs[`ugsName`]
            }
        }
        else {
            if (!this.$scripts.Utils.empty(obj))
            {
                full_name = ['usl', 'org', 'ff', 'fo'].includes(nameField.slice(3).toLowerCase()) ? obj[`${nameField.slice(3).toLowerCase()}Name`] : obj[`${nameField.slice(3).toLowerCase()}Code`] + ' ' + obj[`${nameField.slice(3).toLowerCase()}Name`];
                if (['kcpgroup'].includes(nameField.slice(3).toLowerCase()))
                    full_name = obj[`code`] + ' - ' + obj[`name`]
            }
        }
        return full_name;
    }
}


