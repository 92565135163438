import Vue from 'vue'
import Router from 'vue-router'
import store from "@/store";

Vue.use(Router)

// import LKlogin from "@/pages/LK";

const routes = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: () => import('@/layouts/lkLayout'),
            meta: {},
            children: [
                {
                    path: '',
                    component: () => import('@/View/ViewLK'),
                    meta: {},
                },
            ]
        },
        {
            path: '/section-1',
            component: () => import('@/layouts/sectionLayout'),
            children: [
                {
                    path: '',
                    component: () => import('@/View/SectionWithForms'),
                },
                {
                    path: 'form-1',
                    component: () => import('@/View/section1/form1'),
                },
                {
                    path: 'form-1.1',
                    component: () => import('@/View/section1/form1.1'),
                    meta: {
                        needSection: ['sectionOne', 'form1']
                    },
                },
                {
                    path: 'form-2',
                    component: () => import('@/View/section1/form2'),
                },
                {
                    path: 'form-3',
                    component: () => import('@/View/section1/form3'),
                }
            ],
            meta: {
                needContact: true,
            },
        },
        {
            path: '/section-2',
            component: () => import('@/layouts/sectionLayout'),
            children: [
                {
                    path: '',
                    component: () => import('@/View/SectionWithForms'),
                },
                {
                    path: 'form-1',
                    component: () => import('@/View/section2/form1'),
                },
                {
                    path: 'form-1.1',
                    component: () => import('@/View/section2/form1.1'),
                    meta: {
                        needSection: ['sectionTwo', 'form2']
                    },
                },
                {
                    path: 'form-2',
                    component: () => import('@/View/section2/form2'),
                },
                {
                    path: 'form-3',
                    component: () => import('@/View/section2/form3'),
                },
            ],
            meta: {
                needContact: true,
            },
        },
        {
            path: '/admin',
            component: () => import('@/layouts/adminLayout'),
            children: [
                {
                    path: 'exports',
                    component: () => import('@/View/Admin/Exports')
                },
                {
                    path: 'profiles',
                    component: () => import('@/View/Admin/orgProfilesOlympiad')
                },
                {
                    path: '*',
                    redirect: '/admin/exports'
                }
            ],
            meta: {
                isAdmin: true
            }
        }
    ]
});
routes.beforeEach(async (to, from, next) => {

    if (to.matched.some(record => record.meta.needContact)) {
        if (!store.getters['auth/getUserInfo'].haveContacts) {
            next({
                path: '/',
            });
        } else {
            next()
        }
    } else {
        next()
    }
    if (to.matched.some(record => record.meta.isAdmin)){
        if (store.getters['keycloak/isAdminGroup'])
            next();
        else
        {
            // store.dispatch('notifications/addNotification', {
            //     notificationStatus: 'error',
            //     notificationMessage: 'Нет доступа',
            //     timeout: 5000
            // }, {root: true});
            next('/')
        }
    }
    // if (to.matched.some(record => record.meta.needSection)) {
    //     const section = to.meta?.needSection[0];
    //     const form = to.meta?.needSection[1];
    //     if (!store.getters['auth/getUserInfo'][section][form].haveOlympiads) {
    //         store.dispatch('notifications/addNotification', {
    //             notificationStatus: 'warning',
    //             notificationMessage: 'Заполните предыдущую форму',
    //             timeout: 3000
    //         }, {root: true});
    //         next();
    //     } else {
    //         next()
    //     }
    // } else {
    //     next()
    // }
    await store.dispatch('auth/saveUserInfo', {...store.getters['auth/getUserInfo'], path: to.fullPath});
});
export default routes;