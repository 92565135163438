<template>
  <wrapper-preview-category>

    <h1 class="main-header__title">{{ pageTitle }}</h1>
    <div>
      <div class="mx-2 mb-2 d-flex justify-space-between align-center">
        <v-btn class="blue__v-btn" :disabled="getBlock" @click="openModal = true">Добавить запись</v-btn>
        <v-btn
            class="blue__v-btn"
            :loading="downloadingFileForPrint"
            @click="downloadPrintDocument(`/api/excelReport?num=2&orgId=${orgId}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')"
        >
          Скачать заполненную форму в формате Excel для самопроверки
        </v-btn>
      </div>
      <GridTable
          :headers="headers.massive"
          :headers-for-rows="headers.massive"
          :num-cols="headers.countCells"
          :loading-data="loading"
          :data-rows="formDataForTable"
          class="ma-3"
      >
        <template #fileId="{row}">
          <v-btn
              color="primary"
              icon
              @click="downloadFile(row.fileId)">
            <v-icon size="20" color="primary">mdi-file-outline</v-icon>
          </v-btn>
        </template>
        <template #actions="{row, index}">
          <v-btn :disabled="getBlock" :loading="sendingOlympiad" icon @click="changeRow(row.id, index)">
            <v-icon class="flex align-center" color="primary">
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn :disabled="getBlock" :loading="deleting.id === row.id && deleting.value" icon
                 @click="deleteRow(row.id, index)">
            <v-icon class="flex align-center" color="#888888">
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </GridTable>
      <div class="d-flex justify-end">
        <v-btn class="white__v-btn mb-2 mr-2"
               @click="goNextStage">
          Перейти к заполнению следующей формы
        </v-btn>
      </div>
    </div>
    <v-dialog
        v-model="openModal"
        max-width="1000px"
        persistent
        @keydown.esc="closeMainDialog"
    >
      <v-card>
        <v-card-title class="modal-header justify-space-between">
          <div class="addNote title">Добавление записи</div>
          <v-icon class="close" @click="closeMainDialog">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <div class="content__block">
            Входит ли олимпиада в список олимпиад, указанных в Форме 1 «Информация об организованных в 2021/22 учебном
            году олимпиадах школьников, участие в которых принимали иностранные граждане, обучавшиеся в 11 классе (в
            выпускном классе)»
            <v-radio-group
                v-model="newNote.actual"
                :error-messages="errorNote.actual"
                row
            >
              <v-radio :value=true label="Да"></v-radio>
              <v-radio :value=false label="Нет"></v-radio>
            </v-radio-group>
          </div>
          <div class="content__block">
            Наименование олимпиады
            <div v-if="!newNote.actual">
              <v-text-field
                  v-model="newNote.nameOlympic"
                  :disabled="newNote.actual === null"
                  dense
                  outlined
              ></v-text-field>
            </div>
            <div v-else>
              <v-autocomplete
                  v-model="newNote.olympiad"
                  :disabled="newNote.actual === null"
                  :error-messages="errorNote.nameOlympic"
                  :items="orgOlympiads"
                  dense
                  outlined
              />
            </div>
          </div>
          <div class="content__block">
            <div class="block__with-btn">
              Наименования(-е) профилей (-я) олимпиады
            </div>
            <div class="d-flex flex-row">
              <v-autocomplete
                  v-model="newNote.profiles"
                  :disabled="(newNote.nameOlympic === null || !newNote.nameOlympic) && !newNote.olympiad"
                  :error-messages="errorNote.profiles"
                  :items="orgOlympiadsProfile"
                  dense
                  multiple
                  outlined
                  no-data-text="По вашему запросу ничего нет"
                  return-object
              >
              </v-autocomplete>
              <v-tooltip
                  top
                  color="primary"
              >
                <template #activator="{on, attrs}">
                  <v-btn
                      :disabled="(newNote.nameOlympic === null || !newNote.nameOlympic) && !newNote.olympiad"
                      icon
                      v-on="on"
                      v-bind="attrs"
                      @click="openCustomDialog('profile')">
                    <v-icon size="35" color="primary">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <p class="white--text mb-0 pb-0">Добавить свой вариант</p>
              </v-tooltip>
            </div>
          </div>
          <div class="content__block">
            Укажите с какого года проводится олимпиада
            <div class="d-flex flex-row align-start">
              <v-text-field v-model="newNote.startYear"
                            label="Год-месяц-день"
                            :error-messages="errorNote.startYear"
                            dense
                            :disabled="!newNote.profiles.length"
                            outlined
                            v-mask="'####-##-##'"
              />
              <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      :disabled="!newNote.profiles.length"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon color="primary" size="26">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                    v-model="newNote.startYear"
                    locale="ru"
                    no-title
                    scrollable
                >
                </v-date-picker>
              </v-menu>
            </div>
<!--            <v-menu
                ref="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                    v-model="newNote.startYear"
                    chips
                    dense
                    :error-messages="errorNote.startYear"
                    :disabled="!newNote.profiles.length"
                    label=""
                    small-chips
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-combobox>
              </template>
              <v-date-picker
                  v-model="newNote.startYear"
                  locale="ru"
                  no-title
                  scrollable
              >
              </v-date-picker>
            </v-menu>-->
          </div>
          <div class="content__block">
            Период проведения олимпиады (укажите даты начала первого этапа и завершения последнего этапа олимпиады)
            <div class="d-flex flex-row align-start">
              <v-text-field v-model="newNote.dates[0]"
                            :error-messages="errorNote.dates"
                            dense
                            outlined
                            label="От: Год-месяц-день"
                            v-mask="'####-##-##'"
              />
              <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon color="primary" size="26">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                    v-model="newNote.dates[0]"
                    locale="ru"
                    no-title
                >
                </v-date-picker>
              </v-menu>
              <v-text-field v-model="newNote.dates[1]"
                            :error-messages="errorNote.dates"
                            dense
                            outlined
                            label="До: Год-месяц-день"
                            v-mask="'####-##-##'"
              />
              <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon color="primary" size="26">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                    v-model="newNote.dates[1]"
                    locale="ru"
                    no-title
                    scrollable
                >
                </v-date-picker>
              </v-menu>
            </div>
<!--            <v-menu
                ref="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                    v-model="newNote.dates"
                    chips
                    dense
                    multiple
                    :error-messages="errorNote.dates"
                    :rules="[
                        () => newNote.dates.length <= 2 || 'Можно выбрать только две даты'
                        ]"
                    label=""
                    small-chips
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-combobox>
              </template>
              <v-date-picker
                  v-model="newNote.dates"
                  locale="ru"
                  no-title
                  multiple
                  scrollable
              >
              </v-date-picker>
            </v-menu>-->
          </div>
          <div class="content__block">
            Является ли ваша организация основным организатором олимпиады?
            <v-radio-group
                v-model="newNote.maintainerOrg"
                :disabled="!newNote.profiles.length"
                :error-messages="errorNote.maintainerOrg"
                row
            >
              <v-radio :value="true" label="Да">
              </v-radio>
              <v-radio :value="false" label="Нет"></v-radio>
            </v-radio-group>
          </div>
          <div class="content__block" v-if="newNote.maintainerOrg !== true">
            Укажите наименование основного организатора олимпиады (ввести полное наименование по ЕГРЮЛ)
            <div class="d-flex flex-row">
              <v-autocomplete
                  v-model="newNote.maintainerOrgName"
                  :disabled="!newNote.olympiad && !newNote.nameOlympic"
                  :error-messages="errorNote.maintainerOrgName"
                  :items="maintainerOrgNameList"
                  dense
                  multiple
                  outlined
                  no-data-text="По вашему запросу ничего нет"
              >
              </v-autocomplete>
              <v-tooltip
                  top
                  color="primary"
              >
                <template #activator="{on, attrs}">
                  <v-btn
                      :disabled="!newNote.olympiad && !newNote.nameOlympic"
                      icon
                      v-on="on"
                      v-bind="attrs"
                      @click="openCustomDialog('orgs', null, 'Наименование основного организатора олимпиады')">
                    <v-icon size="35" color="primary">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <p class="white&#45;&#45;text mb-0 pb-0">Добавить свой вариант</p>
              </v-tooltip>
            </div>
          </div>
          <div class="content__block">
            Укажите наименование(-я) соорганизатора (-ов) олимпиады (ввести полное наименование по ЕГРЮЛ)
            <div class="d-flex flex-row">
              <v-autocomplete
                  v-model="newNote.helpersOrgNames"
                  :disabled="!newNote.olympiad && !newNote.nameOlympic"
                  :error-messages="errorNote.helpersOrgNames"
                  :items="helpersOrgNameList"
                  dense
                  multiple
                  outlined
                  no-data-text="По вашему запросу ничего нет"
              >
              </v-autocomplete>
              <v-tooltip
                  top
                  color="primary"
              >
                <template #activator="{on, attrs}">
                  <v-btn
                      :disabled="!newNote.olympiad && !newNote.nameOlympic"
                      icon
                      v-on="on"
                      v-bind="attrs"
                      @click="openCustomDialog('orgsHelpers', null, 'Наименование соорганизатора олимпиады')">
                    <v-icon size="35" color="primary">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <p class="white&#45;&#45;text mb-0 pb-0">Добавить свой вариант</p>
              </v-tooltip>
            </div>
          </div>
          <div class="content__block">
            Официальный сайт олимпиады
            <v-text-field v-model="newNote.site" :disabled="!newNote.profiles.length" dense
                          outlined/>
          </div>
          <div class="content__block">
            Допустимо ли участие иностранных граждан в олимпиаде?
            <v-radio-group
                v-model="newNote.canForeignBe"
                :disabled="!newNote.profiles.length"
                :error-messages="errorNote.canForeignBe"
                row
            >
              <v-radio :value="true" label="Да">
              </v-radio>
              <v-radio :value="false" label="Нет"></v-radio>
            </v-radio-group>
          </div>
          <div class="content__block">
            Номер пункта в положении об олимпиаде, где указано условие о возможности участия в ней иностранных
            граждан/комментарий об отсутствии в положении ограничений на участие иностранных граждан в данной олимпиаде
            <v-text-field
                v-model="newNote.paragraphNumber"
                :disabled="!newNote.profiles.length"
                :error-messages="errorNote.paragraphNumber"
                dense
                outlined/>
          </div>
          <div class="content__block">
            Есть ли возможность дистанционного участия иностранных граждан в
            олимпиаде?
            <v-radio-group
                v-model="newNote.distance"
                :disabled="!newNote.profiles.length"
                :error-messages="errorNote.distance"
                row
            >
              <v-radio :value="true" label="Да">
              </v-radio>
              <v-radio :value="false" label="Нет"></v-radio>
            </v-radio-group>
          </div>
          <div class="content__block">
            Каналы информирования иностранных граждан об олимпиаде, которые будут использоваться при проведении
            <div class="">
              <v-autocomplete
                  v-model="newNote.informChannels"
                  :disabled="!newNote.profiles.length"
                  :items="sprInformChannels"
                  :error-messages="errorNote.informChannels"
                  dense
                  multiple
                  outlined
                  no-data-text="По вашему запросу ничего нет"
                  return-object
              >
              </v-autocomplete>
            </div>
          </div>
          <div class="content__block">
            Мероприятия(-е) по продвижению олимпиады среди иностранных граждан, которые запланированы к реализации в
            рамках подготовки к проведению олимпиады
            <div class="">
              <v-autocomplete
                  v-model="newNote.advertisements"
                  :disabled="!newNote.profiles.length"
                  :items="spradvertisements"
                  :error-messages="errorNote.advertisements"
                  dense
                  multiple
                  outlined
                  no-data-text="По вашему запросу ничего нет"
                  return-object
              >
              </v-autocomplete>
            </div>
          </div>
          <div class="content__block">
            <div class="block__with-btn">
              <div>ФИО и квалификация международных(-ого) экспертов(-а), участие которых(-ого) планируется в
                организационном комитете/методической комиссии/жюри олимпиады
              </div>
              <v-btn
                  :disabled="(newNote.nameOlympic === null || !newNote.nameOlympic) && !newNote.olympiad"
                  icon
                  @click="openCustomDialog">
                <v-icon size="35" color="primary">mdi-plus</v-icon>
              </v-btn>
            </div>
            <div v-for="(person, index) in newNote.experts" :key="index" class="block__with-btn">
              <div v-if="!newNote.experts.length"></div>
              <div v-else class="text d-flex align-center">
                {{ person.name }} <span v-if="person.qualification"> - </span> {{ person.qualification }}
              </div>
              <v-btn
                  :disabled="!newNote.profiles.length"
                  class="button__plus"
                  color="#FF0000"
                  height="40px"
                  small
                  @click="newNote.experts.splice(index, 1)"
              >
                <v-icon color="#FF0000" light>
                  mdi-window-close
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div class="content__block">
            Имелись ли требования к минимальному уровню знаний, необходимых для успешного прохождения олимпиады?
            <v-radio-group
                v-model="newNote.minKnowledgeRequired"
                :disabled="!newNote.profiles.length"
                :error-messages="errorNote.minKnowledgeRequired"
                row
            >
              <v-radio :value="true" label="Да">
              </v-radio>
              <v-radio :value="false" label="Нет"></v-radio>
            </v-radio-group>
          </div>
          <div class="content__block" v-if="newNote.minKnowledgeRequired === true">
            Какие были требования к минимальному уровню знаний, необходимых для успешного прохождения олимпиады
            <v-text-field v-model="newNote.minKnowledgeRequiredInfo"
                          :disabled="!newNote.profiles.length"
                          dense
                          :error-messages="errorNote.minKnowledgeRequiredInfo"
                          outlined/>
          </div>
          <div class="content__block">
            <div class="">
              Наименования(-е) иностранных(-ого) языков(-а) олимпиадных заданий

            </div>
            <div>
              <v-autocomplete
                  v-model="newNote.foreignLanguages"
                  :disabled="!newNote.profiles.length"
                  :items="sprLanguages"
                  dense
                  multiple
                  outlined
                  return-object
                  no-data-text="нет результатов по вашемы поиску"
              >
              </v-autocomplete>
            </div>
          </div>
          <div class="content__block">
            <div>
              Наименования(-е) иностранных(-ого) государств(-а), на территориях
              (-ии) которых(-ого) планируется очно проводить не менее одного этапа олимпиады по указанному профилю (при
              наличии выбрать одно или несколько)
            </div>
            <div>
              <v-autocomplete
                  v-model="newNote.foreignStates"
                  :disabled="!newNote.profiles.length"
                  :items="sprStateTerritory"
                  dense
                  multiple
                  outlined
                  return-object
                  no-data-text="нет результатов по вашемы поиску"
              >
              </v-autocomplete>
            </div>
          </div>
          <div class="content__block">
            <scan-card
                class=""
                :item="file"
                :disabled="!newNote.profiles.length"
                :upload-to-serv-now="loadingFileToServer.fileType === file.fileType && loadingFileToServer.value"
                @replace-file="replaceFile($event)"
                @upload-file="uploadFile($event)"
                :token="token"
                identifier="fileType"
            >
            </scan-card>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="d-flex justify-space-between mt-4">

            <v-btn
                class="blue__v-btn ml-3"
                :loading="sendingOlympiad"

                @click="checkForm('send')"
            >
              Сохранить
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <add-spr-info
        ref="spr-dialog"
        :title="titleForSprDialog"
        :fields="fieldsForSprDialog"
        :loading="addingProfileLoading"
        @send-resolve="addNewProfile($event)"
    >
    </add-spr-info>
    <confirm-dialog
        ref="agree-dialog"
        text="При редактировании данных об олимпиаде в форме 2 данные об этой олимпиаде в форме 3 автоматически обнуляются.<br> <br>  Не забудьте внести данные об олимпиаде в форме 3 заново." >
      <template #actions="{agree, close}">
        <v-btn
            class='blue__v-btn'
            @click="agree"
            width="80px"
        >
          Ок
        </v-btn>
        <v-btn
            class='white__v-btn ml-2'
            @click="close"
            width="80px"
        >
          Отменить
        </v-btn>
      </template>
    </confirm-dialog>
  </wrapper-preview-category>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
//import request from "@/services/request";
import {setFileFunctions} from "@/mixins/fileFucntions";
import {deleteForm, getForm, setForm} from "@/Api/sectionOne";
import ScanCard from "@/components/cards/scanCard";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import GridTable from "@/components/GridTable/GridTable";
import headersFormTwo from "@/helpers/sectionOne/headersFormTwo";
import AddSprInfo from "@/components/Dialogs/addSprInfo";
import {setAddSprDialogFunctions} from "@/mixins/sprDialogFunctions";
import {isBlock} from "@/mixins/isBlock";
import {headerFormsName} from "@/mixins/headerName";
import {filterProfilesFormTwo} from "@/helpers/sectionOne/filterFunctions";
import ConfirmDialog from "@/components/Dialogs/confirmDialog";

const defaultErrorNoteValues = {
  actual: "",
  nameOlympic: "",
  profiles: "",
  distance: "",
  file: "",
  dates: "",
  startYear: "",
  maintainerOrg: "",
  maintainerOrgName: "",
  advertisements: "",
  informChannels: "",
  canForeignBe: "",
  paragraphNumber: "",
  minKnowledgeRequired: "",
  minKnowledgeRequiredInfo: ""
}
export default {
  components: {ConfirmDialog, AddSprInfo, GridTable, WrapperPreviewCategory, ScanCard},
  name: 'FORM_2',
  mixins: [setFileFunctions, setAddSprDialogFunctions, isBlock, headerFormsName],
  data() {
    return {
      titleForSprDialog: 'Добавить профиль олимпиады',
      fieldsForSprDialog: [],
      addingProfileLoading: false,
      formData: [],
      closeEdit: null,
      openModal: false,
      openMiniModal: false,
      openMiniTitle: "",
      openMiniVModal: "",
      openMiniInput: "",
      openMiniSelect: false,
      openMiniVModalAdditional: "",
      openMiniInputAdditional: '',
      confirmFinishEdit: false,
      sendingChoice: false,
      sendingOlympiad: false,
      loading: false,
      deleting: {value: false, id: null},
      errorNote: {
        ...defaultErrorNoteValues
      },
      newNote: {
        actual: null,
        nameOlympic: null,
        olympiad: null,
        /**/
        startYear: null,
        dates: [
            '', ''
        ],
        maintainerOrg: null,
        maintainerOrgName: [],
        helpersOrgNames: [],
        canForeignBe: null, //9
        paragraphNumber: null,  //10
        minKnowledgeRequired: null,//15
        minKnowledgeRequiredInfo: null,//16
        foreignLanguages: [],
        foreignStates: [],
        /**/
        profiles: [],
        site: "",
        distance: null,
        informChannels: [],
        advertisements: [], /*{select: [], manual: []},*/
        experts: [],
        fileId: null,
      },
      headers: headersFormTwo,
      maintainerOrgNameListDefault: [],
      helpersOrgNameListDefault: [],
    };
  },
  created() {
    this.getFormData();
    this.getFile();
  },
  watch: {
    '$route.path'() {
      this.reloadData()
    }
  },
  computed: {
    ...mapGetters('spr', {getSpr: 'getSprData', getOrgOlympiads: 'getOrgOlympiadsSchool'}),
    ...mapGetters('auth', {getUserInfo: 'getUserInfo'}),
    ...mapGetters('keycloak', {getOrgName: 'getOrgName', getOrgId: 'getOrgId'}),
    completeWork() {
      return this.getUserInfo.sectionOne.form2.complete;
    },
    haveOlympiads() {
      return this.getUserInfo.sectionOne.form2.haveOlympiads;
    },
    sprStateTerritory() {
      return this.getSpr.sprDataOksm.map(el => {
        return {text: el.name, value: el.id}
      })
    },
    sprLanguages() {
      return this.getSpr.sprDataLanguages.map(el => {
        return {text: el.name, value: el.id}
      })
    },
    orgOlympiads() {
      return this.getOrgOlympiads.map(el => {
        return {value: el.text, text: el.text, id: el.id, fl: el.fl}
      })
    },
    maintainerOrgNameList() {
      return this.maintainerOrgNameListDefault.map(el => ({
        value: el, text: el
      }))
    },
    helpersOrgNameList() {
      return this.helpersOrgNameListDefault.map(el => ({
        value: el, text: el
      }))
    },
    currentOlympiad(){
      return this.orgOlympiads?.find(el => el.value === this.newNote.olympiad)
    },
    olympiadId() {
      return this.currentOlympiad?.id
    },
    orgOlympiadsProfile() {
      return this.getSprData.sprDataOlympiadProfileSchool
          .filter(el => filterProfilesFormTwo.call(this, el))
          .map(el => {
            return {text: el.name, value: el.id}
          })
    },
    sprInformChannels() {
      return this.getSprData.sprDataInformChannels.map(el => {
        return {text: el.name, value: el.id}
      })
    },
    spradvertisements() {
      return this.getSprData.sprDataAdvertisement.map(el => {
        return {text: el.name, value: el.id}
      })
    },
    getSprData() {
      return this.getSpr
    },
    orgName() {
      return this.getOrgName;
    },
    orgId() {
      return this.getOrgId;
    },
    formDataForTable() {
      return this.formData.map(data => {
        return {
          ...data,
          nameOlympic: data.olympiad?.name ? data.olympiad?.name : data.olympiad,
          actual: this.$scripts.FormData.getStringFromBoolean(data.actual),
          maintainerOrg: this.$scripts.FormData.getStringFromBoolean(data.maintainerOrg),
          canForeignBe: this.$scripts.FormData.getStringFromBoolean(data.canForeignBe),
          minKnowledgeRequired: this.$scripts.FormData.getStringFromBoolean(data.minKnowledgeRequired),
          profiles: this.$scripts.FormData.getNamesFromArray(data.profiles),
          distance: this.$scripts.FormData.getStringFromBoolean(data.distance),
          informChannels: this.$scripts.FormData.getNamesFromArray(data.informChannels),
          advertisements: this.$scripts.FormData.getNamesFromArray(data.advertisements),
          experts: data.experts?.length > 0 ? this.$scripts.FormData.getNamesFromArray(data.experts, ['name', 'qualification']) : '-',
          dates: data?.dates?.join(' - ')
        }
      })
    }
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('auth', {saveUserInfo: 'saveUserInfo'}),
    ...mapActions('spr', {downloadSprDataExtended: 'downloadSprDataExtended'}),
    async addNewProfile(e) {
      try {
        this.addingProfileLoading = true;
        const objToSend = {}
        e.forEach(el => {
          objToSend[el.dataField] = el.value;
        })
        if (this.fieldsForSprDialog.length === 1) {
          const elem = e[0];
          if (elem.typeDialog !== 'profile'){
            this[elem.mainOrg ? 'maintainerOrgNameListDefault' : 'helpersOrgNameListDefault'].push(elem.value);
            this.$nextTick(() => this.newNote[elem.mainOrg ? 'maintainerOrgName' : 'helpersOrgNames'].push(elem.value))
          }else{
            objToSend.forSchool = true;
            console.log('e', elem, objToSend);
            this.setProfile(objToSend);
            await this.addNotification({
              notificationStatus: 'success',
              notificationMessage: 'Ваш профиль успешно добавлен, выберите его.',
              timeout: 5000
            });
          }
          // const text = e[0].value
          // console.log('e', e[0], !e[0].id)
          // if (!e[0].id)
          //   this.profilesDialogValue.push({text: text})
          // else{
          //   const item = this.profilesDialogValue.find(el => el.id === e[0].id);
          //   item.name = text;
          //   item.text = text;
          // }
          // this.setProfile(objToSend);

          // await this.addNotification({
          //   notificationStatus: 'success',
          //   notificationMessage: 'Ваш профиль успешно добавлен, выберите его.',
          //   timeout: 5000
          // });
        } else {
          this.newNote.experts.push(objToSend)
        }
        this.$refs['spr-dialog'].closeDialog()
        this.addingProfileLoading = false;
      } catch (e) {
        this.addingProfileLoading = false;
      }

    },
    async openCustomDialog(type = 'orgs', data, text = '') {
      if (type === 'profile') {
        this.titleForSprDialog = 'Добавить профиль олимпиады';
        this.fieldsForSprDialog = [
          {
            typeDialog: 'profile',
            id: data?.id,
            dataField: 'name',
            value: data?.name ?? null,
            rules: [v => !!v || 'поле не может быть пустым'],
            text: 'Наименование профиля'
          }
        ]
      }
      else if (['orgs', 'orgsHelpers'].includes(type)) {
        this.titleForSprDialog = 'Добавить свой вариант';
        this.fieldsForSprDialog = [
          {
            typeDialog: 'orgs',
            id: data?.id,
            mainOrg: type === 'orgs',
            dataField: 'value',
            value: data?.name ?? null,
            rules: [v => !!v || 'поле не может быть пустым'],
            text: text ? text : 'Наименование'
          }
        ]
      } else {
        this.titleForSprDialog = 'Добавить эксперта'
        this.fieldsForSprDialog = [
          {
            dataField: 'name',
            value: null,
            rules: [v => !!v || 'поле не может быть пустым'],
            text: 'ФИО '
          },
          {
            dataField: 'qualification',
            value: null,
            rules: [v => !!v || 'поле не может быть пустым'],
            text: 'Квалификация'
          },
        ]
      }

      await this.$refs["spr-dialog"].openDialog();
    },
    closeMainDialog() {
      this.openModal = false;
      this.openMiniModal = false;
      this.clearDefaultValue();
    },
    getFile() {
      this.file = {...this.file, name: 'Положение об олимпиаде', id: this.file?.id, fileType: 'FORM_2'};
    },
    clearDefaultValue() {
      this.newNote = {
        actual: null,
        nameOlympic: null,
        olympiad: null,
        id: null,
        /**/
        startYear: null,
        dates: [
          '', ''
        ],
        maintainerOrg: null,
        maintainerOrgName: [],
        helpersOrgNames: [],
        canForeignBe: null, //9
        paragraphNumber: null,  //10
        minKnowledgeRequired: null,//15
        minKnowledgeRequiredInfo: null,//16
        foreignLanguages: [],
        foreignStates: [],
        /**/
        profiles: [],
        site: "",
        distance: null,
        informChannels: [],
        advertisements: [], /*{select: [], manual: []},*/
        experts: [],
        fileId: null,
      };
      this.file.id = null;
    },
    async doChoseAboutOlympiad(flag) {
      try {
        this.sendingChoice = true;
        await this.saveUserInfo({
          ...this.getUserInfo,
          sectionOne: {
            ...this.getUserInfo.sectionOne,
            form2: {
              complete: !flag,
              haveOlympiads: flag,
              fileId: ''
            },
          }
        })
        this.sendingChoice = false;
      } catch (e) {
        this.sendingChoice = false;
      }
    },
    changeRow(id) {
      const searchItem = this.formData.find(el => el.id === id);
      if (!searchItem) return;
      this.file.id = searchItem.fileId;
      this.newNote = {
        ...searchItem,
        nameOlympic: searchItem.actual ? null : searchItem.olympiad,
        foreignStates: searchItem.foreignStates?.map(el => {
          return {value: el.id}
        }),
        foreignLanguages: searchItem.foreignLanguages?.map(el => {
          return {value: el.id}
        }),
        informChannels: searchItem.informChannels?.map(el => {
          return {value: el.id}
        }),
        profiles: searchItem.profiles?.map(el => {
          return {value: el.id}
        }),
        advertisements: searchItem.advertisements?.map(el => {
          return {value: el.id}
        })
      }
      this.openModal = true;
    },
    reloadData() {
      window.scrollTo(0, 0)
      this.formData = []
      this.closeEdit = null
      this.getFormData()
      this.errorNote = {
        ...defaultErrorNoteValues
      }
      this.clearDefaultValue()
      this.openMiniModal = false;
      this.openMiniTitle = "";
      this.openMiniInput = "";
      this.openMiniVModal = ''
      this.openMiniInputAdditional = "";
      this.openMiniVModalAdditional = ''
      this.openMiniSelect = false
      this.userAction = null
    },
    async getFormData() {
      try {
        this.loading = true;
        this.downloadSprDataExtended()
        this.formData = await getForm('formTwo');
        this.formData.forEach(el => {
          if (el.helpersOrgNames?.length > 0)
            this['helpersOrgNameListDefault'].push(...el.helpersOrgNames);
          if (el.maintainerOrgName?.length > 0)
            this['maintainerOrgNameListDefault'].push(...el.maintainerOrgName);
        })
        this.loading = false;
      } catch (e) {
        console.error('getFormData', e)
        this.loading = false;
      }

    },
    checkForm(action) {
      let haveError = false;
      this.errorNote = {
        defaultErrorNoteValues
      }
      if (this.newNote.actual === null /*&& this.currentSection === 2*/) {
        this.errorNote.actual = "Поле не должно быть пустым";
        haveError = true;
      }
      if (!this.newNote.nameOlympic && !this.newNote.olympiad) {
        this.errorNote.nameOlympic = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.startYear === null) {
        this.errorNote.startYear = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.startYear.length !== 10) {
        this.errorNote.startYear = "Поле должно быть формата ГГГГ-ММ-ДД";
        haveError = true;
      }
      if (this.newNote.dates[0].length === 0 || this.newNote.dates[1].length === 0) {
        this.errorNote.dates = "Выберите две даты для правильного ввода";
        haveError = true;
      }
      if (this.newNote.dates[0] && this.newNote.dates[0].length !== 10) {
        this.errorNote.dates = "Поле должно быть формата ГГГГ-ММ-ДД";
        haveError = true;
      }
      if (this.newNote.dates[1] && this.newNote.dates[1].length !== 10) {
        this.errorNote.dates = "Поле должно быть формата ГГГГ-ММ-ДД";
        haveError = true;
      }
      if (this.newNote.informChannels === null || this.newNote.informChannels?.length === 0) {
        this.errorNote.informChannels = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.advertisements === null || this.newNote.advertisements?.length === 0) {
        this.errorNote.advertisements = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.maintainerOrg === null) {
        this.errorNote.maintainerOrg = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.minKnowledgeRequired === null) {
        this.errorNote.minKnowledgeRequired = "Поле не должно быть пустым";
        haveError = true;
      }
      if ([null, false].includes(this.newNote.maintainerOrg) && this.newNote.maintainerOrgName.length === 0) {
        this.errorNote.maintainerOrgName = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.canForeignBe === null) {
        this.errorNote.canForeignBe = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.paragraphNumber === null) {
        this.errorNote.paragraphNumber = "Поле не должно быть пустым";
        haveError = true;
      }
      if (!this.newNote.profiles.length) {
        this.errorNote.profiles = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.distance === null) {
        this.errorNote.distance = "Поле не должно быть пустым";
        haveError = true;
      }
      if (!this.file.id) {
        this.errorNote.file = "Файл должен быть обязательно загружен";
        haveError = true;
      }
      if (haveError) return;
      else {
        this.errorNote = {
          ...defaultErrorNoteValues
        }
        this.confirmFinishEdit = true;
        this.userAction = action;
        this.finishEdit();
      }
    },
    async finishEdit() {
      try {
        let finalNote = {...this.newNote}
        if (finalNote.id){
          let agree = false;
          await this.$refs["agree-dialog"].openDialog().then(data => agree = data);
          if (!agree) return;
        }
        this.confirmFinishEdit = false
        finalNote.fileId = this.file.id;
        if (finalNote.informChannels.length > 0) {
          finalNote.informChannels = finalNote.informChannels.map(el => {
            return {id: el.value}
          })
        }
        if (finalNote.profiles.length > 0) {
          finalNote.profiles = finalNote.profiles.map(el => {
            if (el.value)
              return {id: el.value}
            else return {name: el.text ?? el.name}
          })
        }
        if (finalNote.advertisements.length > 0) {
          finalNote.advertisements = finalNote.advertisements.map(el => {
            return {id: el.value}
          })
        }
        if (finalNote.foreignLanguages?.length > 0) {
          finalNote.foreignLanguages = finalNote.foreignLanguages.map(el => {
            return {id: el.value}
          })
        }
        if (finalNote.foreignStates?.length > 0) {
          finalNote.foreignStates = finalNote.foreignStates.map(el => {
            return {id: el.value}
          })
        }
        finalNote.org = {id: this.orgId}
        if (!finalNote.actual)
          finalNote.olympiad = finalNote.nameOlympic;
        if (this.userAction === 'print') {
          this.formDataForPrint = finalNote
          this.userAction = null
          console.log(finalNote)
        } else if (this.userAction === 'send') {
          this.sendingOlympiad = true;
          const res = await setForm('formTwo', finalNote);
          res.profiles = res.profiles ?? [];
          const index = this.formData.findIndex(el => el.id === finalNote.id);
          if (finalNote.id)
            this.formData.splice(index, 1, res)
          else
            this.formData.push(res)
          this.updateNewProfiles(res.profiles);
          this.openModal = false
          this.clearDefaultValue();
          this.userAction = null
          this.sendingOlympiad = false;
          if (!this.getUserInfo?.sectionOne?.form2?.haveOlympiads)
            this.saveUserInfo({
              ...this.getUserInfo,
              sectionOne: {
                ...this.getUserInfo.sectionOne,
                form2: {
                  complete: false,
                  haveOlympiads: true,
                  fileId: ''
                },
              }
            });
        }
      } catch (e) {
        this.sendingOlympiad = false;
      }
    },
    async deleteRow(id, index) {
      const accept = confirm('Удалить запись?')
      if (accept) {
        try {
          this.deleting = {value: true, id: index};
          await deleteForm('formTwo', id)
          this.formData.splice(index, 1)
          this.deleting = {value: false, id: index};
        } catch (e) {
          this.deleting = {value: false, id: index};
        }
      }
    },
    downloadPrintForm() {

    },
    downloadSignedScan() {

    }
  },
};
</script>

<style lang="scss" scoped>
.container__table {
  max-width: 100%;
  overflow: auto;
}

.table2 {
  color: #89a0b0;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 6px 18px rgb(0 0 0 / 6%);
  margin-bottom: 20px;
  padding: 20px;
  width: fit-content;
}

.table__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid;

  > div:first-child {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > div:last-child {
    border-top: 1px solid;
  }
}

.table__row {
  display: grid;
  font-weight: bold;
  border-left: 1px solid;
}

.table__body {
  font-weight: normal;
}

.button__add {
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding: 14px 10px;
  margin-top: 40px;
  margin-left: auto;
  display: flex;
  margin-bottom: 40px;
  font-weight: 600;

  &:hover {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    background: #4ba0e4;
  }
}

.content__block {
  margin-bottom: 15px;
}

.button__add2 {
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding: 14px 10px;
  display: flex;
  font-weight: 600;

  &:hover {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    background: #4ba0e4;
  }
}

.text {
  padding: 5px 10px;
  border: 1px solid grey;
  border-radius: 4px;
}

.modal {
  position: fixed;
  top: 10%;
  padding: 24px 32px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 500;
  background: white;
  left: 50%;
  transform: translate(-50%, 0);
  max-height: 80%;
  overflow-y: auto;
  max-width: 920px;
  width: 100%;
}

.close {
  height: 24px;
  font-size: 24px;
  width: 24px;
  position: absolute;
  right: 8px;
  top: 8px;
}

h1 {
  max-width: 1200px;
}

.left-line {
  border-left: none;
}

.right-line {
  border-right: none;
}

.bottom-line {
  border-bottom: none;
}

.top-line {
  border-top: none;
}

.modal-header {
  //position: sticky;
  //top: -25px;
  z-index: 1;
  margin: 0;
  background-color: #ffffff;
}

.block__with-btn {
  width: 100%;
  display: grid;
  grid-template-columns: 90% 10%;
  margin-bottom: 10px;
}

.button__plus {
  border-radius: 5px;
  color: #4ba0e4;
  border: 1px solid #4ba0e4;
  background-color: #ffffff !important;
  cursor: pointer;
  padding: 14px 10px;
  margin-left: auto;
  display: flex;

  &:hover {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    background: #4ba0e4;
  }
}

.modal-mini {
  position: fixed;
  top: 34%;
  padding: 24px 32px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgb(0 0 0 / 40%);
  z-index: 500;
  background: white;
  left: 50%;
  transform: translate(-50%, 0);
  min-height: 25%;
  overflow-y: auto;
  max-width: 550px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.modal-mini__input {
  max-height: 45px;
  width: 100%;
}

.title {
  margin-bottom: 30px;
  color: rgb(13, 114, 189);
}

.main-header__title {
  font-size: 32px;
  line-height: 35.2px;
  color: #00599B;
  font-weight: 700;
  margin-bottom: 22px;
}

.person {
  border-bottom: 2px solid #A9A9A9;
  width: 730px;
  margin-bottom: 25px;
  padding: 0 12px;
  height: 40px;
  display: flex;
  align-items: flex-end;
}
</style>