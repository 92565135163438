<template>
  <WrapperPreviewCategory customClass="section-preview_lk" v-if="!isAdmin">
    <div class="file mt-4" v-if="getBlock">
      <div class="file-view">
        <div class="file-view__name">
          <span v-html="item.name"></span>
          <span
              v-if="fileFromServer && fileFromServer.id"
              class="file-link ml-2" @click="getUploadedFile"
          >
              <v-progress-circular
                  v-if="uploadedFileLoading"
                  indeterminate
                  color="primary"
                  size="14"
                  width="2"
              ></v-progress-circular> Просмотр загруженного файла
          </span>
        </div>
        <div class="file-view__date mt-1">
          <div v-if="fileFromServer">
            <!--            <span >Дата загрузки: {{uploadFileDate}}</span>-->
          </div>
          <span v-else>Необходимо загрузить pdf</span>
        </div>
        <div class="file-view__actions mt-2">
          <v-btn
              v-if="!modeEdit"
              class="orange__v-btn"
              :class="{'button_border_important' : !fileFromServer}"
              @click="modeEdit = !modeEdit">{{
              fileFromServer && fileFromServer.id ? 'Заменить' :
                  'Загрузить'
            }}
          </v-btn>
        </div>
      </div>
      <div class="file-edit" v-if="modeEdit">
        <v-file-input label="Выбрать файл pdf..." v-model="fileToUpload" :rules="rules"
                      accept="application/pdf"/>
        <!-- <v-file-input label="Выбрать файл..." v-model="fileToUpload" accept="pdf" :rules="rules"/> -->
        <v-btn
            class="orange__v-btn"
            @click="uploadFile1"
            v-if="validate"
            :loading="uploadToServNow"
            :disabled="uploadToServNow"
        >
          Загрузить
        </v-btn>
        <v-btn class="orange__v-btn ml-2" @click="modeEdit = !modeEdit">Отмена</v-btn>
      </div>

    </div>

  </WrapperPreviewCategory>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import {setFileFunctions} from "@/mixins/fileFucntions";

export default {
  name: "scanLoad",
  components: {WrapperPreviewCategory},
  mixins:[setFileFunctions],
  data() {
    return {
      item: {
        name: 'ВНИМАНИЕ!!! Скан должен содержать формы, которые выходят на печать, подписанные руководителем организации с печатью.<br>' +
            'Скан грузится одним pdf-файлом размером до 50 МБ', fileType: ''
      },
      downloadNow: false,
      fileToUpload: null,
      modeEdit: false,
      file: null,
      uploadNow: false,
      uploadedFileLoading: false,
      validate: false,
      idTypeUploadedFile: null,
      uploadToServNow: false,
      rules: [
        value => !value || value.size < 52428800 || 'Размер файла не должен превышать 50 МБ!',
        value => !value || value.type === 'application/pdf' || 'Допустимы файлы только в pdf формате',
        value => {
          if (!value) {
            this.validate = null;
            return false;
          } else if (value.type === 'application/pdf') {
            this.validate = true;
            return true;
          } else {
            this.validate = false;
            return false;
          }
        }
      ],
      canUpload: true,
    }
  },
  computed: {
    ...mapGetters('auth', {isUserBlock: 'isUserBlock', getUserFiles: 'getUserFiles'}),
    ...mapGetters('auth', {getAuthUserData: 'authData'}),
    isAdmin() {
      if (this.$route.query.orgID) {
        return true;
      }
      return false;
    },
    fileFromServer() {
      return this.getUserFiles.find(el => el.fileType === 'FINALLY_FILE');
    },
    getBlock() {
      if (this.$route.query?.orgId) {
        return true;
      }
      return this.isUserBlock;
    },
  },
  watch: {
    fileFromServer() {
      this.getFile();
    }
  },
  created() {
    this.getFile()
  },
  methods: {
    ...mapActions('auth', {deleteFileFromStore: "deleteFileFromStore", setUserFiles: 'setUserFiles'}),
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    getFile() {
      this.file = {...this.file, id: this.fileFromServer?.id, fileType: 'FINALLY_FILE' };
    },
    async uploadFile1() {
      if (this.fileToUpload.size < 52428800) {
        this.uploadToServNow = true;
        try {
          if (this.fileToUpload && this.validate) {
            if (this.fileFromServer) {
              await this.replaceFile({
                id: this.fileFromServer.id,
                fileType: 'FINALLY_FILE',
                file: this.fileToUpload
              });
            } else {
              await this.uploadFile(
                  {
                    fileType: 'FINALLY_FILE',
                    file: this.fileToUpload
                  }
              )
            }
            this.canUpload = false
          } else
            this.canUpload = false
          this.uploadToServNow = false;
          await this.setUserFiles()
        }catch (e) {
          this.canUpload = true
          this.uploadToServNow = false;
        }
      }
    },
    getUploadedFile() {
      this.downloadFile(this.fileFromServer.id)
    },
  },
}
</script>

<style scoped>

</style>