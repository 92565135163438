<template>
  <router-link :to="formLink">
    <div class="white__v-btn mb-4 card">
      <v-icon size="25" class="icon">mdi-filter-outline</v-icon>
      <span >{{ form.title }}</span>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "FormCard",
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    sectionPath: {
      type: String,
      default: ''
    }
  },
  computed: {
    formLink() {
      return `/${this.sectionPath}/${this.form.path}`
    }
  }
}
</script>

<style lang="sass" scoped>

.card
  padding: 15px
  height: 100%
  &:hover
    color: #D4990A
    span
      text-decoration: underline
.icon
  color: #0288D1


</style>