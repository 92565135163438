import {getStringFromBoolean} from "@/utils/formData";

export default {
  countCells: 6,
  massive: [
    {
      dataField: 'org',
      cols: 3,
      rows: 1,
      text: 'Наименование Организации',
      class: 'd-flex flex-row align-center justify-start ',
      headerClass: 'justify-start ',
      headerStyles: `min-width: 260px; justify-content: flex-start;`,
      order: 0,
      variable: true,
      template: (r) => `id: ${r.org?.id}<br>имя: ${r.org?.name}`
    },
    {
      dataField: 'status',
      cols: 2,
      rows: 1,
      order: 2,
      variable: true,
      headerClass: ' ',
      class: 'px-2',
      headerStyles: `min-width: 200px`,
      text: `Статус`,
      template: (r) => `статус блокировки: ${getStringFromBoolean(r.userInfo?.blocked || r.userInfo?.blockedWithoutData)}<br>
                наличие контактов: ${getStringFromBoolean(r.userInfo?.haveContacts)}`
    },
    {
      dataField: 'actions',
      cols: 1,
      rows: 1,
      order: 9,
      headerClass: '',
      variable: true,
      text: '',
    },

  ]
}
