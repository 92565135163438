//import Api from "@/utils/Api";
import {getSpr} from "@/Api/spr";

export default {
    namespaced: true,
    state: {
        sprDataOrg: [],
        sprDataRegion: [],
        sprDataLanguages: [],
        sprDataOksm: [],
        sprOrgOlympiadsSchool: [],
        sprOrgOlympiadsNotSchool: [],
        sprDataOlympiadProfileSchool: [],
        sprDataOlympiadProfileNotSchool: [],
        sprDataInformChannels: [],
        sprDataAdvertisement: [],
        sprDataOrgLoading: false,
        sprDataRegionLoading: false,
        sprDataLanguagesLoading: false,
        sprDataOksmLoading: false,
        sprOrgOlympiadsSchoolLoading: false,
        sprOrgOlympiadsNotSchoolLoading: false,
        sprDataOlympiadProfileSchoolLoading: false,
        sprDataOlympiadProfileNotSchoolLoading: false,
        sprDataInformChannelsLoading: false,
        sprDataAdvertisementLoading: false,
        sprDataOrgError: null,
        sprDataRegionError: null,
        sprDataLanguagesError: null,
        sprDataOksmError: null,
        sprOrgOlympiadsSchoolError: null,
        sprOrgOlympiadsNotSchoolError: null,
        sprDataOlympiadProfileSchoolError: null,
        sprDataOlympiadProfileNotSchoolError: null,
        sprDataInformChannelsError: null,
        sprDataAdvertisementError: null
    },
    actions: {
        async downloadSprData({commit}) {
            /*${rootGetters['keycloak/getOrgId']}*/
            const apiInfo = [
                {name: 'region', api: 'spr/region', stateName: 'sprDataRegion'},
                // {name: 'orgOlympiadsSchool', api: `spr/olympiadName/getOgrSchool?ogr=true&forSchool=true`, stateName: 'sprOrgOlympiadsSchool'},
                // {name: 'orgOlympiadsNotSchool', api: `spr/olympiadName/getOgrSchool?ogr=false&forSchool=false`, stateName: 'sprOrgOlympiadsNotSchool'},
                // {name: 'olympiadProfileSchool', api: `spr/olympiadProfile?ogr=false&forSchool=true`, stateName: 'sprDataOlympiadProfileSchool'},
                // {name: 'olympiadProfileNotSchool', api: `spr/olympiadProfile?ogr=false&forSchool=false`, stateName: 'sprDataOlympiadProfileNotSchool'},
                {name: 'oksm', api: 'spr/oksm/getAll', stateName: 'sprDataOksm'},
                {name: 'languages', api: 'spr/languages/getAll', stateName: 'sprDataLanguages'},
                {name: 'informChannels', api: 'spr/informChannels', stateName: 'sprDataInformChannels'},
                {name: 'advertisement', api: 'spr/advertisement', stateName: 'sprDataAdvertisement'},
            ];
            apiInfo.forEach(el => commit('SET_DATA_SPR_LOADING', {name: `${el.stateName}Loading`, value: true}))
            const requests = apiInfo.map(async el => await getSpr(el.api, el.stateName));
            await Promise.allSettled(requests).then(x => {
                x.forEach(el => {
                    commit('SET_DATA_SPR', {name: `${el.value.stateName}`, value: el.value.data});
                    commit('SET_DATA_SPR_LOADING', {name: `${el.value.stateName}Loading`, value: false});
                })
            }).catch(e => {
                if (Array.isArray(e))
                    e.forEach(el => {
                        commit('SET_DATA_SPR_ERROR', {name: `${el.value.stateName}Error`, value: el});
                        commit('SET_DATA_SPR_LOADING', {name: `${el.value.stateName}Loading`, value: false});
                    })
                else {
                    commit('SET_DATA_SPR_ERROR', {name: `${e.value.stateName}Error`, value: e});
                    commit('SET_DATA_SPR_LOADING', {name: `${e.value.stateName}Loading`, value: false});
                }
            })
        },
        async downloadSprDataExtended({commit}) {
            const apiInfo = [
                {
                    name: 'orgOlympiadsSchool',
                    api: `spr/olympiadName/getOgrSchool?ogr=true&forSchool=true`,
                    stateName: 'sprOrgOlympiadsSchool',
                    splice: true
                },
                {
                    name: 'orgOlympiadsNotSchool',
                    api: `spr/olympiadName/getOgrSchool?ogr=true&forSchool=false`,
                    stateName: 'sprOrgOlympiadsNotSchool',
                    splice: true
                },
                {
                    name: 'orgOlympiadsSchool',
                    api: `spr/olympiadName/getOgrSchool?ogr=false&forSchool=true`,
                    stateName: 'sprOrgOlympiadsSchool',
                    splice: true
                },
                {
                    name: 'orgOlympiadsNotSchool',
                    api: `spr/olympiadName/getOgrSchool?ogr=false&forSchool=false`,
                    stateName: 'sprOrgOlympiadsNotSchool',
                    splice: true
                },
                {
                    name: 'olympiadProfileSchool',
                    api: `spr/olympiadProfile?ogr=false&forSchool=true`,
                    stateName: 'sprDataOlympiadProfileSchool',
                    splice: true
                },
                {
                    name: 'olympiadProfileNotSchool',
                    api: `spr/olympiadProfile?ogr=false&forSchool=false`,
                    stateName: 'sprDataOlympiadProfileNotSchool',
                    splice: true
                },
                {
                    name: 'olympiadProfileSchool',
                    api: `spr/olympiadProfile?ogr=true&forSchool=true`,
                    stateName: 'sprDataOlympiadProfileSchool',
                    splice: true
                },
                {
                    name: 'olympiadProfileNotSchool',
                    api: `spr/olympiadProfile?ogr=true&forSchool=false`,
                    stateName: 'sprDataOlympiadProfileNotSchool',
                    splice: true
                },
            ];
            apiInfo.forEach(el => commit('SET_DATA_SPR_LOADING', {name: `${el.stateName}Loading`, value: true}))
            const requests = apiInfo.map(async el => await getSpr(el.api, el.stateName, el.splice));
            await Promise.allSettled(requests).then(x => {
                x.forEach(el => {
                    commit('SET_DATA_SPR', {
                        name: `${el.value.stateName}`,
                        value: el.value.data,
                        pushing: el.value.splice
                    });
                    commit('SET_DATA_SPR_LOADING', {name: `${el.value.stateName}Loading`, value: false});
                })
            }).catch(e => {
                if (Array.isArray(e))
                    e.forEach(el => {
                        commit('SET_DATA_SPR_ERROR', {name: `${el.value.stateName}Error`, value: el});
                        commit('SET_DATA_SPR_LOADING', {name: `${el.value.stateName}Loading`, value: false});
                    })
                else {
                    commit('SET_DATA_SPR_ERROR', {name: `${e.value.stateName}Error`, value: e});
                    commit('SET_DATA_SPR_LOADING', {name: `${e.value.stateName}Loading`, value: false});
                }
            })
        },
        setProfile({commit}, payload){
            commit('ADD_PROFILE', payload);
            return payload
        },
        updateNewProfiles({commit}, profilesArray){
            profilesArray.forEach(el => commit('UPDATE_PROFILE', el))
        },
        setOlympiad({commit}, payload){
            commit('ADD_OLYMPIAD', payload)
        }
    },
    mutations: {
        ['SET_DATA_SPR'](state, payload) {
            if (payload.pushing)
                state[payload.name].push(...payload.value);
            else
                state[payload.name] = payload.value;
        },
        ['UPDATE_PROFILE'](state, payload){
            const isSchool = !!payload.forSchool;
            const ind = state[isSchool ? 'sprDataOlympiadProfileSchool' : 'sprDataOlympiadProfileNotSchool'].findIndex(el => el.id === payload.id);
            if (ind === -1){
                const newInd = state[isSchool ? 'sprDataOlympiadProfileSchool' : 'sprDataOlympiadProfileNotSchool'].findIndex(el => el.name === payload.name);
                state[isSchool ? 'sprDataOlympiadProfileSchool' : 'sprDataOlympiadProfileNotSchool'].splice(newInd, 1, payload);
            }
        },
        ['ADD_PROFILE'](state, payload) {
            const isSchool = !!payload.forSchool;
            state[isSchool ? 'sprDataOlympiadProfileSchool' : 'sprDataOlympiadProfileNotSchool'].push(payload)
        },
        ['ADD_OLYMPIAD'](state, payload) {
            const isSchool = !!payload.forSchool;
            console.log('pa',isSchool,  payload)
            state[isSchool ? 'sprOrgOlympiadsSchool' : 'sprOrgOlympiadsNotSchool'].push(payload)
        },
        ['SET_DATA_SPR_LOADING'](state, payload) {
            state[payload.name] = payload.value;
        },
        ['SET_DATA_SPR_ERROR'](state, payload) {
            state[payload.name] = payload.value;
        },
    },
    getters: {
        getSprData(state) {
            return {
                sprDataRegion: state.sprDataRegion,
                sprDataInformChannels: state.sprDataInformChannels,
                sprDataAdvertisement: state.sprDataAdvertisement,
                sprDataOksm: state.sprDataOksm,
                sprDataLanguages: state.sprDataLanguages,
                sprDataOlympiadProfileSchool: state.sprDataOlympiadProfileSchool,
                sprDataOlympiadProfileNotSchool: state.sprDataOlympiadProfileNotSchool,
            }
        },
        getSprDataLoading(state) {
            return {
                sprDataRegionLoading: state.sprDataRegionLoading,
                sprDataInformChannelsLoading: state.sprDataInformChannelsLoading,
                sprDataAdvertisementLoading: state.sprDataAdvertisementLoading,
                sprDataOksmLoading: state.sprDataOksmLoading,
                sprDataLanguagesLoading: state.sprDataLanguagesLoading,
                sprDataOlympiadProfileSchoolLoading: state.sprDataOlympiadProfileSchoolLoading,
                sprDataOlympiadProfileNotSchoolLoading: state.sprDataOlympiadProfileNotSchoolLoading,
            }
        },
        getOrgOlympiadsSchool(state) {
            return state.sprOrgOlympiadsSchool?.map(el => {
                return {id: el.id, fl: el.fl, forSchool: true, text: el.name, value: el.id}
            })
        },
        getOrgOlympiadsNotSchool(state) {
            return state.sprOrgOlympiadsNotSchool?.map(el => {
                return {id: el.id, fl: el.fl, forSchool: false, text: el.name, value: el.id}
            })
        },
    }
}