export const tablePagination = {
    data() {
        return {
            page: 1,
            userPages: 25,
            pagesPaginate:[
                {value: 25,   text: 25},
                {value: 10000, text: 'все'},
            ]
        }
    },
    watch: {
        lengthData(newValue) {
            if (newValue < 50)
                this.userPages = 10000;
            else
                this.userPages = 25;
        }
    },
    computed: {
        showingServerData(){
            return this.serverData.slice((this.page-1) * this.userPages, (this.page) * this.userPages)
        },
        pagesVuetify(){
            return Math.ceil(this.serverData.length/this.userPages);
        },
        lengthData(){
            return this.serverData.length
        }
    },
    methods: {
        changePage(){
            // this.loadingData = true;
            // setTimeout(() => {this.loadingData = false;}, 300)
        },
    },

}