import {formatDate, getNamesFromArray, getStringFromBoolean} from "@/utils/formData";

export default {
  countCells: 19,
  massive: [
    {
      dataField: 'actions',
      cols: 1,
      rows: 1,
      order: 9,
      class: 'd-flex flex-row align-start justify-center ',
      headerClass: '',
      variable: true,
      text: '',
    },
    {
      dataField: 'nameOlympic',
      cols: 1,
      rows: 1,
      text: 'Наименование олимпиады',
      class: 'd-flex flex-row align-start justify-start ',
      headerClass: 'justify-start ',
      headerStyles: `min-width: 260px; justify-content: flex-start;`,
      order: 0,
      variable: true,
      template: (r)=>  r.olympiad?.name
    },
    {
      dataField: 'actual',
      cols: 1,
      rows: 1,
      text: `Входит ли в Перечень?`,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      headerStyles: ``,
      headerTooltip: true,
      headerTooltipText: () => `Входит ли олимпиада в Перечень олимпиад школьников и их уровней на
                2021/22 учебный год, утвержденный Приказом Минобрнауки России от
                31.08.2021 № 804`,
      order: 1,
      variable: true,
      template: (r)=>  getStringFromBoolean(r.actual)
    },
    // {
    //   dataField: 'profiles',
    //   cols: 1,
    //   rows: 1,
    //   order: 2,
    //   variable: true,
    //   headerClass: ' ',
    //   class: 'px-2 d-flex flex-column justify-center',
    //   headerStyles: `min-width: 200px`,
    //   styles: 'position: relative;',
    //   text: `Наименования(-е) профилей(-я) олимпиады`,
    // },
    {
      dataField: 'startYear',
      cols: 1,
      rows: 1,
      order: 3,
      headerClass: '',
      class: `align-start`,
      variable: true,
      text: `С какого года проводится олимпиада?`,
      template: (r)=>  formatDate(r.startYear)
    },
    {
      dataField: 'isForeignPlaces',
      cols: 1,
      rows: 1,
      order: 3,
      headerClass: 'd-flex flex-row align-center',
      class: `align-start`,
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Выделялись ли на олимпиаду места для отбора иностранных граждан в пределах квоты Правительства Российской
Федерации на 2022/2023 учебный год?`,
      text: `Выделялись ли места?`,
      template: (r)=>  getStringFromBoolean(r.isForeignPlaces)
    },
    {
      dataField: 'foreignPlaces',
      cols: 1,
      rows: 1,
      order: 3,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: `Какое количество мест было выделено на олимпиаду? `,
    },
    {
      dataField: 'foreignPlacesConfirmed',
      cols: 1,
      rows: 1,
      order: 3,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: `Какое количество мест было освоено по олимпиаде?`,
    },
    {
      dataField: 'site',
      cols: 1,
      rows: 1,
      order: 3,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: `Официальный сайт олимпиады`,
    },
    {
      dataField: 'paragraphNumber',
      cols: 1,
      rows: 1,
      order: 4,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Номер пункта в положении об олимпиаде, где указано условие о возможности участия в ней иностранных граждан/комментарий об отсутствии в положении ограничений на участие иностранных граждан в данной олимпиаде`,
      text: 'Номер пункта',
    },
    {
      dataField: 'maintainerOrg',
      cols: 1,
      rows: 1,
      order: 5,
      class: `align-start`,
      headerClass: 'd-flex flex-row align-center',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Является ли ваша организация основным организатором олимпиады?`,
      text: `Организатор олимпиады?`,
      template: (data)=>  getStringFromBoolean(data.maintainerOrg)
    },
    {
      dataField: 'maintainerOrgName',
      cols: 1,
      rows: 1,
      order: 6,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: `Наименование основного организатора олимпиады `,
      template: (data)=>   data.maintainerOrgName?.join(',<br> ')
    },
    {
      dataField: 'helpersOrgNames',
      cols: 1,
      rows: 1,
      order: 7,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: `Наименование(-я) соорганизатора(-ов) олимпиады `,
      template: (data)=>  data.helpersOrgNames?.join(',<br> ')
    },
    {
      dataField: 'distance',
      cols: 1,
      rows: 1,
      order: 8,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Есть ли возможность дистанционного участия иностранных граждан в олимпиаде?`,
      text: 'Дистанционное участие',
      template: (data)=>  getStringFromBoolean(data.distance)
    },
    {
      dataField: 'informChannels',
      cols: 1,
      rows: 1,
      order: 9,
      class: 'px-2 align-start',
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Каналы(-) информирования иностранных граждан об олимпиаде`,
      text: 'Каналы информирования',
      template: (data)=>  getNamesFromArray(data.informChannels)
    },
    {
      dataField: 'advertisements',
      cols: 1,
      rows: 1,
      order: 10,
      class: `align-start`,
      headerClass: '',
      variable: true,
      headerStyles: `min-width: 200px; justify-content: flex-start;`,
      text: 'Мероприятия по продвижению олимпиады',
      headerTooltip: true,
      headerTooltipText: () => `Запланированные мероприятия по продвижению олимпиады среди иностранных граждан`,
      template: (data)=>  getNamesFromArray(data.advertisements)
    },
    {
      dataField: 'experts',
      cols: 1,
      rows: 1,
      order: 11,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `ФИО и квалификация международных(-ого) экспертов (-а) состоящих (-его) в организационном комитете/методической комиссии/жюри олимпиады`,
      text: 'ФИО и квалификация экспертов',
      template: (data)=>  data.experts?.length > 0 ? getNamesFromArray(data.experts, ['name', 'qualification']) : '-'
    },
    {
      dataField: 'minKnowledgeRequired',
      cols: 1,
      rows: 1,
      order: 12,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Имелись ли требования к минимальному уровню знаний, необходимых для успешного прохождения олимпиады?`,
      text: 'Требования к минимальному уровню знаний',
      template: (data)=>  getStringFromBoolean(data.minKnowledgeRequired)
    },
    {
      dataField: 'minKnowledgeRequiredInfo',
      cols: 1,
      rows: 1,
      order: 13,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      text: 'Минимальные требования знаний',
      headerTooltip: true,
      headerTooltipText: () => `Какие были требования к минимальному уровню знаний, необходимых для успешного прохождения олимпиады `,
    },
    {
      dataField: 'fileId',
      cols: 1,
      rows: 1,
      order: 14,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      text: 'Положение об олимпиаде',
    },


  ]
}
