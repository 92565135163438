import {mapGetters} from "vuex";

export const isBlock =  {
    computed: {
        ...mapGetters('auth', {isUserBlock: 'isUserBlock'}),
        ...mapGetters('keycloak', {isAdminGroup: 'isAdminGroup'}),
        getBlock() {
            return this.isUserBlock || this.isAdminGroup
        },
    },

}