<template>
  <v-dialog
      v-model="show"
      scrollable
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
      max-width="400px"
      style="z-index: 2147483647!important;"
  >
    <v-card>
       <span>
        <v-btn
            icon
            @click="closeDialog()"
            style="position: absolute; right:0;"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>
      <v-card-title></v-card-title>
      <v-card-text class="d-flex flex-column align-center justify-center pb-0">
        <slot name="title">
          <p class="font-size-medium" v-html="text"></p>
        </slot>
      </v-card-text>
      <v-card-actions class="d-flex flex-row align-center justify-center">

        <slot name="actions" :close="closeDialog" :agree="agree">
          <v-btn
              class='blue__v-btn'
              @click="agree()"
              width="80px"
          >
            Да
          </v-btn>
          <v-btn
              class='white__v-btn ml-2'
              @click="closeDialog()"
              width="80px"
          >
            Нет
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "confirmDialog",
  components: {},
  data() {
    return {
      show: false,
    }
  },
  props: {
    text: {
      type: String,
      default: ''
    },
  },
  dialogController: null,
  methods: {
    closeDialog() {
      this.$emit('closing-dialog');
      this.$options.dialogController.resolve(false);
      this.show = false;
    },
    agree(){
      this.$emit('agree');
      this.$options.dialogController.resolve(true);
      this.show = false;
    },
    openDialog() {
      let resolve;
      let reject;
      const dialogPromise = new Promise((ok, fail) => {
        resolve = ok;
        reject = fail;
      })
      this.$options.dialogController = {resolve, reject};
      this.show = true;
      return dialogPromise;
    },
  },
}
</script>

<style lang="sass">

</style>