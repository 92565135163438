<template>
  <v-container>
    <v-btn class="white__v-btn mb-10" @click="$router.go(-1)">Назад</v-btn>
    <h1 class="main-header__title">{{ sectionData.title }}</h1>
    <div class="cards">
      <form-card :form="form" :section-path="sectionData.path" v-for="form in sectionData.forms" :key="form.id"/>
    </div>
  </v-container>
</template>

<script>
import FormCard from "@/View/FormCard";
import { mapGetters } from 'vuex';

export default {
  name: "SectionWithForms",
  components: {
    FormCard
  },
  computed: {
    ...mapGetters('sectionsAndForms', {getSectionsData: 'getSectionsData'}),

    sectionData() {
      const allSections = this.getSectionsData
      const currentSectionIndex = this.$route.path.substr(-1) - 1
      return allSections[currentSectionIndex]
    },
  }
}
</script>

<style scoped>

.main-header__title {
  font-size: 32px;
  line-height: 35.2px;
  color: #00599B;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  max-width: 1200px;
  margin-bottom: 30px;
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10%;
  width: 100%;
  margin: auto;
}

</style>