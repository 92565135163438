import {getStringFromBoolean} from "@/utils/formData";

export default {
  countCells: 3,
  massive: [
    {
      dataField: 'org',
      cols: 1,
      rows: 1,
      text: 'Наименование Организации',
      class: 'd-flex flex-row align-center justify-start ',
      headerClass: 'justify-start ',
      headerStyles: `min-width: 260px; justify-content: flex-start;`,
      order: 0,
      variable: true,
      template: (r) => `id: ${r.orgId?.id}<br>имя: ${r.orgId?.name}`
    },
    {
      dataField: 'olympiad',
      cols: 1,
      rows: 1,
      order: 9,
      headerClass: '',
      variable: true,
      class: 'justify-start',
      text: 'Олимпиада',
      template: (r) => `
id: ${r.olympiadNameId?.id}<br>
школа: ${getStringFromBoolean(r.olympiadNameId?.forSchool)}<br>
имя: ${r.olympiadNameId?.name}`

    },
    {
      dataField: 'profile',
      cols: 1,
      rows: 1,
      order: 2,
      variable: true,
      class: 'justify-start',
      text: `Профиль`,
      template: (r) => `
id: ${r.olympiadProfileId?.id}<br>
школа: ${getStringFromBoolean(r.olympiadProfileId?.forSchool)}<br>
имя: ${r.olympiadProfileId?.name}`
    },
  ]
}
