<template>
  <wrapper-preview-category>

    <h1 class="main-header__title">{{ pageTitle }}</h1>
    <div>
      <Alert alert-type="success">
        <template #icon>
          <v-icon color="#29A128" size="40">mdi-check-bold</v-icon>
        </template>
        <template #default>
          <div class="d-flex flex-row justify-space-between align-center">
            <p>После заполнения формы 1 обязательно перейдите к заполнению формы 1.1</p>
          </div>
        </template>
      </Alert>
      <div class="mx-2 mb-2 d-flex justify-space-between align-center">
        <v-btn class="blue__v-btn" :disabled="getBlock" @click="openModal = true">Добавить запись</v-btn>
        <v-btn
            class="blue__v-btn"
            :loading="downloadingFileForPrint"
            @click="downloadPrintDocument(`/api/excelReport?num=1m&orgId=${orgId}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')"
        >
          Скачать заполненную форму в формате Excel для самопроверки
        </v-btn>
      </div>
      <GridTable
          :headers="headers.massive"
          :headers-for-rows="headers.massive"
          :num-cols="headers.countCells"
          :loading-data="loading"
          :data-rows="formDataForTable"
          class="ma-3"
      >
        <template #fileId="{row}">
          <v-btn
              color="primary"
              icon
              @click="downloadFile(row.fileId)">
            <v-icon size="20" color="primary">mdi-file-outline</v-icon>
          </v-btn>
        </template>
        <template #actions="{row, index}">
          <v-btn :disabled="getBlock" :loading="sendingOlympiad" icon @click="changeRow(row.id, index)">
            <v-icon class="flex align-center" color="primary">
              mdi-pencil
            </v-icon>
          </v-btn>
          <div class="d-flex flex-row align-center">
            <v-btn :disabled="getBlock || !row.canDeleted" :loading="deleting.id === row.id && deleting.value" icon
                   @click="deleteRow(row.id, index)">
              <v-icon class="flex align-center" color="primary">
                mdi-delete
              </v-icon>
            </v-btn>
            <v-tooltip
                top
                color="primary"
            >
              <template #activator="{on, attrs}">
                <v-icon
                    v-if="!row.canDeleted"
                    icon
                    v-on="on"
                    v-bind="attrs"
                    size="22"
                    color="primary"
                >
                  mdi-help
                </v-icon>
              </template>
              <p class="white--text mb-0 pb-0">Удалите сначала все зависимые строки из формы 1.1</p>
            </v-tooltip>
          </div>

        </template>
      </GridTable>
      <div class="d-flex justify-end">
        <v-btn class="white__v-btn mb-2 mr-2"
               @click="goNextStage">
          Перейти к заполнению следующей формы
        </v-btn>
      </div>
      <!--      Вы ввели всю имеющуюся информацию и готовы завершить заполнение данной формы?-->
      <v-dialog
          v-model="openModal"
          max-width="1000px"
          persistent
          @keydown.esc="closeMainDialog()"
      >
        <v-card>
          <v-card-title class="modal-header justify-space-between">
            <div class="addNote title">Добавление записи</div>
            <v-icon class="close" @click="closeMainDialog">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <div class="content__block">Входит ли олимпиада в распределение общего количества мест, выделенных на олимпиады для лиц, претендующих на обучение по образовательным программам высшего образования- программы магистратуры в пределах квоты Правительства Российской Федерации в 2022/23 учебном году утвержденного Заместителем Министра науки и высшего образования Российской Федерации Д.В. Афанасьевым от 30.09.2021 года
              <v-radio-group
                  v-model="newNote.actual"
                  :error-messages="errorNote.actual"
                  row
              >
                <v-radio :value=true label="Да"></v-radio>
                <v-radio :value=false label="Нет"></v-radio>
              </v-radio-group>
            </div>
            <div class="content__block">
              Наименование олимпиады
              <div v-if="!newNote.actual">
                <v-text-field
                    v-model="newNote.nameOlympic"
                    :disabled="newNote.actual === null"
                    dense
                    outlined
                ></v-text-field>
              </div>
              <div v-else>
                <v-autocomplete
                    v-model="newNote.olympiad.id"
                    :disabled="newNote.actual === null"
                    :error-messages="errorNote.nameOlympic"
                    :items="orgOlympiads"
                    dense
                    outlined
                />
              </div>
            </div>
<!--            <div class="content__block">
              <div class="block__with-btn">
                Наименования(-е) профилей (-я) олимпиады
              </div>
              <div class="d-flex flex-row">
                <v-autocomplete
                    v-model="newNote.profiles"
                    :disabled="(newNote.nameOlympic === null || !newNote.nameOlympic) && !newNote.olympiad.id"
                    :error-messages="errorNote.profiles"
                    :items="orgOlympiadsProfile"
                    dense
                    multiple
                    outlined
                    no-data-text="По вашему запросу ничего нет"
                    return-object
                >
                </v-autocomplete>
                <v-tooltip
                    top
                    color="primary"
                >
                  <template #activator="{on, attrs}">
                    <v-btn
                        v-if="!newNote.actual"
                        :disabled="(newNote.nameOlympic === null || !newNote.nameOlympic) && !newNote.olympiad.id"
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="openCustomDialog('profile')">
                      <v-icon size="35" color="primary">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <p class="white&#45;&#45;text mb-0 pb-0">Добавить свой вариант</p>
                </v-tooltip>
              </div>
            </div>-->
            <div class="content__block">
              Укажите с какого года проводится олимпиада
              <div class="d-flex flex-row align-start">
                <v-text-field v-model="newNote.startYear"
                              label="Год-месяц-день"
                              :disabled="!newNote.olympiad && !newNote.nameOlympic"
                              :error-messages="errorNote.startYear"
                              dense
                              outlined
                              v-mask="'####-##-##'"
                />
                <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        :disabled="!newNote.olympiad && !newNote.nameOlympic"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon color="primary" size="26">mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                      v-model="newNote.startYear"
                      locale="ru"
                      no-title
                      scrollable
                  >
                  </v-date-picker>
                </v-menu>
              </div>


            </div>
            <div class="content__block">
              Является ли ваша организация основным организатором олимпиады?
              <v-radio-group
                  v-model="newNote.maintainerOrg"
                  :disabled="!newNote.olympiad && !newNote.nameOlympic"
                  :error-messages="errorNote.maintainerOrg"
                  row
              >
                <v-radio :value="true" label="Да">
                </v-radio>
                <v-radio :value="false" label="Нет"></v-radio>
              </v-radio-group>
            </div>
            <div class="content__block" v-if="newNote.maintainerOrg !== true">
              Укажите наименование основного организатора олимпиады (ввести полное наименование по ЕГРЮЛ)
              <div class="d-flex flex-row">
                <v-autocomplete
                    v-model="newNote.maintainerOrgName"
                    :disabled="!newNote.olympiad && !newNote.nameOlympic"
                    :error-messages="errorNote.maintainerOrgName"
                    :items="maintainerOrgNameList"
                    dense
                    multiple
                    outlined
                    no-data-text="По вашему запросу ничего нет"
                >
                </v-autocomplete>
                <v-tooltip
                    top
                    color="primary"
                >
                  <template #activator="{on, attrs}">
                    <v-btn
                        :disabled="!newNote.olympiad && !newNote.nameOlympic"
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="openCustomDialog('orgs', null, 'Наименование основного организатора олимпиады')">
                      <v-icon size="35" color="primary">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <p class="white&#45;&#45;text mb-0 pb-0">Добавить свой вариант</p>
                </v-tooltip>
              </div>
            </div>
            <div class="content__block">
              Укажите наименование(-я) соорганизатора (-ов) олимпиады (ввести полное наименование по ЕГРЮЛ)
              <div class="d-flex flex-row">
                <v-autocomplete
                    v-model="newNote.helpersOrgNames"
                    :disabled="!newNote.olympiad && !newNote.nameOlympic"
                    :error-messages="errorNote.helpersOrgNames"
                    :items="helpersOrgNameList"
                    dense
                    multiple
                    outlined
                    no-data-text="По вашему запросу ничего нет"
                >
                </v-autocomplete>
                <v-tooltip
                    top
                    color="primary"
                >
                  <template #activator="{on, attrs}">
                    <v-btn
                        :disabled="!newNote.olympiad && !newNote.nameOlympic"
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="openCustomDialog('orgsHelpers', null, 'Наименование соорганизатора олимпиады')">
                      <v-icon size="35" color="primary">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <p class="white&#45;&#45;text mb-0 pb-0">Добавить свой вариант</p>
                </v-tooltip>
              </div>
            </div>
            <div class="content__block">
              Выделялись ли на олимпиаду места для отбора иностранных граждан в пределах квоты Правительства Российской
              Федерации на 2022/2023 учебный год?
              <v-radio-group
                  v-model="newNote.isForeignPlaces"
                  :disabled="!newNote.olympiad && !newNote.nameOlympic"
                  :error-messages="errorNote.isForeignPlaces"
                  row
              >
                <v-radio :value="true" label="Да">
                </v-radio>
                <v-radio :value="false" label="Нет"></v-radio>
              </v-radio-group>
            </div>
            <div class="content__block" v-if="newNote.isForeignPlaces === true">
              Какое количество мест было выделено на олимпиаду?
              <v-text-field v-model="newNote.foreignPlaces"
                            type="number"
                            :disabled="!newNote.olympiad && !newNote.nameOlympic"
                            :error-messages="errorNote.foreignPlaces"
                            dense
                            outlined/>
            </div>
            <div class="content__block" v-if="newNote.isForeignPlaces === true"><!--v-if="newNote.isForeignPlaces === true"-->
              Какое количество мест было освоено по олимпиаде?
              <v-text-field v-model="newNote.foreignPlacesConfirmed"
                            type="number"
                            :disabled="!newNote.olympiad && !newNote.nameOlympic"
                            :error-messages="errorNote.foreignPlacesConfirmed"
                            dense
                            outlined/>
            </div>

            <div class="content__block">
              Официальный сайт олимпиады
              <v-text-field v-model="newNote.site" :disabled="!newNote.olympiad && !newNote.nameOlympic" dense
                            outlined/>
            </div>
            <div class="content__block">
              Номер пункта в положении об олимпиаде, где указано условие о возможности участия в ней иностранных
              граждан/комментарий об отсутствии в положении ограничений на участие иностранных граждан в данной
              олимпиаде
              <v-text-field v-model="newNote.paragraphNumber"
                            :disabled="!newNote.olympiad && !newNote.nameOlympic"
                            dense
                            :error-messages="errorNote.paragraphNumber"
                            outlined/>
            </div>
            <div class="content__block">
              Была ли возможность дистанционного участия иностранных граждан в олимпиаде?
              <v-radio-group
                  v-model="newNote.distance"
                  :disabled="!newNote.olympiad && !newNote.nameOlympic"
                  :error-messages="errorNote.distance"
                  row
              >
                <v-radio :value="true" label="Да">
                </v-radio>
                <v-radio :value="false" label="Нет"></v-radio>
              </v-radio-group>
            </div>
            <div class="content__block">
              Какие каналы информирования иностранных граждан об олимпиаде были использованы
              <div class="">
                <v-autocomplete
                    v-model="newNote.informChannels"
                    :disabled="!newNote.olympiad && !newNote.nameOlympic"
                    :items="sprInformChannels"
                    dense
                    multiple
                    outlined
                    no-data-text="По вашему запросу ничего нет"
                    return-object
                >
                </v-autocomplete>
              </div>
            </div>
            <div class="content__block">
              Какие мероприятия(-е) по продвижению олимпиады среди иностранных граждан были использованы
              <div class="">
                <v-autocomplete
                    v-model="newNote.advertisements"
                    :disabled="!newNote.olympiad && !newNote.nameOlympic"
                    :items="spradvertisements"
                    dense
                    multiple
                    outlined
                    no-data-text="По вашему запросу ничего нет"
                    return-object
                >
                </v-autocomplete>
              </div>
            </div>
            <div class="content__block">
              <div class="block__with-btn">
                <div>
                  ФИО и квалификация международных(-ого) экспертов(-а), состоящих(-его) в
                  организационном комитете/ методической комиссии /жюри олимпиады
                </div>
                <v-btn
                    :disabled="(newNote.nameOlympic === null || !newNote.nameOlympic) && !newNote.olympiad.id"
                    icon
                    @click="openCustomDialog">
                  <v-icon size="35" color="primary">mdi-plus</v-icon>
                </v-btn>
              </div>
              <div v-for="(person, index) in newNote.experts" :key="index" class="block__with-btn">
                <div v-if="!newNote.experts.length"></div>
                <div v-else class="text d-flex align-center">
                  {{ person.name }} <span v-if="person.qualification"> - </span> {{ person.qualification }}
                </div>
                <v-btn
                    :disabled="!newNote.olympiad && !newNote.nameOlympic"
                    height="40px"
                    icon
                    @click="newNote.experts.splice(index, 1)"
                >
                  <v-icon color="error" size="22" light>
                    mdi-window-close
                  </v-icon>
                </v-btn>
              </div>
            </div>

            <div class="content__block">
              Имелись ли требования к минимальному уровню знаний, необходимых для успешного прохождения олимпиады?
              <v-radio-group
                  v-model="newNote.minKnowledgeRequired"
                  :disabled="!newNote.olympiad && !newNote.nameOlympic"
                  :error-messages="errorNote.minKnowledgeRequired"
                  row
              >
                <v-radio :value="true" label="Да">
                </v-radio>
                <v-radio :value="false" label="Нет"></v-radio>
              </v-radio-group>
            </div>
            <div class="content__block" v-if="newNote.minKnowledgeRequired === true">
              Какие были требования к минимальному уровню знаний, необходимых для успешного прохождения олимпиады
              <v-text-field v-model="newNote.minKnowledgeRequiredInfo"
                            :disabled="!newNote.olympiad && !newNote.nameOlympic"
                            dense
                            :error-messages="errorNote.minKnowledgeRequiredInfo"
                            outlined/>
            </div>
            <div class="content__block">
              <scan-card
                  class=""
                  :item="file"
                  :disabled="!newNote.olympiad && !newNote.nameOlympic"
                  :upload-to-serv-now="loadingFileToServer.fileType === file.fileType && loadingFileToServer.value"
                  @replace-file="replaceFile($event)"
                  @upload-file="uploadFile($event)"
                  :token="token"
                  identifier="fileType"
              >
              </scan-card>
            </div>
          </v-card-text>
          <v-card-actions>
            <div class="d-flex justify-space-between mt-4">
              <v-btn
                  class="blue__v-btn ml-3"
                  :loading="sendingOlympiad"

                  @click="checkForm('send')"
              >
                Сохранить
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
    <add-spr-info
        ref="spr-dialog"
        :title="titleForSprDialog"
        :fields="fieldsForSprDialog"
        :loading="addingProfileLoading"
        @send-resolve="addNewProfile($event)"
    >
    </add-spr-info>
<!--    <v-dialog
        v-model="showProfilesDialog"
        @click:outside="closeDialogProfiles()"
        @keydown.esc="closeDialogProfiles()"
        max-width="700px"
    >
      <v-card>
        <v-card-title>Добавить/Изменить профиль</v-card-title>
        <v-card-text>
          <v-form v-model="profilesDialogValid" lazy-validation>
            <div class="content__block mb-0">
              <div class="block__with-btn">
                Наименования(-е) профилей (-я) олимпиады
              </div>
              <div class="d-flex flex-row">
                <v-autocomplete
                    v-model="profilesDialogValue"
                    :disabled="!bufDataForProfilesDialog || (bufDataForProfilesDialog.nameOlympic === null || !bufDataForProfilesDialog.nameOlympic) && !bufDataForProfilesDialog.olympiad.id"
                    :error-messages="errorNote.profiles"
                    :items="orgOlympiadsProfile"
                    dense
                    multiple
                    outlined
                    no-data-text="По вашему запросу ничего нет"
                    return-object
                >
                </v-autocomplete>
                <v-tooltip
                    top
                    color="primary"
                >
                  <template #activator="{on, attrs}">
                    <v-btn
                        v-if="bufDataForProfilesDialog && !bufDataForProfilesDialog.actual"
                        :disabled="!bufDataForProfilesDialog || (bufDataForProfilesDialog.nameOlympic === null || !bufDataForProfilesDialog.nameOlympic) && !bufDataForProfilesDialog.olympiad.id"
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="openCustomDialog('profile')">
                      <v-icon size="35" color="primary">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <p class="white--text mb-0 pb-0">Добавить свой вариант</p>
                </v-tooltip>
              </div>
              <div class="d-flex flex-row justify-space-between align-center" v-for="(profile, i) in filteredDialogProfilesData" :key="i">
                <span>              {{ profile.text ? profile.text : profile.name }}</span>
                <div>
                  <v-btn icon>
                    <v-icon size="22" color="primary" @click="openCustomDialog('profile', profile)">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon size="22" color="error" @click="deleteProfile(profile)">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-form>

        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="saveProfiles" :loading="profilesDialogLoading" class="blue__v-btn">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
  </wrapper-preview-category>
</template>

<script>
//import request from "@/services/request";
import {mapActions, mapGetters} from "vuex";
// import { getNamesFromArray} from '@/utils/formData'
import ScanCard from "@/components/cards/scanCard";
import {setFileFunctions} from "@/mixins/fileFucntions";
import {deleteForm, getForm, setForm} from "@/Api/sectionTwo";
import GridTable from "@/components/GridTable/GridTable";
import headersFormOne from "@/helpers/sectionTwo/headersFormOne";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import AddSprInfo from "@/components/Dialogs/addSprInfo";
// import {filterProfiles} from "@/helpers/sectionOne/filterFunctions";
import Alert from "@/components/UI/Alert";
import {isBlock} from "@/mixins/isBlock";
// import request from "@/services/request";
//eslint-disable-next-line no-unused-vars
import {addDbProfile, deleteDbProfile} from "@/Api/spr";
import {headerFormsName} from "@/mixins/headerName";
const defaultErrorNoteValues = {
  actual: "",
  nameOlympic: "",
  startYear: "",
  profiles: "",
  distance: "",
  file: "",
  paragraphNumber: "",
  maintainerOrg: "",
  maintainerOrgName: "",
  isForeignPlaces: "",
  foreignPlaces: "",
  foreignPlacesConfirmed: "",
  minKnowledgeRequired: "",
};
export default {
  components: {Alert, AddSprInfo, WrapperPreviewCategory, GridTable, ScanCard},
  mixins: [setFileFunctions, isBlock, headerFormsName],
  name: 'FORM_1',
  data() {
    return {
      sendingChoice: false,
      titleForSprDialog: 'Добавить профиль олимпиады',
      fieldsForSprDialog: [],
      addingProfileLoading: false,
      formData: [],
      olympiadList: [],
      closeEdit: null,
      openModal: false,
      loading: false,
      bufDataForProfilesDialog: null,
      showProfilesDialog: false,
      profilesDialogLoading: false,
      profilesDialogValid: true,
      profilesDialogValue: [],
      openMiniModal: false,
      openMiniTitle: "",
      openMiniVModal: "",
      openMiniInput: "",
      openMiniSelect: false,
      openMiniVModalAdditional: "",
      openMiniInputAdditional: '',
      confirmFinishEdit: false,
      deleting: {value: false, id: null},
      errorNote: {...defaultErrorNoteValues},
      newNote: {
        actual: null,
        nameOlympic: null,
        startYear: null, //1.1
        maintainerOrg: null, //1.2
        maintainerOrgName: [], //1.2.1
        helpersOrgNames: [], //1.2.2
        isForeignPlaces: null, //1.4
        foreignPlaces: null, //1.4.1
        foreignPlacesConfirmed: null,
        // foreignPlacesConfirmed1: null,//1.4.2
        olympiad: {id: null},
        profiles: [],
        site: "",
        paragraphNumber: null, //1.5.1
        distance: null,
        informChannels: [],
        advertisements: [],
        experts: [],
        //1.5.5 зачем если и так вводят их
        minKnowledgeRequired: null,//1.6
        minKnowledgeRequiredInfo: null,//1.6.1
        fileId: null,
      },
      sendingOlympiad: false,
      headers: headersFormOne,
      maintainerOrgNameListDefault: [],
      helpersOrgNameListDefault: [],
    };
  },
  mounted() {
    this.getFormData()
    //this.getOlympiadsForOrg()
  },
  created() {
    this.getFile();
  },
  watch: {
    '$route.path'() {
      this.reloadData()
    }
  },
  computed: {
    ...mapGetters('spr', {getSpr: 'getSprData', getOrgOlympiads: 'getOrgOlympiadsNotSchool'}),
    ...mapGetters('auth', {getUserInfo: 'getUserInfo'}),
    orgOlympiads() {
      return this.getOrgOlympiads.filter(el => el.fl)
    },
    maintainerOrgNameList() {
      return this.maintainerOrgNameListDefault.map(el => ({
        value: el, text: el
      }))
    },
    helpersOrgNameList() {
      return this.helpersOrgNameListDefault.map(el => ({
        value: el, text: el
      }))
    },
    // filteredDialogProfilesData() {
    //   return this.profilesDialogValue.filter(el => !el.fl)
    // },
    // orgOlympiadsProfile() {
    //   return this.getSprData.sprDataOlympiadProfileSchool
    //       .filter(el => filterProfiles.call(this, el))
    //       .map(el => {
    //         return {text: el.name, value: el.id, fl: el.fl}
    //       })
    // },
    olympiadId() {
      return this.newNote.olympiad?.id ?? null;
      // return this.bufDataForProfilesDialog?.olympiad?.id ?? null;
    },
    sprInformChannels() {
      return this.getSprData.sprDataInformChannels.map(el => {
        return {text: el.name, value: el.id}
      })
    },
    spradvertisements() {
      return this.getSprData.sprDataAdvertisement.map(el => {
        return {text: el.name, value: el.id}
      })
    },
    completeWork() {
      return this.getUserInfo.sectionTwo.form1.complete;
    },
    haveOlympiads() {
      return this.getUserInfo.sectionTwo.form1.haveOlympiads;
    },
    ...mapGetters('keycloak', {getOrgName: 'getOrgName', getOrgId: 'getOrgId'}),
    getSprData() {
      return this.getSpr
    },
    orgName() {
      return this.getOrgName;
    },
    orgId() {
      return this.getOrgId;
    },
    formDataForTable() {
      return this.formData.map(data => {
        return {
          ...data,
          profilesShow: data?.profiles?.map(el => el.profile['name']).join(', ') ?? ' - ',
        }
      })
    }
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('auth', {saveUserInfo: 'saveUserInfo'}),
    ...mapActions('spr', {
      setProfile: 'setProfile',
      setOlympiad: 'setOlympiad',
      updateNewProfiles: 'updateNewProfiles'
    }),
    closeDialogProfiles() {
      this.showProfilesDialog = false;
      this.profilesDialogLoading = false;
      this.profilesDialogValid = true;
      this.errorNote.profiles = '';
      this.bufDataForProfilesDialog = null;
      this.profilesDialogValue = [];
    },
    openDialogForProfiles(row) {
      this.bufDataForProfilesDialog = row;
      /*
      * mainInfo:{
          id:6
          olympiadData:2
          profile:12
         }
* */
      this.profilesDialogValue = row?.profiles.map(el => ({
        ...el.profile,
        value: el.profile.id,
        text: el.profile.name,
        mainInfo: {id: el.id, olympiadData: el.olympiadData.id, profile: el.profile.id}
      })) ?? [];
      this.showProfilesDialog = true;
    },
    async deleteProfile(profile){
      try {
        if (!profile.id){
          const ind = this.profilesDialogValue.findIndex(el => profile.text === el.text);
          this.profilesDialogValue.splice(ind, 1);
          return;
        }
        if (!profile) return;
        await deleteDbProfile(profile.mainInfo.id, false);
        //не все работает
        const ind = this.profilesDialogValue.findIndex(el => profile.mainInfo.id === el.mainInfo.id)
        this.profilesDialogValue.splice(ind, 1);
        const  mainIndexFormData = this.formData.find(el => el.id === profile.mainInfo.olympiadData);
        mainIndexFormData.profiles = this.bufDataForProfilesDialog.profiles.filter(el => el.id !== profile.mainInfo.id);
      }catch (e) {
        console.error('deleteProfile', e)
      }
    },
    async saveProfiles() {
      try {
        if (this.profilesDialogValue.length === 0) {
          this.errorNote.profiles = 'Выберите или добавить свой профиль';
          return;
        }
        this.profilesDialogLoading = true;
        const bufIds = new Set();
        this.bufDataForProfilesDialog.profiles.forEach(el => bufIds.add(el.id))
        let requestsData = this.profilesDialogValue.map((el) => {
          let idDbProfile = el?.mainInfo?.id ?? null;
          if (idDbProfile)
            bufIds.delete(idDbProfile)
          return {
            id: idDbProfile,
            olympiadData: {id: this.bufDataForProfilesDialog.id},
            profile: {id: el.id ?? el.value, name: el.name ?? el.text}
          }
        })
        if (bufIds.size > 0){
          const deletedRequests = [...bufIds].map(async el =>{
            return await deleteDbProfile(el, false)
          });
          await Promise.allSettled(deletedRequests);
        }
        const requests = requestsData.map(async el => {
          return await addDbProfile(el, false);
        });
        await Promise.allSettled(requests).then(data => {
          const item = this.formData.find(el => el.id === this.bufDataForProfilesDialog.id)
          item.profiles = data.map(el => (el.value));
          this.updateNewProfiles(item.profiles.map(el => (el.profile)));
        });
        //eslint-disable-next-line
        this.showProfilesDialog = false;
        this.profilesDialogLoading = false;
      } catch (e) {
        this.profilesDialogLoading = false;
      }
    },
    async addNewProfile(e) {
      try {
        this.addingProfileLoading = true;
        const objToSend = {}
        e.forEach(el => {
          objToSend[el.dataField] = el.value;
        })
        if (this.fieldsForSprDialog.length === 1) {

          const elem = e[0];
          this[elem.mainOrg ? 'maintainerOrgNameListDefault' : 'helpersOrgNameListDefault'].push(elem.value);
          // const text = e[0].value
          // console.log('e', e[0], !e[0].id)
          // if (!e[0].id)
          //   this.profilesDialogValue.push({text: text})
          // else{
          //   const item = this.profilesDialogValue.find(el => el.id === e[0].id);
          //   item.name = text;
          //   item.text = text;
          // }
          // this.setProfile(objToSend);
          this.$nextTick(() => this.newNote[elem.mainOrg ? 'maintainerOrgName' : 'helpersOrgNames'].push(elem.value))
          // await this.addNotification({
          //   notificationStatus: 'success',
          //   notificationMessage: 'Ваш профиль успешно добавлен, выберите его.',
          //   timeout: 5000
          // });
        } else {
          this.newNote.experts.push(objToSend)
        }
        this.$refs['spr-dialog'].closeDialog()
        this.addingProfileLoading = false;
      } catch (e) {
        this.addingProfileLoading = false;
      }

    },
    async openCustomDialog(type = 'orgs', data, text = '') {
      if (['orgs', 'orgsHelpers'].includes(type)) {
        this.titleForSprDialog = 'Добавить свой вариант';
        this.fieldsForSprDialog = [
          {
            id: data?.id,
            mainOrg: type === 'orgs',
            dataField: 'value',
            value: data?.name ?? null,
            rules: [v => !!v || 'поле не может быть пустым'],
            text: text ? text : 'Наименование'
          }
        ]
      } else {
        this.titleForSprDialog = 'Добавить эксперта'
        this.fieldsForSprDialog = [
          {
            dataField: 'name',
            value: null,
            rules: [v => !!v || 'поле не может быть пустым'],
            text: 'ФИО '
          },
          {
            dataField: 'qualification',
            value: null,
            rules: [v => !!v || 'поле не может быть пустым'],
            text: 'Квалификация'
          },
        ]
      }

      await this.$refs["spr-dialog"].openDialog();
    },
    closeMainDialog() {
      this.openModal = false;
      this.openMiniModal = false;
      this.file.id = null;
      this.newNote = {
        actual: null,
        nameOlympic: null,
        startYear: null, //1.1
        maintainerOrg: [], //1.2
        maintainerOrgName: [], //1.2.1
        helpersOrgNames: null, //1.2.2
        isForeignPlaces: null, //1.4
        foreignPlaces: null, //1.4.1
        foreignPlacesConfirmed: null, //1.4.2
        olympiad: {id: null},
        profiles: [],
        site: "",
        paragraphNumber: null, //1.5.1
        distance: null,
        informChannels: [],
        advertisements: [],
        experts: [],
        //1.5.5 зачем если и так вводят их
        minKnowledgeRequired: null,//1.6
        minKnowledgeRequiredInfo: null,//1.6.1
        fileId: null,
      }
    },
    getFile() {
      this.file = {...this.file, name: 'Положение об олимпиаде', id: this.file?.id, fileType: 'FORM_1'};
    },
    async doChoseAboutOlympiad(flag) {
      try {
        this.sendingChoice = true;
        await this.saveUserInfo({
          ...this.getUserInfo,
          sectionTwo: {
            ...this.getUserInfo.sectionTwo,
            form1: {
              complete: !flag,
              haveOlympiads: flag,
              fileId: ''
            },
          }
        })
        this.sendingChoice = false;
      } catch (e) {
        this.sendingChoice = false;
      }
    },
    async finishEnterAndBlock() {
      try {
        this.sendingChoice = true;
        await this.saveUserInfo({
          ...this.getUserInfo,
          sectionOne: {
            ...this.getUserInfo.sectionTwo,
            form1: {
              complete: true,
              haveOlympiads: true,
              fileId: ''
            },
          }
        })
        this.sendingChoice = false;
        await this.$router.push('/section-2/form-1.1')
      } catch (e) {
        this.sendingChoice = false;
      }
    },
    changeRow(id) {
      const searchItem = this.formData.find(el => el.id === id);
      if (!searchItem) return;
      this.file.id = searchItem.fileId;
      this.newNote = {
        ...searchItem,
        // actual: true,
        // oldActual: searchItem.actual,
        nameOlympic: searchItem.actual ? null : searchItem?.olympiad.name,
        informChannels: searchItem.informChannels?.map(el => {
          return {value: el.id}
        }),
        // profiles: searchItem.profiles?.map(el => {
        //   return {value: el.id}
        // }),
        advertisements: searchItem.advertisements?.map(el => {
          return {value: el.id}
        })
      }
      this.openModal = true;
    },
    reloadData() {
      window.scrollTo(0, 0)
      this.formData = []
      this.closeEdit = null
      this.getFormData()
      this.errorNote = {
       ...defaultErrorNoteValues
      }
      this.closeMainDialog();
      this.openMiniModal = false;
      this.openMiniTitle = "";
      this.openMiniInput = "";
      this.openMiniVModal = ''
      this.openMiniInputAdditional = "";
      this.openMiniVModalAdditional = ''
      this.openMiniSelect = false
      this.userAction = null
    },
    async getFormData() {
      try {
        this.loading = true;
        this.formData = await getForm('formOne')
        this.formData.forEach(el => {
          if (el.helpersOrgNames?.length > 0)
            this['helpersOrgNameListDefault'].push(...el.helpersOrgNames);
          if (el.maintainerOrgName?.length > 0)
            this['maintainerOrgNameListDefault'].push(...el.maintainerOrgName);
        })
        this.loading = false;
      } catch (e) {
        console.error('getFormData', e)
        this.loading = false;
      }
    },


    checkForm(action) {
      let haveError = false;
      this.errorNote = {
        ...defaultErrorNoteValues
      }
      if (this.newNote.actual === null) {
        this.errorNote.actual = "Поле не должно быть пустым";
        haveError = true;
      }
      if (!this.newNote.nameOlympic && !this.newNote.olympiad.id) {
        this.errorNote.nameOlympic = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.startYear === null) {
        this.errorNote.startYear = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.startYear.length !== 10) {
        this.errorNote.startYear = "Поле должно быть формата ГГГГ-ММ-ДД";
        haveError = true;
      }
      if (this.newNote.maintainerOrg === null) {
        this.errorNote.maintainerOrg = "Поле не должно быть пустым";
        haveError = true;
      }
      if ([null, false].includes(this.newNote.maintainerOrg) && this.newNote.maintainerOrgName.length === 0) {
        this.errorNote.maintainerOrgName = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.isForeignPlaces === null) {
        this.errorNote.isForeignPlaces = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.isForeignPlaces && this.newNote.foreignPlaces === null) {
        this.errorNote.foreignPlaces = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.isForeignPlaces && this.newNote.foreignPlacesConfirmed === null) {
        this.errorNote.foreignPlacesConfirmed = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.distance === null) {
        this.errorNote.distance = "Поле не должно быть пустым";
        haveError = true;
      }
      if (!this.file.id) {
        this.errorNote.file = "Файл должен быть обязательно загружен";
        haveError = true;
      }
      if (this.newNote.paragraphNumber == null) {
        this.errorNote.paragraphNumber = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.minKnowledgeRequired == null) {
        this.errorNote.minKnowledgeRequired = "Поле не должно быть пустым";
        haveError = true;
      }
      if (haveError) return;
      else {
        this.errorNote = {
          ...defaultErrorNoteValues
        }
        this.confirmFinishEdit = true
        this.userAction = action;
        this.finishEdit();
      }
    },
    async finishEdit() {
      this.confirmFinishEdit = false
      let finalNote = {...this.newNote}
      console.log("initial", finalNote)
      finalNote.fileId = this.file.id;
      if (finalNote.informChannels.length > 0) {
        finalNote.informChannels = finalNote.informChannels.map(el => {
          return {id: el.value}
        })
      }
      if (finalNote.advertisements.length > 0) {
        finalNote.advertisements = finalNote.advertisements.map(el => {
          return {id: el.value}
        })
      }
      if (!finalNote.actual)
        finalNote.olympiad = {
          id: !finalNote.olympiad?.fl && finalNote.olympiad?.id ? finalNote.olympiad?.id : null,
          "name": finalNote.nameOlympic,
          "fl": false,
          "forSchool": false
        };
      finalNote.org = {id: this.orgId}
      try {
        if (this.userAction === 'print') {
          this.formDataForPrint = finalNote
          this.userAction = null
        } else if (this.userAction === 'send') {
          this.sendingOlympiad = true;
          if (finalNote.id) {
            finalNote.actual = finalNote.oldActual;
          }
          const res = await setForm('formOne', finalNote);
          // console.log('finalNote.olympiad?.id', finalNote.olympiad?.id, !finalNote.olympiad?.id)
          // this.updateNewProfiles(res.profiles);
          if (!finalNote.olympiad?.id) {
            // console.log(res.olympiad)
            this.setOlympiad(res.olympiad)
          }
          const index = this.formData.findIndex(el => el.id === finalNote.id);
          if (index !== -1)
            this.formData.splice(index, 1, res)
          else
            this.formData.push(res);
          this.sendingOlympiad = false;
          // this.formData.push(finalNote)
          this.closeMainDialog();
          this.userAction = null;

        }
      } catch (e) {

        console.error(e);
        this.sendingOlympiad = false;
      }

    },
    async deleteRow(id, index) {
      const accept = confirm('Удалить запись?')
      if (accept) {
        try {
          this.deleting = {value: true, id: index};
          await deleteForm('formOne', id)
          this.formData.splice(index, 1)
          this.deleting = {value: false, id: index};
        } catch (e) {
          this.deleting = {value: false, id: index};
        }
      }
    },
    downloadPrintForm() {

    },
    downloadSignedScan() {

    }
  },
};
</script>

<style lang="scss" scoped>
.container__table {
  max-width: 100%;
  overflow: auto;
}

.content__block {
  margin-bottom: 15px;
}

.text {
  padding: 5px 10px;
  border: 1px solid grey;
  border-radius: 4px;
}

.left-line {
  border-left: none;
}

.right-line {
  border-right: none;
}

.bottom-line {
  border-bottom: none;
}

.top-line {
  border-top: none;
}

.block__with-btn {
  width: 100%;
  display: grid;
  grid-template-columns: 90% 10%;
  margin-bottom: 10px;
}

h1 {
  max-width: 1200px;
}
</style>