
export default {
    orgId: '',
    haveContacts: false,
    blockedWithoutData: false,
    blocked: false,
    sectionOne:{
        form1: {complete: false, haveOlympiads: false, fileId: ''},
        form11: {complete: false, haveOlympiads: false, fileId: ''},
        form2: {complete: false, haveOlympiads: false, fileId: ''},
        form3: {complete: false, haveOlympiads: false, fileId: ''}
    },
    sectionTwo:{
        form1: {complete: false, haveOlympiads: false, fileId: ''},
        form11: {complete: false, haveOlympiads: false, fileId: ''},
        form2: {complete: false, haveOlympiads: false, fileId: ''},
        form3: {complete: false, haveOlympiads: false, fileId: ''}
    },
    lastPage: ''
}