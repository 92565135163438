function filterProfilesBufData (el) {
    if (!this.olympiadId && !this.bufDataForProfilesDialog?.nameOlympic) return false;
    if (this.bufDataForProfilesDialog?.oldActual != null && !this.bufDataForProfilesDialog?.oldActual) return true;
    if (!this.bufDataForProfilesDialog?.actual) return true;
    if (this.olympiadId)
        return el.olympiadId === this.olympiadId;
    else
        return false
}
function filterProfiles (el) {
    if (!this.olympiadId && !this.newNote?.nameOlympic) return false;
    if (this.newNote?.oldActual != null && !this.newNote?.oldActual) return true;
    if (!this.newNote?.actual) return true;
    if (this.olympiadId)
        return el.olympiadId === this.olympiadId;
    else
        return false
}
function filterProfilesFormTwo (el) {
    if (!this.olympiadId && !this.newNote?.olympiad && !this.newNote?.nameOlympic) return false;
    if (!this.currentOlympiad?.fl) return true;
    if (!this.newNote?.actual) return true;
    if (this.newNote?.olympiad)
        return el.olympiadId === this.olympiadId || !el.fl;
    else
        return false
}
export {filterProfiles, filterProfilesBufData, filterProfilesFormTwo}