<template>
  <v-app>
    <CNotificationsFromStore/>
    <TopBar :user-data="userData"/>
    <v-main style="background-color: #F5F6F8">
      <div class="pa-8 ">
        <keep-alive include="lkLayout,adminLayout">
<!--        <div class="d-flex justify-center align-center" >-->
<!--          <v-progress-circular size="40" color="#00599B"/>-->
<!--        </div>-->
<!--        <template v-else>-->
           <router-view/>
<!--        </template>-->
        </keep-alive>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import CNotificationsFromStore from "@/components/CNotificationsFromStore";
import TopBar from "@/components/TopBar";
import {mapActions, mapGetters} from 'vuex'
import axios from "axios";

export default {
  name: 'App',
  components: {
    TopBar,
    CNotificationsFromStore
  },
  props: {
    keycloak: Object,
    default: () => {
    }
  },
  data() {
    return {
      showModal: true,
      userData: {
        name: this.keycloak.idTokenParsed?.given_name,
      }
    }
  },
  created() {
    axios.interceptors.response.use(undefined, err => {
      // eslint-disable-next-line no-unused-vars
      return new Promise(/*function (resolve, reject)*/() => {
        if (process.env.NODE_ENV === 'development') {
          console.log("err.request", err.request)
          console.log("err.response", err.response)
        }
        throw err.response;
      });
    });
    this.setAuthData(this.keycloak);
    this.downloadSprData();
    if (!this.isAdminGroup){
      this.getUserInfo();
      this.setUserFiles();
      this.downloadSprDataExtended();
    }
  },
  computed: {
    ...mapGetters('auth', { getUserInfoLoading: 'getUserInfoLoading'}),
    ...mapGetters('keycloak', {isAdminGroup: 'isAdminGroup',getAuthData: 'getAuthData'})
  },
  methods: {
    ...mapActions('keycloak', {setAuthData: 'setAuthData'}),
    ...mapActions('auth', {getUserInfo: 'getUserInfo', setUserFiles: 'setUserFiles'}),
    ...mapActions('spr', {downloadSprData: 'downloadSprData', downloadSprDataExtended: 'downloadSprDataExtended'}),
  }
}
</script>

<style lang="sass">
@media (min-width: 1264px)
.main-header__title
  font-size: 32px
  line-height: 35.2px
  color: #00599B
  font-weight: 700
  margin-bottom: 22px


.container
  //max-width: 1666px !important
</style>