import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from "vuex-persist";
// import gql from 'graphql-tag';

// import graphqlClient from '../utils/graphql';
Vue.use(Vuex)


import auth from '@/store/auth'
import cNotifications from "@/store/cNotifications";
import keycloak from "@/store/keycloak";

import spr from "@/store/spr";
import sectionsAndForms from "@/store/sectionsAndForms";

import checkFilled from "@/store/checkFilled";

const vuexLocal = new VuexPersistence({
    // key: 'persist-vuex',
    storage: window.localStorage,
    reducer: (state) => ({
        auth: state.auth,
        // spr: state.spr
    }),
    // filter: mutation => mutation.type === 'TABLE_HEADER_ASYNC'
});
export default new Vuex.Store({
    modules: {
        auth: auth,
        notifications: cNotifications,
        spr: spr,
        keycloak: keycloak,
        checkFilled: checkFilled,
        sectionsAndForms: sectionsAndForms
    },
    plugins: [vuexLocal.plugin]
})