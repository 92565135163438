import {getNamesFromArray} from "@/utils/formData";

export default {
  countCells: 20,
  massive: [
    {
      dataField: 'actions',
      cols: 1,
      rows: 1,
      order: 9,
      class: 'd-flex flex-row align-start justify-center ',
      headerClass: '',
      variable: true,
      text: '',
    },
    {
      dataField: 'nameOlympic',
      cols: 1,
      rows: 1,
      text: 'Наименование олимпиады',
      class: 'd-flex flex-row align-start justify-start ',
      headerClass: 'justify-start ',
      headerStyles: `min-width: 260px; justify-content: flex-start;`,
      order: 0,
      variable: true,
    },
    {
      dataField: 'profiles',
      cols: 1,
      rows: 1,
      order: 2,
      variable: true,
      headerClass: ' ',
      class: 'px-2 align-start',
      headerStyles: `min-width: 200px`,
      text: `Наименования(-е) профилей(-я) олимпиады`,
    },
    {
      dataField: 'startYear',
      cols: 1,
      rows: 1,
      order: 3,
      headerClass: '',
      class: `align-start`,
      variable: true,
      text: `С какого года проводится олимпиада?`,
    },
    {
      dataField: 'dates',
      cols: 1,
      rows: 1,
      order: 3,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: `Период проведения олимпиады`,
      headerTooltip: true,
      headerTooltipText: () => `Укажите даты начала первого этапа и завершения последнего этапа олимпиады`,
    },
    {
      dataField: 'maintainerOrg',
      cols: 1,
      rows: 1,
      order: 3,
      class: `align-start`,
      headerClass: 'd-flex flex-row align-center',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Является ли ваша организация основным организатором олимпиады?`,
      text: `Организатор олимпиады?`,
    },
    {
      dataField: 'maintainerOrgName',
      cols: 1,
      rows: 1,
      order: 6,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: `Наименование основного организатора олимпиады `,
      template: (data)=>   data.maintainerOrgName?.join(',<br> ')
    },
    {
      dataField: 'helpersOrgNames',
      cols: 1,
      rows: 1,
      order: 7,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: `Наименование(-я) соорганизатора(-ов) олимпиады `,
      template: (data)=>  data.helpersOrgNames?.join(',<br> ')
    },
    {
      dataField: 'site',
      cols: 1,
      rows: 1,
      order: 3,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: `Официальный сайт олимпиады`,
    },
    {
      dataField: 'canForeignBe',
      cols: 1,
      rows: 1,
      order: 3,
      class: `align-start`,
      headerClass: 'd-flex flex-row align-center',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Допустимо ли участие иностранных граждан в олимпиаде?`,
      text: `Участие иностранных граждан`,
    },
    {
      dataField: 'paragraphNumber',
      cols: 1,
      rows: 1,
      order: 4,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Номер пункта в положении об олимпиаде, где указано условие о возможности участия в ней иностранных граждан/комментарий об отсутствии в положении ограничений на участие иностранных граждан в данной олимпиаде`,
      text: 'Номер пункта',
    },
    {
      dataField: 'minKnowledgeRequired',
      cols: 1,
      rows: 1,
      order: 7,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Имелись ли требования к минимальному уровню знаний, необходимых для успешного прохождения олимпиады?`,
      text: 'Требования к минимальному уровню знаний',
    },
    {
      dataField: 'minKnowledgeRequiredInfo',
      cols: 1,
      rows: 1,
      order: 8,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      text: 'Минимальные требования знаний',
      headerTooltip: true,
      headerTooltipText: () => `Какие были требования к минимальному уровню знаний, необходимых для успешного прохождения олимпиады `,
    },
    {
      dataField: 'foreignLanguages',
      cols: 1,
      rows: 1,
      order: 5,
      class: 'px-2 align-start',
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Наименования(-е) иностранных(-ого) языков(-а) олимпиадных заданий`,
      text: 'Наименования языков',
      template: (row) => {
        return getNamesFromArray(row.foreignLanguages)
      }
    },
    {
      dataField: 'foreignStates',
      cols: 1,
      rows: 1,
      order: 6,
      class: 'px-2 align-start',
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Наименования(-е) иностранных(-ого) государств(-а), граждане(-ин)
          которых(-ого) приняли(-) участие в олимпиаде`,
      text: 'Наименования государств',
      template: (row) => {
        return getNamesFromArray(row.foreignStates)
      }
    },
    {
      dataField: 'distance',
      cols: 1,
      rows: 1,
      order: 4,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Есть ли возможность дистанционного участия иностранных граждан в олимпиаде?`,
      text: 'Дистанционное участие',
    },
    {
      dataField: 'informChannels',
      cols: 1,
      rows: 1,
      order: 5,
      class: 'px-2 align-start',
      headerClass: 'd-flex flex-row justify-center px-2',
      headerStyles: `min-width: 200px;`,
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Каналы(-) информирования иностранных граждан об олимпиаде`,
      text: 'Каналы информирования',
    },
    {
      dataField: 'advertisements',
      cols: 1,
      rows: 1,
      order: 6,
      class: `align-start`,
      headerClass: '',
      variable: true,
      headerStyles: `min-width: 200px;`,
      text: 'Мероприятия по продвижению олимпиады',
      headerTooltip: true,
      headerTooltipText: () => `Запланированные мероприятия по продвижению олимпиады среди иностранных граждан`,
    },
    {
      dataField: 'experts',
      cols: 1,
      rows: 1,
      order: 7,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      headerStyles: `min-width: 200px;`,
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `ФИО и квалификация международных(-ого) экспертов(-а) состоящих(-его) в организационном
          комитете/методической комиссии/жюри олимпиады/методической комиссии/жюри олимпиады`,
      text: 'ФИО и квалификация экспертов',
    },
    {
      dataField: 'fileId',
      cols: 1,
      rows: 1,
      order: 8,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      text: 'Положение об олимпиаде',
    },
  ]
}
