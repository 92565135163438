<template>
  <div>
    <div class="d-flex fade-row justify-space-between align-center">
      <h1 class="main-header__title">Личный кабинет</h1>
      <div class="d-flex flex-row align-center">
        <a href="https://files.ined.ru/files/olipmiad_instructions.pdf" class="main-header__subtitle mr-2 text-decoration-underline" target="_blank">Инструкция</a>
        <v-menu
            :close-on-content-click="false"
            :nudge-bottom="20"
            min-width="420px"
            transition="scale-transition"
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <span class="main-header__subtitle main-color__important text-decoration-underline" v-on="on" v-bind="attrs">Обратная связь</span>
          </template>
          <v-card class="">
            <v-card-title>
              Ответственный за сбор данных от ФГБУ «Интеробразование»
            </v-card-title>
            <v-card-text class="d-flex flex-column">
              <div>ФИО: Морозова Анна Антоновна</div>
              <div>Контактный номер телефона: 8 499 246 66 93</div>
              <div>Контактный e-mail: aa.morozova@ined.ru</div>
              <div>Режим работы: пн-пт с 10:00 до 13:00 и с 14:00 до 17:00 (мск)</div>
            </v-card-text>
          </v-card>
        </v-menu>

      </div>
    </div>

    <div class="mb-10 mt-10 text-center" v-if="!filledContacts">
      <v-alert
          color="red"
          elevation="4"
          type="error"

      ><span class="font-size-large">Доступ к формам появится только после заполнения информации о
        непосредственном ответственном исполнителе</span></v-alert>
    </div>
    <WrapperPreviewCategory v-for="(item,index) in contacts" :key="index"
                            customClass="section-preview_lk">
      <WrapperCollapse
          :initialOpen="true"
          :title="'Непосредственный ответственный исполнитель ' + (index + 1)"
      >
        <ContainerPersonContact
            :blocked="getBlock"
            :can-be-deleted="index!==0"
            :contact="item"
            :loading-contacts="loadingContacts"
            :saving-data="savingContact"
            @delete-contact="deleteContact($event, index)"
            @change-contact="editContact($event, index)"
            @add-contact="addContact($event, index)"
        />
      </WrapperCollapse>
    </WrapperPreviewCategory>

    <div v-if="filledContacts">
      <header-main
          subtitle=""
          title="Сбор данных об олимпиадах школьников и олимпиадах для лиц, поступающих в магистратуру, в которых имеют возможность принимать участие иностранные граждане"
      />
<!--      <Alert>
        <template #icon>
          <v-icon color="#D4990A" size="40">mdi-alert</v-icon>
        </template>
        <p>Формы сбора данных заполняются только основным организатором олимпиады</p>
      </Alert>
      <Alert>
        <template #icon>
          <v-icon color="#D4990A" size="40">mdi-alert</v-icon>
        </template>
        <template #default>
          <div v-if="!getBlock" class="d-flex flex-row justify-space-between align-center">
            <p>Заблокировать ввод данных, если нет данных</p>
            <v-btn
                v-if="!getBlock"
                :disabled="getBlock"
                :loading="loadingBlockEnterWithoutData"
                class="orange__v-btn "
                color="#EB722E"
                @click="clickBlockEnterWithoutData()"
            >&lt;!&ndash;class="button_border" :class="getBlock ? 'blocked-btn': 'blocked-btn'"&ndash;&gt;
              <span style="color:#fff;">Заблокировать</span>
            </v-btn>
          </div>
          <div v-else class="d-flex flex-row justify-space-between align-center">
            <p>Печать</p>
            <div class="d-flex flex-column">
              <v-btn
                  v-if="getBlock"
                  :disabled="!getBlock"
                  :loading="downloadingFileForPrint"
                  class="orange__v-btn"
                  @click="downloadPrintDocument"
              >&lt;!&ndash;class="button_border" :class="getBlock ? 'blocked-btn': 'blocked-btn'"&ndash;&gt;
                <span>Печатные формы с введенными данными об олимпиадах</span>
              </v-btn>
              <v-btn
                  v-if="!getBlock"
                  class="orange__v-btn mt-1 pa-3"
                  color="#EB722E"
                  href="https://files.ined.ru/adminpanel/cms_article_editor_1_66666_c0e9b8294dc95fd9ed599872b150468d.docx"
              >
                Уведомление о том, что олимпиады не проводились
              </v-btn>
              <v-btn
                  v-if="!getBlock"
                  class="orange__v-btn mt-1 pa-3"
                  color="#EB722E"
                  href="https://files.ined.ru/adminpanel/cms_article_editor_1_66666_1f71bfad76d9f17e000a4483b6ac0a30.docx"
              >
                Уведомление о том, что олимпиады с участием иностранных граждан не проводились
              </v-btn>
            </div>
          </div>
        </template>
      </Alert>-->
      <Alert alert-type="warning">
        <template #icon>
          <v-icon color="#D4990A" size="40">mdi-alert</v-icon>
        </template>
        <template #default>
          <div class="d-flex flex-row justify-space-between align-center">
            <p v-if="getBlock">Формы для подписи и печати</p>
            <p v-else>Формы для самопроверки</p>
            <v-btn
                :loading="downloadingFileForPrint"
                class="success__v-btn"
                @click="downloadPrintDocument"
                color="#29A128"
            ><!--class="button_border" :class="getBlock ? 'blocked-btn': 'blocked-btn'"-->
              <span style="color:#fff;">Скачать</span>
            </v-btn>
          </div>
        </template>
      </Alert>
      <Alert v-if="!getBlock" alert-type="success">
        <template #icon>
          <v-icon color="#29A128" size="40">mdi-check-bold</v-icon>
        </template>
        <template #default>
          <div class="d-flex flex-row justify-space-between align-center">
            <p>{{ getBlock ? 'Ввод данных заблокирован' : 'Заблокировать ввод данных' }}</p>
            <v-btn
                v-if="!getBlock"
                :disabled="getBlock"
                :loading="loadingBlockEnterData"
                class="success__v-btn"
                color="#29A128"
                @click="clickBlockEnterData()"
            ><!--class="button_border" :class="getBlock ? 'blocked-btn': 'blocked-btn'"-->
              <span style="color:#fff;">{{
                  getBlock ? 'Заблокировано' : 'Заблокировать'
                }}</span>
            </v-btn>
          </div>
        </template>
      </Alert>
      <scanLoad v-else></scanLoad>
      <v-expansion-panels  class="mb-5" v-for="section in sectionsData" :key="section.id">
        <v-expansion-panel >
          <v-expansion-panel-header>
            <h1 class="main-header__title">{{ section.title }}</h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="cards">
              <form-card :form="form" :section-path="section.path" v-for="form in section.forms"
                         :key="form.id"/>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </div>
    <confirm-dialog
        ref="agree-dialog"
        :text="finallyTextForConfirmDialog" >
      <template #actions="{agree, close}">
        <v-btn
            class='blue__v-btn'
            @click="agree"
            width="80px"
        >
          Ок
        </v-btn>
        <v-btn
            class='white__v-btn ml-2'
            @click="close"
            width="80px"
        >
          Отменить
        </v-btn>
      </template>
    </confirm-dialog>
  </div>
</template>

<script>
import request from "@/services/request";
import {mapActions, mapGetters} from 'vuex';
import {saveBlobFile} from "@/utils/saveFile";
import HeaderMain from "@/components/headers/HeaderMain";
import WrapperPreviewCategory from '@/components/wrappers/WrapperPreviewCategory';
import WrapperCollapse from '@/components/wrappers/WrapperCollapse';
import ContainerPersonContact from "@/components/contacts/ContainerPersonContact";
import Alert from "@/components/UI/Alert";
import axios from "axios";
import scanLoad from "@/components/scanLoad";
import FormCard from "@/View/FormCard";
import ConfirmDialog from "@/components/Dialogs/confirmDialog";
import {getBlockInfo} from "@/Api/userInfo";

export default {
  name: "LK",
  components: {
    ConfirmDialog,
    Alert,
    HeaderMain,
    WrapperPreviewCategory,
    WrapperCollapse,
    ContainerPersonContact,
    scanLoad,
    FormCard
  },
  data() {
    return {
      orgData: {},
      contacts: [
        {
          fio: '',
          email: '',
          position: '',
          workPhoneNumber: '',
          personalPhoneNumber: '',
        },
      ],
      defaultTextConfirmDialog: 'Внимание! После подтверждения вы не сможете менять данные.<br><br> ',
      textConfirmDialog: '',
      savingContact: false,
      loadingContacts: false,
      errorSaving: null,
      loadingBlockEnterData: false,
      loadingBlockEnterWithoutData: false,
      downloadingFileForPrint: false,
    }
  },
  created() {
    if (this.orgId)
      this.getContacts()
    if (this.orgId) {
      this.getOrgData()
    }
  },
  computed: {
    ...mapGetters('auth', {getUserInfo: 'getUserInfo', isUserBlock: 'isUserBlock'}),
    ...mapGetters('keycloak', {getOrgId: 'getOrgId'}),
    getBlock() {
      return this.isUserBlock
    },
    finallyTextForConfirmDialog(){
      return this.defaultTextConfirmDialog + ' ' + this.textConfirmDialog;
    },
    orgId() {
      return this.getOrgId
    },
    sectionsData() {
      return this.$store.getters["sectionsAndForms/getSectionsData"]
    },
    filledContacts() {
      return this.contacts[0]?.fio
          && this.contacts[0]?.email
          && this.contacts[0]?.position
          && this.contacts[0]?.workPhoneNumber
          && this.contacts[0]?.personalPhoneNumber || this.getUserInfo.haveContacts
    }
  },
  watch: {
    getAuthUserDataLoading(newValue) {
      // console.log("token", newValue, oldValue)
      if (newValue === false) {
        // console.log("watcher start")
        this.getContacts()
      }
    }
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    ...mapActions('auth', {saveUserInfo: 'saveUserInfo'}),
    downloadFileForPrint(){

    },
    getQuery(item) {
      return {
        path: item.href,
        query: {orgID: this.$route.query.orgID}
      }
    },
    async getOrgData() {
      const res = await request({
        endpoint: `api/spr/org/${this.orgId}`,
        method: 'get',
      })
      this.orgData = res.data
    },
    async downloadPrintDocument() {
      this.downloadingFileForPrint = true;
      const response = await axios.get( `/api/fullreport/${this.orgId}?isTmp=${!this.getBlock}`, {
        responseType: "blob",
        headers: this.$scripts.Utils.authHeader(),
      });
      this.downloadingFileForPrint = false;
      saveBlobFile(response.data);
    },
    async clickBlockEnterData() {
      this.loadingBlockEnterData = true;
      let agree = false;
      //После потверждения вы не сможете менять данные.
      await getBlockInfo(this.orgId).then(data => {
        if (!data.canBlock)
          this.textConfirmDialog = data.message+'<br><br> Уверены ли вы в том, что хотите заблокировать ввод данных?';
      })
      await this.$refs["agree-dialog"].openDialog().then(data => agree = data);
      if (agree) {
          try {
            this.loadingBlockEnterData = true;
            await this.saveUserInfo({
              ...this.getUserInfo,
              blocked: true
            })
            this.loadingBlockEnterData = false;
          } catch (e) {
            this.loadingBlockEnterData = false;
          }
      }
      this.loadingBlockEnterData = false;
    },
    async getContacts() {
      this.loadingContacts = true;
      const res = await request({
        endpoint: `/api/person/direct/getAll?orgID=${this.orgId}`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data.length > 0) {
          this.contacts = res.data.map(el => {
            for (const key of Object.keys(el)) {
              el[key] = el[key] === null ? '' : el[key]
            }
            return el
          });
          this.contacts = res.data
          await this.fetchCheckFilled({contacts: this.contacts})
        }
      } else {
        if (res.status !== 404)
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка получения данных ответственных исполнителей',
            timeout: 5000
          })
      }
      this.loadingContacts = false;
    },
    async deleteContact(obj, index) {
      // console.log("deleteC", obj,index)
      if (obj?.personID) {
        const res = await request({
          endpoint: `/api/person/direct/delete?personID=${obj.personID}`,
          method: 'get',
        })
        // console.log("UserInfo res ",res)
        if (res.status === 200) {
          if (res.data.length > 0)
            this.contacts.splice(index, 1)
        } else {
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка сохранения данных ответственного исполнителя ' + (index + 1),
            timeout: 5000
          })
        }
      } else {
        this.contacts.splice(index, 1)
      }

    },
    async addContact(event, index) {
      this.contacts.splice(index + 1, 0, {
        fio: '',
        email: '',
        position: '',
        workPhoneNumber: '',
        personalPhoneNumber: '',
        orgID: this.orgId
      })
    },
    async editContact(obj, index) {
      const res = await request({
        endpoint: `/api/person/direct/${obj?.personID ? 'update' : 'add'}`,
        method: 'post',
        body: {
          ...obj,
          orgID: this.orgId,
          personID: obj?.personID,
        },
      })
      if (res.status === 200) {
        this.contacts.splice(index, 1, res.data);
        await this.saveUserInfo({...this.getUserInfo, haveContacts: true});
        setTimeout(() => {
          this.savingContact = false;
        }, 2000);

      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения данных ответственного исполнителя ' + (index + 1),
          timeout: 5000
        })
      }
    },
  }
}
</script>

<style lang="sass">
.section-preview_lk
  background: #fff
  border-radius: 6px
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06)
  //border: 1px solid #cdd1d4
  margin-bottom: 20px


  .lk-list_flex_start
    justify-content: flex-start

.v-expansion-panel-content__wrap
  padding-bottom: 60px

.main-header__title
  font-size: 32px
  line-height: 35.2px
  color: #00599B
  font-family: Roboto, sans-serif
  font-weight: 700
  max-width: 1200px
  margin-bottom: 22px

.cards
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 20px
  width: 100%
  margin: auto


</style>
