<template>
  <wrapper-preview-category>
    <h1 class="main-header__title">{{ pageTitle }}</h1>
    <div>
      <div class="mx-2 mb-2 d-flex justify-end align-center">
<!--        <v-btn class="blue__v-btn" :disabled="getBlock" @click="openModal = true">Добавить запись</v-btn>-->
        <v-btn
            class="blue__v-btn"
            :loading="downloadingFileForPrint"
            @click="downloadPrintDocument(`/api/excelReport?num=1.1&orgId=${orgId}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')"
        >
            Скачать заполненную форму в формате Excel для самопроверки
        </v-btn>
      </div>
      <GridTable
          :headers="headers.massive"
          :headers-for-rows="headersForRows"
          :num-cols="headers.countCells"
          :loading-data="loading"
          :data-rows="formDataForTable"
          class="ma-3"
      >
        <template #nameOlympic="{row, cell}">
          {{cell.template(row)}}
          <v-btn
              class="blue__v-btn mt-1"
              :disabled="getBlock"
              @click="openDialogForProfiles(row)"
          >
          Профили
          </v-btn>
        </template>
<!--        <template #profiles="{row}">-->
<!--          <v-btn-->
<!--              color="primary"-->
<!--              icon-->
<!--              style="position: absolute; top: 0; right: 0px;"-->
<!--              @click="openDialogForProfiles(row)">-->
<!--            <v-icon size="20" color="primary">mdi-pencil</v-icon>-->
<!--          </v-btn>-->
<!--          <span>{{ profilesShow(row) }}</span>-->
<!--        </template>-->
        <template #actions="{row, index}">
          <v-btn :disabled="getBlock || row.profiles.length === 0" :loading="sendingOlympiad" icon @click="changeRow(row, index)">
            <v-icon class="flex align-center"  color="primary">
              mdi-pencil
            </v-icon>
          </v-btn>
<!--          <v-btn :loading="deleting.id === row.id && deleting.value" :disabled="getBlock" icon-->
<!--                 @click="deleteRow(row.id, index)">-->
<!--            <v-icon class="flex align-center" color="primary">-->
<!--              mdi-delete-->
<!--            </v-icon>-->
<!--          </v-btn>-->
        </template>
      </GridTable>
      <div class="d-flex justify-end">
        <v-btn class="white__v-btn mb-2 mr-2"
               @click="goNextStage">
          Перейти к заполнению следующей формы
        </v-btn>
      </div>
    </div>
    <v-dialog
        v-model="openModal"
        max-width="1000px"
     persistent
        @keydown.esc="closeMainDialog()"
    >
      <v-card>
        <v-card-title class="modal-header">
          <div>
            <div class="addNote title mb-5">Добавление записи</div>
            <v-icon class="close" @click="closeMainDialog">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text>
<!--          <div class="content__block">-->
<!--            Наименование олимпиады и её профиля-->
<!--            <v-autocomplete-->
<!--                v-model="newNote.profile"-->
<!--                :error-messages="errorNote.profile"-->
<!--                :items="getOlympiads"-->
<!--                dense-->
<!--                outlined-->
<!--                no-data-text="нет результатов по вашемы поиску"-->
<!--            >-->
<!--            </v-autocomplete>-->
<!--          </div>-->
          <div class="content__block">
            Период проведения олимпиады по указанному профилю в 2021/2022 учебном году
              (Укажите даты начала первого этапа и завершения последнего этапа олимпиады)
            <div class="d-flex flex-row align-start">
              <v-text-field v-model="newNote.dates[0]"
                            :error-messages="errorNote.dates"
                            dense
                            outlined
                            label="От: Год-месяц-день"
                            v-mask="'####-##-##'"
              />
              <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon color="primary" size="26">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                    v-model="newNote.dates[0]"
                    locale="ru"
                    no-title
                >
                </v-date-picker>
              </v-menu>
              <v-text-field v-model="newNote.dates[1]"
                            :error-messages="errorNote.dates"
                            dense
                            outlined
                            label="До: Год-месяц-день"
                            v-mask="'####-##-##'"
              />
              <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon color="primary" size="26">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                    v-model="newNote.dates[1]"
                    locale="ru"
                    no-title
                    scrollable
                >
                </v-date-picker>
              </v-menu>
            </div>
<!--            <v-menu-->
<!--                ref="menu"-->
<!--                :close-on-content-click="false"-->
<!--                transition="scale-transition"-->
<!--                offset-y-->
<!--                min-width="auto"-->
<!--            >-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <v-combobox-->
<!--                    v-model="newNote.dates"-->
<!--                    chips-->
<!--                    dense-->
<!--                    multiple-->
<!--                    :error-messages="errorNote.dates"-->
<!--                    :rules="[-->
<!--                        () => newNote.dates.length <= 2 || 'Можно выбрать только две даты'-->
<!--                        ]"-->
<!--                    label=""-->
<!--                    small-chips-->
<!--                    readonly-->
<!--                    v-bind="attrs"-->
<!--                    v-on="on"-->
<!--                ></v-combobox>-->
<!--              </template>-->
<!--              <v-date-picker-->
<!--                  v-model="newNote.dates"-->
<!--                  locale="ru"-->
<!--                  no-title-->
<!--                  multiple-->
<!--                  scrollable-->
<!--              >-->
<!--              </v-date-picker>-->
<!--            </v-menu>-->
          </div>
          <div class="content__block">
            Проводилась ли олимпиада по указанному профилю проводилась в 2020/21
            учебном году?
            <v-radio-group
                v-model="newNote.actualProfile"

                :error-messages="errorNote.actualProfile"
                row
            >
              <v-radio :value=true label="Да">
              </v-radio>
              <v-radio :value=false label="Нет"></v-radio>
            </v-radio-group>
          </div>
          <div class="content__block">
            <div>
              Наименования(-е) иностранных(-ого) государств(-а), на территориях (-ии)
              которых(-ого) очно проводилось не менее одного этапа олимпиады по
              указанному профилю
            </div>
            <div>
              <v-autocomplete
                  v-model="newNote.foreignStates"

                  :items="sprStateTerritory"
                  dense
                  multiple
                  outlined
                  return-object
                  no-data-text="нет результатов по вашемы поиску"
              >
              </v-autocomplete>
            </div>
          </div>
          <div class="content__block">
            <div class="">
              Наименования(-е) иностранных(-ого) языков(-а) олимпиадных заданий

            </div>
            <div>
              <v-autocomplete
                  v-model="newNote.foreignLanguages"

                  :items="sprLanguages"
                  dense
                  multiple
                  outlined
                  return-object
                  no-data-text="нет результатов по вашемы поиску"
              >
              </v-autocomplete>
            </div>
          </div>
          <div class="content__block">
            <div class="">
              Наименования(-е) иностранных(-ого) государств(-а), граждане(-ин)
              которых(-ого) приняли(-) участие в олимпиаде
            </div>
            <div>
              <v-autocomplete
                  v-model="newNote.foreignCitizens"
                  :error-messages="errorNote.foreignCitizens"
                  :items="sprStateTerritory"
                  dense
                  multiple
                  outlined
                  return-object
                  no-data-text="нет результатов по вашемы поиску"
              >
              </v-autocomplete>
            </div>
          </div>
          <div class="content__block">
            Общее количество участников олимпиады по профилю
            <v-text-field v-model="newNote.numberParticipants"

                          :error-messages="errorNote.numberParticipants"
                          dense
                          outlined
                          type="number"/>
          </div>
          <div class="content__block">
            Из них иностранных граждан
            <v-text-field v-model="newNote.foreignParticipants"
                          :error-messages="errorNote.foreignParticipants" dense
                          outlined
                          type="number"/>
          </div>
          <div class="content__block">
            Общее количество победителей и (или) призеров заключительного
            (итогового) этапа олимпиады по профилю
            <v-text-field v-model="newNote.numberWinners"
                          :error-messages="errorNote.numberWinners" dense
                          outlined type="number"/>
          </div>
          <div class="content__block">
            Из них иностранных граждан
            <v-text-field v-model="newNote.foreignWinners"
                          :error-messages="errorNote.foreignWinners" dense
                          outlined type="number"/>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="d-flex justify-space-between mt-4">
            <v-btn
                class="blue__v-btn ml-3"
                :disabled="sendingOlympiad"
                :loading="sendingOlympiad"
                @click="checkForm('send')"
            >
              Сохранить
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="showProfilesDialog"
        @click:outside="closeDialogProfiles()"
        @keydown.esc="closeDialogProfiles()"
        max-width="700px"
    >
      <v-card>
        <v-card-title>Добавить/Изменить профиль</v-card-title>
        <v-card-text>
          <v-form v-model="profilesDialogValid" lazy-validation>
            <div class="content__block mb-0">
              <div class="block__with-btn">
                Наименования(-е) профилей (-я) олимпиады
              </div>
              <div class="d-flex flex-row">
                <v-autocomplete
                    v-model="profilesDialogValue"
                    :disabled="!bufDataForProfilesDialog || (bufDataForProfilesDialog.nameOlympic === null || !bufDataForProfilesDialog.nameOlympic) && !bufDataForProfilesDialog.olympiad.id"
                    :error-messages="errorNote.profiles"
                    :items="orgOlympiadsProfile"
                    dense
                    multiple
                    outlined
                    no-data-text="По вашему запросу ничего нет"
                    return-object
                >
                </v-autocomplete>
                <v-tooltip
                    top
                    v-if="showAdditionalBtnForProfilesDialog"
                    color="primary"
                >
                  <template #activator="{on, attrs}">
                    <v-btn
                        :disabled="!bufDataForProfilesDialog || (bufDataForProfilesDialog.nameOlympic === null || !bufDataForProfilesDialog.nameOlympic) && !bufDataForProfilesDialog.olympiad.id"
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="openCustomDialog('profile')">
                      <v-icon size="35" color="primary">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <p class="white&#45;&#45;text mb-0 pb-0">Добавить свой вариант</p>
                </v-tooltip>
              </div>
              <div class="d-flex flex-row justify-space-between align-center"
                   v-for="(profile, i) in filteredDialogProfilesData" :key="i">
                <span>              {{ profile.text ? profile.text : profile.name }}</span>
                <div>
                  <v-btn :disabled="profile.fl" icon>
                    <v-icon size="22" color="primary" @click="openCustomDialog('profile', profile)">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon size="22" color="error" @click="deleteProfile(profile)">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-form>

        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="saveProfiles" :loading="profilesDialogLoading" class="blue__v-btn">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-modal
        v-if="confirmFinishEdit"
        card-text="Вы ввели всю имеющуюся информацию и готовы завершить заполнение данной формы?"
        @acceptAction="finishEdit"
        @rejectAction="confirmFinishEdit = false"
    />
    <add-spr-info
        ref="spr-dialog"
        :title="titleForSprDialog"
        :fields="fieldsForSprDialog"
        :loading="addingProfileLoading"
        @send-resolve="addNewProfile($event)"
    >
    </add-spr-info>
  </wrapper-preview-category>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/UI/ConfirmModal";
import {deleteForm, getForm, setForm} from "@/Api/sectionOne";
import {addDbProfile, deleteDbProfile, getDbProfiles} from '@/Api/spr'
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import GridTable from "@/components/GridTable/GridTable";
import headersFormOneOne from "@/helpers/sectionOne/headersFormOneOne";
import {isBlock} from "@/mixins/isBlock";
import {headerFormsName} from "@/mixins/headerName";
import AddSprInfo from "@/components/Dialogs/addSprInfo";
import {filterProfilesBufData} from "@/helpers/sectionOne/filterFunctions";
import {setFileFunctions} from "@/mixins/fileFucntions";
//import request from "@/services/request";
// import { getNamesFromArray, getStringFromBoolean} from '@/utils/formData'
const defaultErrorNoteValues = {
  profile: "",
  actualProfile: null,
  foreignCitizens: '',
  numberParticipants: "",
  foreignParticipants: "",
  numberWinners: "",
  foreignWinners: "",
  dates: "",
}
// import {timeMask } from "@/helpers/maskForDate";
export default {
  components: {GridTable, AddSprInfo, WrapperPreviewCategory, ConfirmModal},
  mixins: [isBlock, headerFormsName, setFileFunctions],
  name: 'FORM_11',
  data() {
    return {
      formData: [],
      // mask: '',
      // timeMask,
      profilesPrevStage: [],
      titleForSprDialog: 'Добавить профиль олимпиады',
      fieldsForSprDialog: [],
      addingProfileLoading: false,
      bufDataForProfilesDialog: null,
      showProfilesDialog: false,
      profilesDialogLoading: false,
      profilesDialogValid: true,
      profilesDialogValue: [],
      openModal: false,
      openMiniModal: false,
      openMiniTitle: "",
      openMiniVModal: "",
      openMiniInput: "",
      userAction: "",
      openMiniSprType: null,
      confirmFinishEdit: false,
      deleting: {value: false, id: null},
      errorNote: {
      ...defaultErrorNoteValues
      },
      headers: headersFormOneOne,
      loading: false,
      sendingOlympiad: false,
      newNote: {
        profile: null,
        actualProfile: null,
        foreignStates: [],
        dates: [
            '', ''
        ],
        foreignLanguages: [],
        foreignCitizens: [],
        numberParticipants: '',
        foreignParticipants: '',
        numberWinners: '',
        foreignWinners: '',
      },
    };
  },
  created() {
    this.getFormData()
  },
  watch: {
    '$route.path'() {
      this.reloadData()
    },
    // firstDate(v){
    //   this.mask = this.timeMask(v)
    // }
  },
  computed: {
    ...mapGetters('spr', {getSpr: 'getSprData'}),
    ...mapGetters('auth', {getUserInfo: 'getUserInfo'}),
    ...mapGetters('keycloak', {getOrgName: 'getOrgName', getOrgId: 'getOrgId'}),
    // firstDate(){
    //   return this.newNote.dates[0]
    // },
    getOlympiads() {
      return this.profilesPrevStage.map(el => {
        return {
          value: el.id, text: el?.olympiadData?.olympiad?.name + ' - ' + el?.profile?.name
        }
      })
    },
    filteredDialogProfilesData() {
      return this.profilesDialogValue
    },
    showAdditionalBtnForProfilesDialog(){
      return !!this.bufDataForProfilesDialog && !this.bufDataForProfilesDialog.actual
    },
    orgOlympiadsProfile() {
      return this.getSprData.sprDataOlympiadProfileSchool
          .filter(el => filterProfilesBufData.call(this, el))
          .map(el => {
            return {text: el.name, value: el.id, fl: el.fl}
          })
    },
    olympiadId() {
      // return this.newNote.olympiad?.id ?? null;
      return this.bufDataForProfilesDialog?.olympiad?.id ?? null;
    },
    sprStateTerritory() {
      return this.getSprData.sprDataOksm.map(el => {
        return {text: el.name, value: el.id}
      })
    },
    sprLanguages() {
      return this.getSprData.sprDataLanguages.map(el => {
        return {text: el.name, value: el.id}
      })
    },
    sprInformChannels() {
      return this.getSprData.sprDataInformChannels.map(el => {
        return {text: el.name, value: el.id}
      })
    },
    sprAdvertisement() {
      return this.getSprData.sprDataAdvertisement.map(el => {
        return {text: el.name, value: el.id}
      })
    },
    completeWork() {
      return this.getUserInfo.sectionOne.form11.complete;
    },
    haveOlympiads() {
      return this.getUserInfo.sectionOne.form11.haveOlympiads;
    },
    getSprData() {
      return this.getSpr
    },
    orgName() {
      return this.getOrgName;
    },
    orgId() {
      return this.getOrgId;
    },
    headersForRows() {
      return this.headers.massive.map(el => {
        return {
          ...el,
          styles: (row) => {
            if (['profiles', 'nameOlympic'].includes(el.dataField))
              return !row.first ? 'display: none!important;' : 'position: relative;'/*text-align: center; */
            else
              return ''
          },
          rowsDataCalc: (row) => {
            if (['profiles', 'nameOlympic'].includes(el.dataField))
              return row.first ? row.profiles?.length : 1;
            else
              return 1
            // else
            //   return row.first ? row.profiles?.length : 1;
          },
        }
      })
    },
    formDataForTable() {
      return this.formData.map(data => {
        return {
          ...data,
        }
      })
    }
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('auth', {saveUserInfo: 'saveUserInfo'}),
    ...mapActions('spr', {
      updateNewProfiles: 'updateNewProfiles'
    }),
    // changeMask(v){
    //
    //   this.mask = this.timeMask(v);
    //   console.log(333, v, this.mask)
    // },
    profilesShow(r) {
      return r?.profiles?.map(el => el.profile['name']).join(', ') ?? ' - ';
    },
    closeMainDialog() {
      this.openModal = false;
      this.clearDialogData()
    },
    clearDialogData() {
      this.newNote = {
        nameOlympic: '',
        olympiad: null,
        profile: null,
        actualProfile: null,
        foreignStates: [],
        dates: [
            '', ''
        ],
        foreignLanguages: [],
        foreignCitizens: [],
        numberParticipants: null,
        foreignParticipants: null,
        numberWinners: null,
        foreignWinners: null,
      }
    },
    closeDialogProfiles() {
      this.showProfilesDialog = false;
      this.profilesDialogLoading = false;
      this.profilesDialogValid = true;
      this.errorNote.profiles = '';
      this.bufDataForProfilesDialog = null;
      this.profilesDialogValue = [];
    },
    openDialogForProfiles(row) {
      this.bufDataForProfilesDialog = row;
      /*
      * mainInfo:{
          id:6
          olympiadData:2
          profile:12
         }
* */
      this.profilesDialogValue = row?.profiles.map(el => ({
        ...el.profile,
        value: el.profile?.id,
        text: el.profile?.name,
        mainInfo: {id: el.id, olympiadData: row.olympiadDataId, profile: el.profile?.id}
      })) ?? [];
      this.$nextTick(() => this.showProfilesDialog = true)
    },
    async deleteProfile(profile) {
      // server 500 error but delete anyway !! need to fix
      // если по профилю что то есть он его не удалит !"!!!!!
      //eslint-disable-next-line
      try {
        if (!profile.id) {
          const ind = this.profilesDialogValue.findIndex(el => profile.text === el.text);
          this.profilesDialogValue.splice(ind, 1);
          return;
        }
        if (!profile) return;
        await deleteDbProfile(profile.mainInfo.id);
        //не все работает
        const ind = this.profilesDialogValue.findIndex(el => profile.mainInfo.id === el.mainInfo.id)
        this.profilesDialogValue.splice(ind, 1);
        const mainIndexFormData = this.formData.find(el => el.id === profile.mainInfo.olympiadData);
        if (mainIndexFormData)
          mainIndexFormData.profiles = this.bufDataForProfilesDialog?.profiles.filter(el => el.id !== profile.mainInfo.id);
        await this.getFormData()
      } catch (e) {
        console.error('deleteProfile', e)
      }
    },
    async saveProfiles() {
      try {
        if (this.profilesDialogValue.length === 0) {
          this.errorNote.profiles = 'Выберите или добавить свой профиль';
          return;
        }
        this.profilesDialogLoading = true;
        const bufIds = new Set();
        this.bufDataForProfilesDialog.profiles.forEach(el => bufIds.add(el.id))
        let requestsData = this.profilesDialogValue.map((el) => {
          let idDbProfile = el?.mainInfo?.id ?? null;
          if (idDbProfile)
            bufIds.delete(idDbProfile)
          return {
            id: idDbProfile,
            olympiadData: {id: this.bufDataForProfilesDialog.olympiadDataId},
            profile: {id: el.id ?? el.value, name: el.name ?? el.text}
          }
        })
        // if (bufIds.size > 0) {
        //   const deletedRequests = [...bufIds].map(async el => {
        //     return await deleteDbProfile(el)
        //   });
        //   await Promise.allSettled(deletedRequests);
        // }
        const requests = requestsData.map(async el => {
          return await addDbProfile(el, true);
        });
        await Promise.allSettled(requests).then(data => {
          const item = this.formData.find(el => el.id === this.bufDataForProfilesDialog.id)
          item.profiles = data.map(el => (el.value));
          this.updateNewProfiles(item.profiles.map(el => (el.profile)));
        });
        await this.getFormData();
        //eslint-disable-next-line
        this.showProfilesDialog = false;
        this.profilesDialogLoading = false;
      } catch (e) {
        this.profilesDialogLoading = false;
      }
    },
    async addNewProfile(e) {
      try {
        this.addingProfileLoading = true;
        const objToSend = {}
        e.forEach(el => {
          objToSend[el.dataField] = el.value;
        })
        if (this.fieldsForSprDialog.length === 1) {
          objToSend.forSchool = true;
          const text = e[0].value
          console.log('e', e[0], !e[0].id)
          if (!e[0].id)
            this.profilesDialogValue.push({text: text})
          else {
            const item = this.profilesDialogValue.find(el => el.id === e[0].id);
            item.name = text;
            item.text = text;
          }
        } else {
          this.newNote.experts.push(objToSend)
        }
        this.$refs['spr-dialog'].closeDialog()
        this.addingProfileLoading = false;
      } catch (e) {
        this.addingProfileLoading = false;
      }

    },
    async openCustomDialog(type = 'profile', data) {
      if (type === 'profile') {
        this.titleForSprDialog = 'Добавить профиль олимпиады';
        this.fieldsForSprDialog = [
          {
            id: data?.id,
            dataField: 'name',
            value: data?.name ?? null,
            rules: [v => !!v || 'поле не может быть пустым'],
            text: 'Наименование профиля'
          }
        ]
      } else {
        this.titleForSprDialog = 'Добавить эксперта'
        this.fieldsForSprDialog = [
          {
            dataField: 'name',
            value: null,
            rules: [v => !!v || 'поле не может быть пустым'],
            text: 'ФИО '
          },
          {
            dataField: 'qualification',
            value: null,
            rules: [v => !!v || 'поле не может быть пустым'],
            text: 'Квалификация'
          },
        ]
      }

      await this.$refs["spr-dialog"].openDialog();
    },
    async doChoseAboutOlympiad(flag) {
      try {
        this.sendingChoice = true;
        await this.saveUserInfo({
          ...this.getUserInfo,
          sectionOne: {
            ...this.getUserInfo.sectionOne,
            form11: {
              complete: !flag,
              haveOlympiads: flag,
              fileId: ''
            },
          }
        })
        this.sendingChoice = false;
      } catch (e) {
        this.sendingChoice = false;
      }
    },
    changeRow(row) {
      const {id} = row;
      console.log('row' , row);
      console.log('id' , id);
      const searchItemGrouped = this.formData.find(el => el.id === id);
      const searchItem = searchItemGrouped.profiles.find(el => row.profile?.id === el.profile?.id);
      if (!searchItem) return;
      console.log('searchItem', searchItem)
      if (!searchItem.schoolOlympiadProfileDataDto)
        searchItem.schoolOlympiadProfileDataDto = {
          profile: id,
          actualProfile: null,
          foreignStates: [],
          dates: [
              '' ,''
          ],
          foreignLanguages: [],
          foreignCitizens: [],
          numberParticipants: '',
          foreignParticipants: '',
          numberWinners: '',
          foreignWinners: '',
        }

      this.newNote = {
        ...searchItem.schoolOlympiadProfileDataDto,
        currentProfile: searchItem.profile?.id,
        olympiadDataId: searchItem.olympiadDataId,
        profile: id,
        // profile: searchItem?.profile.id,
        foreignStates: searchItem.schoolOlympiadProfileDataDto?.foreignStates?.map(el => {
          return {value: el.id}
        }),
        foreignLanguages: searchItem.schoolOlympiadProfileDataDto?.foreignLanguages?.map(el => {
          return {value: el.id}
        }),
        foreignCitizens: searchItem.schoolOlympiadProfileDataDto?.foreignCitizens?.map(el => {
          return {value: el.id}
        })
      }
      this.openModal = true;
    },
    reloadData() {
      window.scrollTo(0, 0)
      this.formData = []
      this.getFormData()
      this.errorNote = {
        ...defaultErrorNoteValues
      }
      this.clearDialogData()
      this.openMiniModal = false;
      this.openMiniTitle = "";
      this.openMiniInput = "";
      this.openMiniVModal = ''
      this.openMiniInputAdditional = "";
      this.openMiniVModalAdditional = ''
      this.openMiniSelect = false
      this.userAction = null
    },
    async getFormData() {
      try {
        this.loading = true;
        this.profilesPrevStage = await getDbProfiles();
        const res = await getForm('formOneOne');
        const finallyArr = [];
        const olympiadsIds = new Set();
        res.forEach(el => {
          if (!olympiadsIds.has(el.id)) {
            olympiadsIds.add(el.id);
            finallyArr.push({
              ...el, olympiadDataId : el.id, first: true, ...el.profiles[0]});
            el.profiles.forEach((el2, i) => {
              if (i !== 0)
              {
                //eslint-disable-next-line
                const {profiles, ...anotherEl} = el;
                finallyArr.push({...el, ...el2, first: false});
              }
            })
          }
        })
        this.formData = finallyArr;
        this.loading = false;
      } catch (e) {
        console.error('getFormData', e)
        this.loading = false;
      }
    },
    checkForm(action) {
      let haveError = false;
      this.errorNote = {
        ...defaultErrorNoteValues
      }
      if (!this.newNote.profile) {
        this.errorNote.profile = "Поле не должно быть пустым";
        haveError = true;
      }
      if (this.newNote.dates[0].length === 0 || this.newNote.dates[1].length === 0) {
        this.errorNote.dates = "Выберите две даты для правильного ввода";
        haveError = true;
      }
      if (this.newNote.dates[0] && this.newNote.dates[0].length !== 10) {
        this.errorNote.dates = "Поле должно быть формата ГГГГ-ММ-ДД";
        haveError = true;
      }
      if (this.newNote.dates[1] && this.newNote.dates[1].length !== 10) {
        this.errorNote.dates = "Поле должно быть формата ГГГГ-ММ-ДД";
        haveError = true;
      }
      if (this.newNote.actualProfile === null) {
        this.errorNote.actualProfile = "Поле не должно быть пустым";
        haveError = true;
      }
      if (!this.newNote.foreignCitizens.length ) {
        this.errorNote.foreignCitizens = "Поле не должно быть пустым";
        haveError = true;
      }
      if (!this.newNote.numberParticipants && this.newNote.actualProfile !== false) {
        this.errorNote.numberParticipants = "Поле не должно быть пустым";
        haveError = true;
      }
      if (+this.newNote.numberParticipants <= 0 && this.newNote.actualProfile !== false) {
        this.errorNote.numberParticipants = "Значение поля должно быть больше 0";
        haveError = true;
      }
      if (!this.newNote.foreignParticipants && this.newNote.actualProfile !== false) {
        this.errorNote.foreignParticipants = "Поле не должно быть пустым";
        haveError = true;
      }
      if (+this.newNote.foreignParticipants <= 0 && this.newNote.actualProfile !== false) {
        this.errorNote.foreignParticipants = "Значение поля должно быть больше 0";
        haveError = true;
      }
      if (+this.newNote.foreignParticipants > +this.newNote.numberParticipants && this.newNote.actualProfile !== false) {
        this.errorNote.foreignParticipants = "Значение поля не должно превышать значение предыдущего поля";
        haveError = true;
      }
      if (!this.newNote.numberWinners && this.newNote.actualProfile !== false) {
        this.errorNote.numberWinners = "Поле не должно быть пустым";
        haveError = true;
      }
      // if (+this.newNote.numberWinners <= 0 && this.newNote.actualProfile !== false) {
      //   this.errorNote.numberWinners = "Значение поля должно быть больше 0";
      //   haveError = true;
      // }
      if (+this.newNote.numberWinners > +this.newNote.numberParticipants && this.newNote.actualProfile !== false) {
        this.errorNote.numberWinners = "Значение поля не должно превышать общее количество участников";
        haveError = true;
      }
      if (+this.newNote.foreignWinners > +this.newNote.numberWinners || +this.newNote.foreignWinners > +this.newNote.foreignParticipants && this.newNote.actualProfile !== false) {
        this.errorNote.foreignWinners = "Значение не должно быть больше общего количества победителей или иностраных участников";
        haveError = true;
      }
      if (haveError) {
        return;
      } else {
        this.errorNote = {
        ...defaultErrorNoteValues
        }
        this.userAction = action
        // this.confirmFinishEdit = true
        this.finishEdit();

      }
    },
    async finishEdit() {
      try {
        this.confirmFinishEdit = false;
        const { olympiadDataId,currentProfile, ...finalNote} = this.newNote;
        finalNote.profile = {id: this.newNote.profile};
        finalNote.needQuantities = Number(finalNote.needQuantities);
        if (finalNote.foreignCitizens?.length > 0) {
          finalNote.foreignCitizens = finalNote.foreignCitizens.map(el => {
            return {id: el.value}
          })
        }
        if (finalNote.foreignLanguages?.length > 0) {
          finalNote.foreignLanguages = finalNote.foreignLanguages.map(el => {
            return {id: el.value}
          })
        }
        if (finalNote.foreignStates?.length > 0) {
          finalNote.foreignStates = finalNote.foreignStates.map(el => {
            return {id: el.value}
          })
        }
        if (this.userAction === 'print') {
          this.formDataForPrint = finalNote
          console.log(finalNote)
          this.userAction = null
        } else if (this.userAction === 'send') {
          this.sendingOlympiad = true;
          console.log("send", finalNote)
          const res = await setForm('formOneOne', finalNote);
          const currentOlympiads = this.formData.filter(el => el.olympiadDataId === olympiadDataId);
          console.log(olympiadDataId, currentOlympiads)
          const profileFormRowProfiles = currentOlympiads.find(el => el.profiles.find(el2 => el2.id === finalNote.profile?.id && el.profile?.id === currentProfile))
          const profileFormRowProfilesCurrent = profileFormRowProfiles.profiles.find(el2 => el2.id === finalNote.profile?.id && el2.profile?.id === currentProfile)
          if (profileFormRowProfiles)
            profileFormRowProfiles.schoolOlympiadProfileDataDto = res;
          if (profileFormRowProfilesCurrent)
            profileFormRowProfilesCurrent.schoolOlympiadProfileDataDto = res;
          // const index = this.formData.findIndex(el => el.id === finalNote.id);
          // console.log('res', res)
          // // if (finalNote.id)
          //   this.formData.splice(index, 1, {...this.formData[index], ...res})
          // else
          //   this.formData.push(res)
          this.sendingOlympiad = false;
          this.openModal = false
          this.clearDialogData();
          this.userAction = null

        }
      } catch (e) {
        this.sendingOlympiad = false;
        console.error(e)
      }
    },
    async deleteRow(id, index) {
      const accept = confirm('Удалить запись?')
      if (accept) {
        try {
          this.deleting = {value: true, id: index};
          await deleteForm('formOneOne', id)
          this.formData.splice(index, 1)
          this.deleting = {value: false, id: index};
        } catch (e) {
          this.deleting = {value: false, id: index};
        }
      }
    },
    downloadPrintForm() {

    },
    downloadSignedScan() {

    }

  },
};
</script>

<style lang="scss" scoped>
.container__table {
  max-width: 100%;
  overflow: auto;
}

.table2 {
  color: #89a0b0;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 6px 18px rgb(0 0 0 / 6%);
  margin-bottom: 20px;
  padding: 20px;
  width: fit-content;
}

.table__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid;

  > div:first-child {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > div:last-child {
    border-top: 1px solid;
  }
}

.table__row {
  display: grid;
  grid-template-columns: repeat(3, 150px) repeat(2, 200px) repeat(4, 200px 150px) 50px;
  font-weight: bold;
  border-left: 1px solid;
}

.table__body {
  font-weight: normal;
}

.error-note {
  font-size: 12px;
  color: red;
}

.button__add {
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding: 14px 10px;
  margin-top: 40px;
  margin-left: auto;
  display: flex;
  margin-bottom: 40px;
  font-weight: 600;

  &:hover {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    background: #4ba0e4;
  }
}

.button__add2 {
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding: 14px 10px;
  display: flex;
  font-weight: 600;

  &:hover {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    background: #4ba0e4;
  }
}

.text {
  padding: 5px 10px;
  border: 1px solid grey;
  border-radius: 4px;
}

.modal {
  position: fixed;
  top: 10%;
  padding: 24px 32px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 500;
  background: white;
  left: 50%;
  transform: translate(-50%, 0);
  max-height: 80%;
  overflow-y: auto;
  max-width: 920px;
  width: 100%;
}

.close {
  height: 24px;
  font-size: 24px;
  width: 24px;
  position: absolute;
  right: 8px;
  top: 8px;
}

.addNote {
  right: 8px;
  top: 8px;
  left: 8px;
}

.content__block {
  margin-bottom: 15px;
}

.left-line {
  border-left: none;
}

.right-line {
  border-right: none;
}

.bottom-line {
  border-bottom: none;
}

.top-line {
  border-top: none;
}

.modal-header {
  //position: sticky;
  //top: -25px;
  z-index: 1;
  margin: 0;
  background-color: #ffffff;
}

.block__with-btn {
  width: 100%;
  display: grid;
  grid-template-columns: 90% 10%;
  margin-bottom: 10px;
}

.button__plus {
  border-radius: 5px;
  color: #4ba0e4;
  border: 1px solid #4ba0e4;
  background-color: #ffffff !important;
  cursor: pointer;
  padding: 14px 10px;
  margin-left: auto;
  display: flex;

  &:hover {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    background: #4ba0e4;
  }
}

h1 {
  max-width: 1200px;
}

.modal-mini {
  position: fixed;
  top: 34%;
  padding: 24px 32px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgb(0 0 0 / 40%);
  z-index: 500;
  background: white;
  left: 50%;
  transform: translate(-50%, 0);
  min-height: 25%;
  overflow-y: auto;
  max-width: 550px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.main-header__title {
  font-size: 32px;
  line-height: 35.2px;
  color: #00599B;
  font-weight: 700;
  margin-bottom: 22px;
}

.modal-mini__input {
  max-height: 45px;
  width: 100%;
}

.title {
  color: rgb(13, 114, 189);
}
</style>