<template>
  <v-app-bar
      app
      class="app-bar"
      color="#00599B"
      style="z-index: 50!important;"
  >
    <router-link class="header__logo" to="/">
      <img alt="Логотип сайта" src="../assets/icons/Logo_ined.svg" style="width: 40px; height: 40px;">
      <span class="white--text ml-2 font-size-extra-large text-uppercase" style="letter-spacing: 0.01em;">Интеробразование</span>
    </router-link>
    <v-tabs
        centered
        dark
        color="white"
    >
      <v-tab to="/"
             style="color: #FFFFFF!important;"
      >
        Главная
      </v-tab>
      <v-menu
          :nudge-bottom="-5"
          bottom
          offset-y
          open-on-hover
          :disabled="section.disabled"
          v-for="(section, index) in topNavigation"
          :key="index"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-tab
              :link="true"
              :to="section.path"
              :disabled="section.disabled"
              active-class="v-tab--active"
              v-bind="attrs"
              v-on="on"
          >
            {{section.title}}
          </v-tab>
        </template>
        <v-list>
          <v-list-item
              v-for="(item, index) in section.items"
              :key="index"
              class="nav-bar-menu-item"
          >
            <router-link :to="item.path" :exact="true">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </router-link>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-tabs>
    <div class="">
      <div class="d-flex direction-row justify-lg-space-between align-center">
        <!--        <router-link class="d-flex direction-row align-center" to="/">-->
        <!--          <img class="fr-lk-auth__user-img" src="@/assets/img/icon-auth-user.svg" alt="">-->
        <v-icon color="#FFFFFF">mdi-account-outline</v-icon>
        <div class="white--text" style="font-size: 12px; font-weight: 600; margin-right: 20px;">
          {{ userData.name }}
        </div>
        <!--        </router-link>-->
        <div class="fr-lk-auth__logout" @click="logout">
          <v-icon color="#FFFFFF">mdi-exit-to-app</v-icon>
          <!--          <img class="fr-lk-auth__logout-img" src="@/assets/img/icon-auth-logout.svg" alt="">-->
        </div>
        <v-btn v-if="isAdminGroup && getFakeUser" class="white__v-btn" @click="logoutFakeUser">
          Выйти из организации
        </v-btn>

      </div>


    </div>

  </v-app-bar>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: "TopBar",
  props: {
    userData: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      topNavigationDefault: [
        {
          title: 'Раздел 1',
          path: '/section-1',
          items: [
            {path: '/section-1/form-1', title: 'Форма 1'},
            {path: '/section-1/form-1.1', title: 'Форма 1.1'},
            {path: '/section-1/form-2', title: 'Форма 2'},
            {path: '/section-1/form-3', title: 'Форма 3'},
            // {path: '/section-1/form-3.1', title: 'Форма 3.1'},
          ]
        } ,
        {
          title: 'Раздел 2',
          path: '/section-2',
          items: [
            {path: '/section-2/form-1', title: 'Форма 1'},
            {path: '/section-2/form-1.1', title: 'Форма 1.1'},
            {path: '/section-2/form-2', title: 'Форма 2'},
            {path: '/section-2/form-3', title: 'Форма 3'},
            // {path: '/section-2/form-3.1', title: 'Форма 3.1'},
          ],
        },
        {
          title: 'Админка',
          path: '/',
          items: [
            {path: '/admin/exports', title: 'Выгрузки'},
            {path: '/admin/profiles', title: 'Профили и олимпиады'},
          ],
          isAdmin: true
        },

      ],
    }
  },
  computed: {
    ...mapGetters('auth', {authData: 'authData', getFakeUser: 'getFakeUser'}),
    ...mapGetters('keycloak', {isAdminGroup: 'isAdminGroup'}),
    topNavigation(){
      return this.topNavigationDefault.filter(el => this.isAdminGroup && el.isAdmin || !el.isAdmin).map(el => {
        return {...el, disabled: this.isAdminGroup && !el.isAdmin && !this.getFakeUser}
      })
    }
  },
  methods: {
    ...mapActions('keycloak', {keycloakLogout: 'keycloakLogout'}),
    ...mapActions('auth', {clearFakeUser: 'clearFakeUser'}),
    logout() {
      this.keycloakLogout()
    },
    logoutFakeUser(){
      this.clearFakeUser();
      if (this.$route.path !== '/')
        this.$router.push('/');
    },
    getQuery(item) {
      return {
        path: item.href,
        query: {orgID: this.$route.query.orgID}
      }
    },
  }

}
</script>

<style lang="scss" scoped>

.app-bar {
  z-index: 99999 !important;
}
.header__logo {
  display: flex;
  align-items: center;
}

.grantsLink {
  display: flex;
  flex-direction: column;
  width: 482px;
  height: 292px;
  position: absolute;
  top: 122px;
  left: 395px;
  padding: 24px 32px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 1000;
  background: white;
}
</style>