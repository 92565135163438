<template>
  <v-dialog
      v-model="show"
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
      max-width="700px"
  >
    <v-card>
       <span>
        <v-btn
            icon
            @click="closeDialog()"
            style="position: absolute; right:0;"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>
      <v-card-title>
        <slot name="title">
          <p class="font-size-medium" v-text="title"></p>
        </slot>
      </v-card-title>
      <v-card-text class="d-flex flex-column pb-0">
       <slot>
         <v-form
             v-if="!initializing"
             ref="form-spr"
             v-model="valid"
             lazy-validation
             @submit.prevent="sendResolve"
         >
           <v-text-field
               v-for="field in initializedFields"
               :key="field.dataField"
               v-model="field.value"
               :rules="field.rules"
               :label="field.text"
               :style="field.hidden != null && field.hidden === true ? 'display: none;': ''"
               class="mt-2"
               dense
               clearable
               required
           />
         </v-form>
         <v-progress-circular v-else size="35" color="primary"></v-progress-circular>
       </slot>
      </v-card-text>
      <v-card-actions class="d-flex flex-row align-center justify-space-between">
        <slot name="actions" :close="closeDialog" :agree="sendResolve">
          <v-btn
              class='white__v-btn ml-2'
              @click="closeDialog"
              min-width="80px"
          >
            Закрыть
          </v-btn>
          <v-btn
              :disabled="!valid"
              :loading="loading"
              class='blue__v-btn'
              @click.prevent="sendResolve"
              @keyup.enter.prevent="sendResolve"
              min-width="80px"
          >
            Сохранить
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/*
  Example Filed for this dialog
  {
    dataField: '',
    value: null,
    rules: [],
    text: ''
  }
* */
export default {
  name: "addSprInfo",
  components: {},
  data() {
    return {
      show: false,
      valid: true,
      initializing: false,
      initializedFields: []
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    fields:{
      type: Array,
      default: () => {return []}
    }
  },
  dialogController: null,
  watch:{
    show(){
      this.initFields();
    }
  },
  created() {
    this.initFields();
  },
  methods: {
    initFields(){
      this.initializing = true;
      this.initializedFields = this.fields.map(el => {
        return  {
          ...el,
          dataField: el.dataField ?? '',
          value: el.value ?? null,
          rules: el.rules ?? [],
          text: el.text ?? ''
        }
      });
      this.initializing = false;
    },
    closeDialog() {
      this.$emit('closing-dialog');
      this.initializedFields= [];
      this.valid = true;
      this.$options.dialogController.resolve(false);
      this.show = false;
    },
    async validate() {
      this.valid = this.$refs["form-spr"].validate();
    },
    async sendResolve(){
      await this.validate();
      if (this.valid){
        this.$emit('send-resolve', this.initializedFields);
        this.$options.dialogController.resolve(true);
      }
    },
    openDialog() {
      let resolve;
      let reject;
      const dialogPromise = new Promise((ok, fail) => {
        resolve = ok;
        reject = fail;
      })
      this.$options.dialogController = {resolve, reject};
      this.show = true;
      return dialogPromise;
    },
  },
}
</script>

<style lang="sass">

</style>