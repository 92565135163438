<template>
  <wrapper-preview-category class="pa-3">
    <GridTable
        :data-rows="showingServerData"
        :headers="headers.massive"
        :headers-for-rows="headers.massive"
        :loading-data="loadingData"
        :num-cols="headers.countCells"
        key="form-2"
    >
      <template #actions="{row}">
        <div class="d-flex flex-row align-center px-2">
          <v-btn
              @click="authAsOrg(row.org.id)"
              class="white__v-btn "
          >
            Авторизация
          </v-btn>
          <v-btn
              @click="blockOrg(row.org.id)"
              class="white__v-btn  ml-2"
              :loading="loadingBlockEnterData"
          >
            {{row.userInfo && row.userInfo.blocked  ? 'Разблокировать' : 'Заблокировать'}}
          </v-btn>
          <v-btn
              @click="printFile(row.org.id)"
              icon
              :loading="downloadingFileForPrint.value && downloadingFileForPrint.id === row.org.id"
          >
            <v-icon color="primary" size="26">mdi-file-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </GridTable>
    <div class="mt-3 d-flex flex-row justify-center align-center">
      <v-autocomplete
          class="pagination-table"
          v-model="userPages"
          :items="pagesPaginate"
          solo
          dense
          filled
          height="25"
          rounded
          style="max-width: 150px"
          @change="changePage($event)"
      ></v-autocomplete>
      <v-pagination
          v-model="page"
          :length="pagesVuetify"
          :total-visible="7"
          @input="changePage($event)"
      >
      </v-pagination>

    </div>
  </wrapper-preview-category>
</template>

<script>
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import {tablePagination} from "@/mixins/tablePagination";
import GridTable from "@/components/GridTable/GridTable";
import headersSprOrg from "@/helpers/admin/headersSprOrg";
import {getOrgList} from "@/Api/admin";
import {mapActions} from "vuex";
import {setUserInfo} from "@/Api/userInfo";
import {setFileFunctions} from "@/mixins/fileFucntions";

export default {
  name: "LKAdmin",
  components: {GridTable, WrapperPreviewCategory},
  mixins: [tablePagination, setFileFunctions],
  data() {
    return {
      headers: headersSprOrg,
      defaultData: [],
      serverData: [],
      loadingData: false,
      downloadingFileForPrint:{ value: false, id: null},
      loadingBlockEnterData: false,
    }
  },
  computed: {},
  created() {
    this.loadingDataTable();
  },
  methods: {
    ...mapActions('auth', {initFakeUser :'initFakeUser', getUserInfo: 'getUserInfo', setUserFiles: 'setUserFiles'}),
    async loadingDataTable() {
      this.loadingData = true;
      try {
        this.serverData = await getOrgList();
        this.loadingData = false;
      } catch (e) {
        this.loadingData = false;
      }
    },
    authAsOrg(id){
      this.initFakeUser({id: id, role: 'ORGANIZATION_ROLE'});
      this.getUserInfo();
      this.setUserFiles();
    },
    async printFile(id){
      this.downloadingFileForPrint.value = true;
      this.downloadingFileForPrint.id = id;
      await this.downloadPrintDocument(`/api/excelReport?num=all&orgId=${id}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').then().catch(e => console.error(e)).finally(() => {
        this.downloadingFileForPrint = { value: false, id: null}
      })
    },
    async blockOrg(orgId){
      try {
        const index = this.serverData.findIndex(el => el.org.id === orgId);
        const item = this.serverData[index];
        this.loadingBlockEnterData = true;
        const res = await setUserInfo({
          ...item.userInfo,
          blocked: !item.userInfo.blocked
        });
        this.serverData.splice(index, 1, {...item, userInfo: res});
        this.loadingBlockEnterData = false;
      } catch (e) {
        this.loadingBlockEnterData = false;
      }
    }
  },
}
</script>

<style lang="sass" scoped>

</style>