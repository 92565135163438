<template>
  <div class="confirm-modal-wrapper">
    <v-card
        class="confirm-modal flex justify-center align-center mx-auto pa-10"
        max-width="900"
        max-height="700"
    >
      <template v-if="!loading">
        <v-card-text>
          <div class="text--primary mb-7 text-center font-size-large" v-if="needConfirm">{{ cardText }}
          </div>
          <div class="text--primary mb-4 text-center font-size-large" v-else>Вы
            уверены?
          </div>
        </v-card-text>
        <v-card-actions class="ma-0 align-end">
          <v-spacer></v-spacer>
          <v-btn class="button blue__v-btn mr-10" @click="setAnswer(true)">
            Да
          </v-btn>
          <v-btn class="button blue__v-btn font-size-large" @click="setAnswer(false)">
            Нет
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
      <v-progress-circular v-else size="40" indeterminate color="primary"/>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  props: {
    cardText: {
      type: String,
      default: ''
    },
    loading:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      needConfirm: true,
      accept: true,
    }
  },
  methods: {
    setAnswer(answer) {
      if (this.needConfirm) {
        this.needConfirm = false
        this.accept = answer
      } else {
        if (answer) {
          if (this.accept) {
            this.$emit('acceptAction')
          } else {
            this.$emit('rejectAction')
          }
        }
        this.needConfirm = true
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.confirm-modal {
  //display: none;
  position: absolute;
  z-index: 600;

  &-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    //display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 600;
  }
}

</style>