export default {
    namespaced: true,
    state: {
        keycloak: null,
    },
    mutations: {
        ['SET_AUTH_DATA'](state, data) {
            state.keycloak = data;
        },
    },
    actions: {
        async setAuthData({commit}, payload) {
            commit('SET_AUTH_DATA', payload);
        },
        async keycloakLogout({commit, state}) {
            if (state.keycloak) {
                state.keycloak.logout();
                commit('SET_AUTH_DATA', null)
            }
        }
    },
    getters: {
        getAuthData(state) {
            return state.keycloak
        },
        getUserId(state) {
            return state.keycloak?.tokenParsed?.sub
        },
        getOrgId(state, _, rootState) {
            return state.keycloak?.idTokenParsed?.orgID ?? rootState.auth.fakeUser?.id
        },
        getOrgName(state) {
            return state.keycloak?.idTokenParsed?.name
        },
        isAdmin(state) {
            return !!state.keycloak?.realmAccess?.roles.find(el => el === 'ADMIN_ROLE')
        },
        isAdminGroup(state) {
            return !!state.keycloak?.realmAccess?.roles.find(el => el === 'ADMIN_ROLE')
        },
        getRoles(state) {
            return state.keycloak?.realmAccess?.roles
        },
        isSimpleOrg(state){
            return !!state.keycloak?.realmAccess?.roles.find(el => el === 'ORGANIZATION_ROLE')
        }
    }
}