<template>
<wrapper-preview-category>
  <h1 class="main-header__title mt-3">Выгрузки</h1>
  <v-btn
      @click="downloadPrintDocument(`/api/excelReport/status`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')"
      :loading="downloadingFileForPrint"
      class="blue__v-btn mb-3"
  >
    Статусы
  </v-btn>
  <v-btn
      @click="downloadPrintDocument(`/api/excelReport/all`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')"
      :loading="downloadingFileForPrint"
      class="blue__v-btn mb-3"
  >
    Выгрузка олимпиад по всем организациям
  </v-btn>
  <v-btn
      @click="downloadPrintDocument(`/api/getZipAllPrils`, 'application/zip')"
      :loading="downloadingFileForPrint"
      class="blue__v-btn mb-3"
  >
    Выгрузка приложений к олимпиадам
  </v-btn>
  <v-btn
      @click="downloadPrintDocument(`/api/getZipAllPrilsFinal`, 'application/zip')"
      :loading="downloadingFileForPrint"
      class="blue__v-btn mb-3"
  >
    Выгрузка файлов с подписями
  </v-btn>
</wrapper-preview-category>
</template>

<script>
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import {setFileFunctions} from "@/mixins/fileFucntions";
export default {
  name: "Exports",
  components: {WrapperPreviewCategory},
  mixins: [setFileFunctions]
}
</script>

<style scoped>

</style>