<template>
  <keep-alive include="orgProfilesOlympiad">
  <router-view/>
  </keep-alive>
</template>

<script>
export default {
  name: "adminLayout"
}
</script>

<style scoped>

</style>