import {Base64} from 'js-base64';

export function saveFile(file, useFileFormat = true) {
    const decoderFile = file.withoutEncode ? file.fileBytes : Base64.toUint8Array(file.fileBytes);
    const saveFile = new File([decoderFile], file.fileName + (file.fileFormat && useFileFormat ? '.' + file.fileFormat : ''));
    const fileURL = window.URL.createObjectURL(saveFile);
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', file.fileName + (file.fileFormat && useFileFormat ? '.' + file.fileFormat : ''));

    document.body.appendChild(fileLink);
    fileLink.click();
}

export function saveBlobFile(bytes, name = 'reportFile', type = "application/pdf") {
    let blob = new Blob([bytes], {type: type});
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = name;
    link.click();
}
