import request from "@/services/request";
import store from "@/store";
async function getSpr(api ='', stateName = '', splice= false){
    try {
        const res = await request({
            endpoint: `/api/${api}`,
            method: 'get',
        });
        if (res.status === 200) {
            return {data: res.data, stateName: stateName, splice: splice};
        } else {
            throw new Error('error getSpr'+ api + res);
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения справочника ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        console.error('error getSpr'+ api + e)
        throw new Error('error getSpr'+ api + e);
    }
}
async function addDbProfile(payload = {name: ''}, isSchool = true){
    try {
        const school = isSchool ? 'school' : 'mag'
        const res = await request({
            endpoint: `/api/${school}/setDbProfiles`,
            method: 'post',
            body: payload
        });
        if (res.status >= 200) {
            return res.data;
        } else {
            throw new Error('error addDbProfile');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка добавления профиля ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        console.error('error addDbProfile')
        throw new Error('error addDbProfile');
    }
}
async function getDbProfiles(isSchool = true) {
    try {
        let params = '';
        const school = isSchool ? 'school' : 'mag'
        if (store.getters['auth/getFakeUser'])
            params = `?orgId=${store.getters['keycloak/getOrgId']}`
        const res = await request({
            endpoint: `/api/${school}/getDbProfiles${params? params: ''}`,
            method: 'get',
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('error getDbProfiles');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения данных формы 1 ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        console.error('error getDbProfiles' + e)
        throw new Error('error getDbProfiles' + e)
    }
}
async function deleteDbProfile(id,isSchool = true) {
    try {
        if (!id)
            throw new Error('id cannot be null or undefined');
        let params = '';
        const school = isSchool ? 'school' : 'mag'
        if (store.getters['auth/getFakeUser'])
            params = `?orgId=${store.getters['keycloak/getOrgId']}&id=${id}`
        const res = await request({
            endpoint: `/api/${school}/delDbProfiles${params? params: `?id=${id}`}`,
            method: 'delete',
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('error getDbProfiles');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения данных формы 1 ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        console.error('error getDbProfiles' + e)
        throw new Error('error getDbProfiles' + e)
    }
}
export {getSpr, addDbProfile, getDbProfiles, deleteDbProfile}