<template>
  <keep-alive include="ViewLK">
    <router-view/>
  </keep-alive>
</template>

<script>
export default {
  name: "lkLayout"
}
</script>

<style scoped>

</style>