<template>
  <wrapper-preview-category>
    <h1 class="main-header__title">{{ pageTitle }}</h1>
    <div class="d-flex flex-row justify-space-between">
      <v-btn @click="$router.go(-1)" class="white__v-btn ml-2">Назад</v-btn>
      <v-btn
          class="blue__v-btn"
          :loading="downloadingFileForPrint"
          @click="downloadPrintDocument(`/api/excelReport?num=3mall&orgId=${orgId}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')"
      >
        Скачать заполненную форму в формате Excel для самопроверки
      </v-btn>
    </div>
    <!--    Здесь нужна проверка на открытие ввода министерством-->
    <div>
<!--      <div class="mx-2 my-2 d-flex justify-space-between align-center">-->
<!--        <v-btn class="blue__v-btn" @click="openModal = true">Добавить запись</v-btn>-->

<!--      </div>-->
      <GridTable
          :headers="headers.massive"
          :headers-for-rows="headers.massive"
          :num-cols="headers.countCells"
          :loading-data="loading"
          :data-rows="formDataForTable"
          class="ma-3"
      >
        <template #fileId="{row}">
          <v-btn
              color="primary"
              icon
              @click="downloadFile(row.fileId)">
            <v-icon size="20" color="primary">mdi-file-outline</v-icon>
          </v-btn>
        </template>
        <template #actions="{row, index}">
          <v-btn :loading="sendingOlympiad" :disabled="getBlock" icon @click="changeRow(row, index)">
            <v-icon class="flex align-center" color="#888888">
              mdi-pencil
            </v-icon>
          </v-btn>
<!--          <v-btn :loading="deleting.id === row.id && deleting.value" icon @click="deleteRow(row.id, index)">-->
<!--            <v-icon class="flex align-center" color="#888888">-->
<!--              mdi-delete-->
<!--            </v-icon>-->
<!--          </v-btn>-->
        </template>
      </GridTable>

      <v-dialog
          v-model="openModal"
          max-width="1000px"
          persistent
          @keydown.esc="closeMainDialog()"
      >
        <v-card>
          <v-card-title class="modal-header">
            <div class="addNote title">Добавление записи</div>
            <v-icon class="close" @click="closeMainDialog">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <div class="content__block">
              Общая потребность в количестве  мест на 2023/2024 учебный год по олимпиаде
              <v-text-field v-model="newNote.value" :error-messages="errorNote.value" dense outlined
                            type="number"/>
            </div>
          <div class="content__block">
            Наименование профилей олимпиады
            <v-divider class="my-2"/>
            <div v-for="(profile) in newNote.profiles" class="content__block mb-0" :key="profile.id">
              <span>{{profile.name}}</span>
              <v-text-field
                  v-model="profile.value"
                  dense
                  outlined
                  type="number"/>
            </div>
          </div>

          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn
                class="blue__v-btn"
                :loading="sendingOlympiad"
                @click="checkForm('send')"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <confirm-modal
          v-if="confirmFinishEdit"
          card-text="Вы ввели всю имеющуюся информацию и готовы завершить заполнение данной формы?"
          @acceptAction="finishEdit"
          @rejectAction="confirmFinishEdit = false"
      />
    </div>
  </wrapper-preview-category>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/UI/ConfirmModal";
import GridTable from "@/components/GridTable/GridTable";
import headersFormThree from "@/helpers/sectionTwo/headersFormThree";
import {setFileFunctions} from "@/mixins/fileFucntions";
import {getForm, setForm} from "@/Api/sectionTwo";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
// import Form3miniDialog from "@/components/Dialogs/form3miniDialog";
import {headerFormsName} from "@/mixins/headerName";
import {isBlock} from "@/mixins/isBlock";

export default {
  components: { WrapperPreviewCategory, GridTable, ConfirmModal},
  mixins: [setFileFunctions, headerFormsName, isBlock ],
  name: 'Form3School',
  data() {
    return {
      headers: headersFormThree,
      formData: [],
      userAction: null,
      openModal: false,
      loading: false,
      titleForSprDialog: 'Добавить профиль олимпиады',
      fieldsForSprDialog: [
        {
          dataField: 'profile',
          sprDataName: 'profiles',
          typeVar: 'selector',
          disabled: true,
          value: null,
          rules: [v => !!v || 'поле не может быть пустым'],
          text: 'Профиль'
        },
        {
          dataField: 'value',
          value: null,
          type: 'number',
          rules: [v => !!v || 'поле не может быть пустым'],
          text: 'количетсво мест'
        }
      ],
      addingProfileLoading: false,
      sendingOlympiad: false,
      confirmFinishEdit: false,
      deleting: {value: false, id: null},
      errorNote: {
        nameOlympic: "",
        profiles: "",
        value: "",
      },
      newNote: {
        olympiad: null,
        profiles: [],
        value: null,
      },
    };
  },
  mounted() {
    this.getFormData()
  },
  watch: {
    '$route.path'() {
      this.reloadData()
    }
  },
  computed: {
    ...mapGetters('spr', {getSpr: 'getSprData'}),
    ...mapGetters('keycloak', {getOrgName: 'getOrgName', getOrgId: 'getOrgId'}),
    orgOlympiads() {
      return []
    },
    orgOlympiadsProfile() {
      return this.getSprData.sprDataOlympiadProfileNotSchool
          .map(el => {
            return {text: el.name, value: el.id}
          })
    },
    getSprData() {
      return this.getSpr
    },
    orgName() {
      return this.getOrgName;
    },
    orgId() {
      return this.getOrgId;
    },
    formDataForTable() {
      return this.formData
    }
  },
  methods: {
    getFile() {
      this.file = {...this.file, name: 'Положение об олимпиаде', id: this.file?.id, fileType: 'FORM_3'};
    },
    closeMainDialog() {
      this.openModal = false;
      this.newNote = {
        olympiad: null,
        profiles: [],
        value: null,
      }
    },
    changeRow(row){
      this.newNote = row;
      this.openModal = true;
    },
    // openCustomDialog(row){
    //   const profile = this.fieldsForSprDialog.find(el => el.dataField === 'profile')
    //   profile.value = {text: row.name, value: row.id};
    //   const value = this.fieldsForSprDialog.find(el => el.dataField === 'value')
    //   value.value = row.value;
    //   // this.fieldsForSprDialog[1].value = this.newNote.profiles[index].value;
    //   this.$refs['form3-dialog'].openDialog()
    // },
    // addInfoFromProfiles(e){
    //   const profile = this.newNote.profiles.find(el => el.id === e[0].value.value);
    //   profile.value = e[1].value;
    //   this.$refs['form3-dialog'].closeDialog()
    // },
    // removeProfile(index){
    //   if (index !== -1)
    //     this.newNote.profiles.splice(index,1)
    // },
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    async getFormData() {
      try {
        this.loading = true;
        this.formData = await getForm('formThree')
        this.loading = false;
      } catch (e) {
        console.error('getFormData', e)
        this.loading = false;
      }
    },
    checkForm(action) {
      let haveError = false;
      this.errorNote = {
        value: "",
      }
      if (!this.newNote.value) {
        this.errorNote.value = "Поле не должно быть пустым";
        haveError = true;
      }
      const sum = this.newNote.profiles.reduce( (a,b) => (Number.parseInt(a) + (Number.parseInt(b?.value) ?? 0)), 0);
      if (Number.parseInt(this.newNote.value) !== sum) {
        this.errorNote.value = "Суммы должны совпадать";
        haveError = true;
      }
      if (haveError) return;
      else {
        this.errorNote = {
          value: "",
        }
        this.confirmFinishEdit = true
        this.userAction = action;
        this.finishEdit();
      }
    },
    async finishEdit() {
      this.confirmFinishEdit = false
      let finalNote = {...this.newNote}
      finalNote.orgId = this.orgId
      if (this.userAction === 'print') {
        this.formDataForPrint = finalNote
        this.userAction = null
        console.log(finalNote)
      } else if (this.userAction === 'send') {
        this.sendingOlympiad = true;
        try {
          const res = await setForm('formThree', finalNote);
          const item = this.formData.find(el => el.id === res.id);
          this.sendingOlympiad = false;
          this.openModal = false
          this.closeMainDialog();
          item.profiles = res.profiles;
          item.value = res.value;
          this.userAction = null
        }catch (e) {
          this.sendingOlympiad = false;
        }
      }
    },
    downloadPrintForm() {

    },
    downloadSignedScan() {

    }
  },

};
</script>

<style lang="scss" scoped>
.container__table {
  max-width: 100%;
  overflow: auto;
}

.table2 {
  //display: grid;
  //grid-template-columns: repeat(4, 25%);
  color: #89a0b0;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 6px 18px rgb(0 0 0 / 6%);
  margin-bottom: 20px;
  padding: 20px;
  margin: 0 auto;
  width: fit-content;
}

.table__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid;

  > div:first-child {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > div:last-child {
    border-top: 1px solid;
  }
}

.table__row {
  display: grid;
  grid-template-columns: repeat(4, 350px) 50px;
  font-weight: bold;
  border-left: 1px solid;
}

.table__body {

  font-weight: normal;
}

.button__add {
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding: 14px 10px;
  margin-top: 40px;
  margin-left: auto;
  display: flex;
  margin-bottom: 40px;
  font-weight: 600;

  &:hover {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    background: #4ba0e4;
  }
}

h1 {
  max-width: 1200px;
}

.content__block {
  margin-bottom: 15px;
}

.button__add2 {
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding: 14px 10px;
  display: flex;
  font-weight: 600;

  &:hover {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    background: #4ba0e4;
  }
}

.data-list {
  display: grid;
  grid-template-columns: repeat(4, 25%);
}

.text {
  padding: 5px 10px;
  border: 1px solid grey;
  border-radius: 4px;
}

.modal {
  position: fixed;
  top: 25%;
  padding: 24px 32px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 500;
  background: white;
  left: 50%;
  transform: translate(-50%, 0);
  max-height: 80%;
  overflow-y: auto;
  max-width: 920px;
  width: 100%;
}

.close {
  height: 24px;
  font-size: 24px;
  width: 24px;
  position: absolute;
  right: 8px;
  top: 8px;
}

.left-line {
  border-left: none;
}

.right-line {
  border-right: none;
}

.bottom-line {
  border-bottom: none;
}

.top-line {
  border-top: none;
}

.modal-header {
  //position: sticky;
  //top: -25px;
  z-index: 1;
  margin: 0;
  background-color: #ffffff;
}

.block__with-btn {
  width: 100%;
  display: grid;
  grid-template-columns: 90% 10%;
  margin-bottom: 10px;
}

.button__plus {
  border-radius: 5px;
  color: #4ba0e4;
  border: 1px solid #4ba0e4;
  background-color: #ffffff !important;
  cursor: pointer;
  padding: 14px 10px;
  margin-left: auto;
  display: flex;

  &:hover {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    background: #4ba0e4;
  }
}

.modal-mini {
  position: fixed;
  top: 34%;
  padding: 24px 32px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgb(0 0 0 / 40%);
  z-index: 500;
  background: white;
  left: 50%;
  transform: translate(-50%, 0);
  min-height: 25%;
  overflow-y: auto;
  max-width: 550px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.modal-mini__input {
  max-height: 45px;
  width: 100%;
}

.title {
  margin-bottom: 30px;
  color: rgb(13, 114, 189);
}

.main-header__title {
  font-size: 32px;
  line-height: 35.2px;
  color: #00599B;
  font-weight: 700;
  margin-bottom: 22px;
}

.person {
  border-bottom: 2px solid #A9A9A9;
  width: 730px;
  margin-bottom: 25px;
  padding: 0 12px;
  height: 40px;
  display: flex;
  align-items: flex-end;
}
</style>