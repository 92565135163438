import request from "@/services/request";
import store from "@/store";
import router from "@/router";
async function getUserInfo() {
    try {
        if (!store.getters['keycloak/getOrgId']) return;
        const res = await request({
            endpoint: `/api/userinfo?orgId=${store.getters['keycloak/getOrgId']}`,
            method: 'get',
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('error getUserInfo');
        }
    } catch (e) {
        if (e.status !== 401)
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения данных о пользователе ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        console.error('error getUserInfo' + e)
        throw new Error('error getUserInfo' + e)
    }
}
async function getUserFiles() {
    try {
        let params = '?userType=orgID';
        if (store.getters['auth/getFakeUser'])
            params += `&userId=${store.getters['auth/getFakeUser'].id}`
        else
            params += `&userId=${store.getters['keycloak/getOrgId']}`
        const res = await request({
            endpoint: `/api/file/scan/list${params}`,
            method: 'get',
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('error getUserFiles');
        }
    } catch (e) {
        console.error('error getUserFiles' + e)
        throw new Error('error getUserFiles' + e)
    }
}
async function deleteFileById(id = '') {
    try {
        if (!id) throw new Error('need param id');
        const res = await request({
            endpoint: `/api/file/scan/list?fileId=${id}`,
            method: 'delete',
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('error deleteFileById');
        }
    } catch (e) {
        console.error('error deleteFileById' + e)
        throw new Error('error deleteFileById' + e)
    }
}
async function getBlockInfo(id = '') {
    try {
        if (!id) throw new Error('need param orgId');
        const res = await request({
            endpoint: `/api/checkBlock?orgId=${id}`,
            method: 'get',
        });
        if (res.status === 200) {
            return res.data;
        }
    } catch (e) {
        console.error('error getBlockInfo' + e)
        throw new Error(e)
    }
}
async function setUserInfo(data) {
    try {
        if (!store.getters['keycloak/getOrgId'] && !data.orgId || !store.getters['keycloak/getAuthData']?.token) return;
        const res = await request({
            endpoint: `/api/userinfo`,
            method: 'put',
            body: {...data, lastPage: data?.path ?? router.currentRoute.fullPath}
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('error setUserInfo');
        }
    } catch (e) {
        if (e.status !== 401)
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения данных о пользователе ' + ' ' + e,
            timeout: 3000
        }, {root: true});
        console.error('error setUserInfo' + e)
        throw new Error('error setUserInfo' + e)
    }
}
export {getUserInfo, setUserInfo,getBlockInfo, getUserFiles, deleteFileById}
