<!-- Компонент заголовка секции, может принимать в slot доп.контент -->
<template>
<header class="section-header">
	<h2 v-if="title" class="section-header__title" itemprop="headline">{{title}}</h2>
	<h3 v-if="subtitle" class="section-header__subtitle">{{subtitle}}</h3>
	<slot></slot>
</header>
</template>
<script>
export default {
	props: {
		title: {
			type: String,
		},
		subtitle: {
			type: String,
		}
	},
}
</script>
<style lang="sass">
.section-header__title
	line-height: 25.39px
	font-size: 28px
	color: #00599B
	font-family: 'Montserrat'
	font-weight: 700
	text-transform: uppercase
	margin-bottom: 18px
.section-header__subtitle
	color: 5B636B
	font-family: 'Roboto'
	font-size: 17px
	line-height: 18.7px
	font-weight: 400
</style>