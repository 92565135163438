import request from "@/services/request";
import store from "@/store";
async function getOrgList() {
    try {
        const res = await request({
            endpoint: `/api/spr/orgWithUserInfo`,
            method: 'get',
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('error getOrgList');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения данных' + ' ' + e,
            timeout: 3000
        }, {root: true});
        console.error('error getOrgList' + e)
        throw new Error('error getOrgList' + e)
    }
}
async function getSprOrg() {
    try {
        const res = await request({
            endpoint: `/api/spr/org`,
            method: 'get',
        });
        if (res.status === 200) {
            return res.data.map(el => ({value: el.id, text: `ID: ${el.id}, называние: ${el.name}`}));
        } else {
            throw new Error('error getSprOrg');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения данных' + ' ' + e,
            timeout: 3000
        }, {root: true});
        console.error('error getSprOrg' + e)
        throw new Error('error getSprOrg' + e)
    }
}
async function getOrgProfilesAndOlympiads(orgId) {
    try {
        const res = await request({
            endpoint: `/api/spr/olympiadName/getOgrSchoolAdmin?orgId=${orgId}`,
            method: 'get',
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('error getOrgProfilesAndOlympiads');
        }
    } catch (e) {
        await store.dispatch('notifications/addNotification', {
            notificationStatus: 'error',
            notificationMessage: 'ошибка получения данных' + ' ' + e,
            timeout: 3000
        }, {root: true});
        console.error('error getOrgProfilesAndOlympiads' + e)
        throw new Error('error getOrgProfilesAndOlympiads' + e)
    }
}
export {getOrgList, getSprOrg, getOrgProfilesAndOlympiads}