<template>
  <keep-alive :include="isAdminGroup ? ['LKAdmin'] :['LKAdmin','LK']"  >
    <component  :is="componentNameCalc"></component>
  </keep-alive>
<!-- :exclude="isAdminGroup ? ['LK'] :[]" -->
</template>

<script>
import LK from "@/View/LK/LK";
import LKAdmin from "./LK/LKAdmin";
import {mapGetters} from "vuex";

export default {
  name: "ViewLK",
  components: {
    LKAdmin,
    LK
  },
  data() {
    return {
      // componentName: 'l-k'
    }
  },
  computed: {
    ...mapGetters('keycloak', {
      isAdminGroup: 'isAdminGroup',
      isSimpleOrg: 'isSimpleOrg'
    }),
    ...mapGetters('auth', {getFakeUser:'getFakeUser'}),
    componentNameCalc(){
      if (this.getFakeUser)
        return 'l-k';
      if (this.isAdminGroup)
        return 'l-k-admin';
      else
        return 'l-k'
    }
  },
  // created() {
  //   this.initNameOfComponent(this.getFakeUser)
  // },
  // watch: {
  //   getFakeUser(newValue) {
  //     this.initNameOfComponent(newValue)
  //   }
  // },
  // methods: {
  //   initNameOfComponent(newValue) {
  //     if(newValue)
  //       this.$nextTick(() =>     this.componentName = 'l-k')
  //     else
  //       this.$nextTick(() =>     this.componentName = this.isAdminGroup ? 'l-k-admin' : 'l-k')
  //
  //   }
  // },
}
</script>

<style scoped>

</style>