<template>
  <div :class="['alert', typeClass]">
    <div class="alert__icon">
      <slot name="icon"></slot>
    </div>
    <div class="alert__content">
      <slot name="default"></slot>
    </div>

  </div>

</template>


<script>
export default {
  props: {
    alertType: {
      type: String,
      default: 'warning',
    }
  },
  computed: {
    typeClass() {
      return 'alert_' + this.alertType;
    }
  }
}
</script>

<style lang="sass">
.alert
  border-style: solid
  border-width: 1px
  border-radius: 10px
  padding: 10px 15px
  margin-bottom: 20px
  display: flex
  align-items: center

.alert__icon
  margin-right: 10px

.alert__content
  width: 100%
  font-size: 18px

.alert_danger
  background: #FDEBEB
  border-color: #F89B9B
  color: #ED393E

.alert_warning
  background: #fff3cd
  border-color: #F9DD8D
  color: #D4990A

.alert_error
  background: #FDEBEB
  border-color: #F89B9B
  color: #ED393E

.alert_primary
  background: #F5FAFD
  border-color: #CFE0EC
  color: #618AA7

.alert_success
  background: #EBF9EB
  border-color: #8FE28E
  color: #29A128

.alert .alert__content p
  margin: 0

.alert__icon
  flex-shrink: 0

.warning__icon
  width: 100%
</style>