import {getNamesFromArray, getStringFromBoolean} from "@/utils/formData";

export default {
  countCells: 12,
  massive: [

    {
      dataField: 'nameOlympic',
      cols: 1,
      rows: 1,
      text: 'Наименование олимпиады',
      class: 'd-flex flex-column align-start justify-start ',
      headerClass: 'justify-start ',
      headerStyles: `min-width: 240px; justify-content: flex-start;`,
      order: 0,
      variable: true,
      template: (r) => r?.olympiad?.name,
      rowsDataCalc: (r) => (r.profiles?.length ?? 1)
    },
    // {
    //   dataField: 'profiles',
    //   cols: 1,
    //   rows: 1,
    //   order: 2,
    //   variable: true,
    //   headerClass: ' ',
    //   class: 'px-2 d-flex flex-column justify-center',
    //   headerStyles: `min-width: 200px`,
    //   text: `Наименования(-е) профилей(-я) олимпиады`,
    //   rowsDataCalc: (r) => r.profiles?.length ?? 1
    // },
    {
      dataField: 'profile',
      cols: 1,
      rows: 1,
      order: 2,
      variable: true,
      headerClass: ' ',
      class: 'px-2 align-start',
      headerStyles: `min-width: 200px`,
      text: `Наименование профиля олимпиады`,
      template: (r) => r?.profile?.name ?? r.profile?.profile?.name,
    },
    {
      dataField: 'actions',
      cols: 1,
      rows: 1,
      order: 11,
      class: 'd-flex flex-row align-start justify-center ',
      headerClass: '',
      variable: true,
      text: 'Данные по профилю',
      // rowsDataCalc: (r) => r.profiles?.length ?? 1
    },
    {
      dataField: 'dates',
      cols: 1,
      rows: 1,
      order: 3,
      headerClass: '',
      class: `align-start`,
      variable: true,
      template: (r) => r.magOlympiadProfileDataDto?.dates?.join(' - '),
      text: `Период проведения олимпиады`,
      headerTooltip: true,
      headerTooltipText: () => `Укажите даты начала первого этапа и завершения последнего этапа олимпиады`,
    },

    {
      dataField: 'actualProfile',
      cols: 1,
      rows: 1,
      text: `Проводилась ли олимпиада?`,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      headerStyles: ``,
      headerTooltip: true,
      headerTooltipText: () => `Проводилась ли олимпиада по указанному профилю проводилась в 2020-2021
          учебном году?`,
      order: 3,
      template: (r) => getStringFromBoolean(r.magOlympiadProfileDataDto?.actualProfile),
      variable: true,
    },
    {
      dataField: 'foreignStates',
      cols: 1,
      rows: 1,
      order: 4,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Наименования(-е) иностранных(-ого) государств(-а), на территориях (-ии)
          которых(-ого) очно проводилось не менее одного этапа олимпиады по
          указанному профилю`,
      text: 'Наименования государств',
      template: (row) => {
        return getNamesFromArray(row.magOlympiadProfileDataDto?.foreignStates)
      }
    },
    {
      dataField: 'foreignLanguages',
      cols: 1,
      rows: 1,
      order: 5,
      class: 'px-2 align-start',
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Наименования(-е) иностранных(-ого) языков(-а) олимпиадных заданий`,
      text: 'Наименования языков',
      template: (row) => {
        return getNamesFromArray(row.magOlympiadProfileDataDto?.foreignLanguages)
      }
    },
    {
      dataField: 'foreignCitizens',
      cols: 1,
      rows: 1,
      order: 6,
      class: 'px-2 align-start',
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Наименования(-е) иностранных(-ого) государств(-а), граждане(-ин)
          которых(-ого) приняли(-) участие в олимпиаде`,
      text: 'Наименования государств',
      template: (row) => {
        return getNamesFromArray(row.magOlympiadProfileDataDto?.foreignCitizens)
      }
    },
    {
      dataField: 'numberParticipants',
      cols: 1,
      rows: 1,
      order: 7,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: 'Общее количество участников олимпиады по профилю',
      template: (row) => {
        return row.magOlympiadProfileDataDto?.numberParticipants
      }
    },
    {
      dataField: 'foreignParticipants',
      cols: 1,
      rows: 1,
      order: 8,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: 'Из них иностранных граждан',
      template: (row) => {
        return row.magOlympiadProfileDataDto?.foreignParticipants
      }
    },
    {
      dataField: 'numberWinners',
      cols: 1,
      rows: 1,
      order: 9,
      class: 'px-2 align-start',
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Общее количество победителей и (или) призеров заключительного
          (итогового) этапа олимпиады по профилю`,
      text: 'Общее количество победителей по профилю',
      template: (row) => {
        return row.magOlympiadProfileDataDto?.numberWinners
      }
    },
    {
      dataField: 'foreignWinners',
      cols: 1,
      rows: 1,
      order: 10,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: 'Из них иностранных граждан',
      template: (row) => {
        return row.magOlympiadProfileDataDto?.foreignWinners
      }
    },

  ]
}
