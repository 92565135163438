// import request from "@/services/request";

export default {
    namespaced: true,
    state: {
        checkFilledMainContact: false,
        checkFilledContacts: false,
    },
    mutations: {
        ['ADD_STATUS_CHECK_FILLED'](state, payload) {
            state.checkFilledMainContact = payload;
        },
        ['ADD_STATUS_CHECK_FILLED_CONTACTS'](state, payload) {
            state.checkFilledContacts = payload;
        },
    },
    actions: {
        async fetchCheckFilled({commit}, payload) {
            const {contacts} = payload
            if (contacts.length > 0 && contacts[0].fio.length > 0)
            {
                commit('ADD_STATUS_CHECK_FILLED_CONTACTS', true)
            }
        },
    },
    getters: {
        get_check_filled(state) {
            return state.checkFilled;
        },
        get_check_filled_status(state) {
            return state.checkFilledContacts;
        },
    }
}