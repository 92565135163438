import TableJs from './Table'
import Utils from './utils'
import {getNamesFromArray,getNewObject,getStringFromBoolean}  from './formData'
export default {
    TableJs,
    Utils,
    FormData:{
        getNamesFromArray,
        getNewObject,
        getStringFromBoolean
    }
}