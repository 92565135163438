import {mapActions} from "vuex";

export const setAddSprDialogFunctions =  {
    data(){
        return {
            titleForSprDialog: 'Добавить профиль олимпиады',
            fieldsForSprDialog: [],
            addingProfileLoading: false,
        }
    },
    computed: {

    },
    methods:{
        ...mapActions('notifications', {addNotification: 'addNotification'}),
        ...mapActions('spr', {setProfile: 'setProfile', setOlympiad:'setOlympiad', updateNewProfiles: 'updateNewProfiles'}),
        async addNewProfile(e, forSchool = true){
            try{
                this.addingProfileLoading = true;
                const objToSend= {}
                e.forEach(el=> {
                    objToSend[el.dataField] = el.value;
                })
                if (this.fieldsForSprDialog.length === 1)
                {
                    objToSend.forSchool = forSchool;
                    this.setProfile(objToSend);
                    // this.$nextTick(() => this.newNote.profiles.push({value: null, text: res.name}))
                    await this.addNotification({
                        notificationStatus: 'success',
                        notificationMessage: 'Ваш профиль успешно добавлен, выберите его.',
                        timeout: 5000
                    });
                }else{
                    this.newNote.experts.push(objToSend)
                }
                this.$refs['spr-dialog'].closeDialog()
                this.addingProfileLoading = false;
            }catch (e) {
                this.addingProfileLoading = false;
            }

        },
        async openCustomDialog(type = 'profile'){
            if (type === 'profile'){
                this.titleForSprDialog = 'Добавить профиль олимпиады'
                this.fieldsForSprDialog = [
                    {
                        dataField: 'name',
                        value: null,
                        rules: [v => !!v || 'поле не может быть пустым'],
                        text: 'наименование профиля'
                    }
                ]
            }
            else{
                this.titleForSprDialog = 'Добавить эксперта'
                this.fieldsForSprDialog = [
                    {
                        dataField: 'name',
                        value: null,
                        rules: [v => !!v || 'поле не может быть пустым'],
                        text: 'ФИО '
                    },
                    {
                        dataField: 'qualification',
                        value: null,
                        rules: [v => !!v || 'поле не может быть пустым'],
                        text: 'Квалификация'
                    },
                ]
            }

            await this.$refs["spr-dialog"].openDialog();
        },
    }
}