<template>
  <wrapper-preview-category class="pa-3">
    <div class="d-flex flex-row align-center">
      <v-autocomplete
          v-model="chosenOrg"
          :items="sprData"
          :loading="loadingSprData"
          clearable
      />
      <v-btn
          class="blue__v-btn"
          :disabled="loadingData || !chosenOrg"
          @click="loadingDataTable()"
      >Посмотреть по организации</v-btn>
    </div>
    <GridTable
        :data-rows="serverData"
        :headers="headers.massive"
        :headers-for-rows="headers.massive"
        :loading-data="loadingData"
        :num-cols="headers.countCells"
        key="form-13"
    >
    </GridTable>
  </wrapper-preview-category>
</template>

<script>
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import GridTable from "@/components/GridTable/GridTable";
import headersProfilesOlympiad from "@/helpers/admin/headersProfilesOlympiad";
import {getSprOrg, getOrgProfilesAndOlympiads} from "@/Api/admin";

export default {
  name: "orgProfilesOlympiad",
  components: {GridTable, WrapperPreviewCategory},
  data() {
    return {
      serverData: [],
      loadingData: false,
      loadingSprData: false,
      sprData: [],
      chosenOrg: null,
      headers: headersProfilesOlympiad,
    }
  },
  created() {
    this.loadingSprOrg();
  },
  methods: {
    async loadingDataTable() {
      this.loadingData = true;
      try {
        this.serverData = await getOrgProfilesAndOlympiads(this.chosenOrg);
        this.loadingData = false;
      } catch (e) {
        this.loadingData = false;
      }
    },
    async loadingSprOrg() {
      this.loadingSprData = true;
      try {
        this.sprData = await getSprOrg();
        this.loadingSprData = false;
      } catch (e) {
        this.loadingSprData = false;
      }
    },
  },
}
</script>

<style scoped>

</style>